import React, {useContext, useState, FC, ChangeEvent, useRef, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import DataServiceProduct from '../../services/DataServiceProduct';
import InlineError from '../elements/InlineError';
import Modal from '../Modal';
import Resource from './Resource';
import SelectCategories from './SelectCategories';
import SelectAttributes from './SelectAttributes';
import CategoryModel from '../../interfaces/CategoryModel'
import Button from '../elements/Button';
import FormRow from '../formfields/FormRow';
import FormLabel from '../formfields/FormLabel'
import TextInputRow from '../formfields/TextInputRow';
import { ModalContext } from "../../contexts/modalContext";
import ProductModel from '../../interfaces/ProductModel';
import { NTAModel } from '../../interfaces/ProductModel';

interface AddProductProps {
    brandId: string
    orgId: string
    setReloadProducts: (a:number)=>void
    reloadProducts: number
}

const AddProduct:FC<AddProductProps> = (props) => {


    let { handleModal } = useContext<any>(ModalContext)
    const isMounted = useRef(false)
    const { getAccessTokenSilently } = useAuth0();
      
    const lowercaseProduct = () => {
        return inputs.item.toLowerCase().replace(/\s/g, '').substring(0,5);
    }

    const [inputs, setInputs] = useState<ProductModel>({  
        "brand_id": props.brandId,
        "org_id": props.orgId,
        "product_id": '',
        "item": '',
        "description": '',
        "categories": [],
        "attribute_set": [],
        "NTA": []
    })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [minSlotIsValid, setMinSlotIsValid] = useState(true)
    const [allCats, setAllCats] = useState<CategoryModel[]>([])

    const handleChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        const name = field;
        const value = event.target.value;

        if(name == 'item') {
            const id = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
            setInputs(values => ({...values, ['product_id']: id}))
        }

        setInputs(values => ({...values, [name]: value}))
    }

    const setCategories = (cats: number[]) => {

        setInputs(values => ({...values, ['categories']: cats}))
    }

    const setNtas = (ntas: NTAModel[]) => {
        setInputs(values => ({...values, ['NTA']: ntas}))
    }

    const setAttributes = (attributes: string[]) => {
        setInputs(values => ({...values, ['attribute_set']: attributes}))
    }

    const submitForm = async () => {
        const token = await getAccessTokenSilently()
        const response = DataServiceProduct.addProduct(inputs, token)
        try {
            const brandResponse = await response
            props.setReloadProducts(props.reloadProducts+1)
            setIsLoading(false)
            handleModal()
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.errors[0])
            } else {
                setError(e.message)
            }
        }
    }
    
    return (
        <div>
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            <h3>Product Details</h3>
            <TextInputRow label={'Name'} defaultValue={inputs.item} field="item" onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label={'Description'} defaultValue={inputs.description} field={'description'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <FormRow>
                <SelectCategories 
                    setAllCats={setAllCats}
                    categories={inputs.categories}
                    setCategories={setCategories}
                    attributes={inputs.attribute_set}
                    setAttributes={setAttributes}
                    ntas={inputs.NTA}
                    setNtas={setNtas}
                    showattrs={true} 
                    setWhatChanged={()=>null} />
            </FormRow>
            <FormRow>
                <Button label={'Add Product'} type={'primary'} action={()=>submitForm()} />
            </FormRow>
        </div>
    )
}

export default AddProduct