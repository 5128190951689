import { Box, Button, Typography } from "@mui/material";
import { ChangeEvent, FC, useContext, useState } from "react";
import { DDCFlowContext } from "../../contexts/DdcFlowContext";
import DataServiceDdc from "../../services/DataServiceDdc";
import FormLabel from "../formfields/FormLabel";
import TextInputRow from "../formfields/TextInputRow";
import LoadingSpinner from "../global/LoadingSpinner";
import { Question } from "./ManageDdc";

interface InsertBeforePayload {
    brand_id: string,
    node_id: string,
    new_node_id: string,
    new_response: string,
    new_display_text: string
}

const InsertBefore:FC<{data: any, closeDrawer: (a: boolean)=>void}> = ({data, closeDrawer}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [ errorMessage, setErrorMessage] = useState('')
    let { incrementRefreshDDC } = useContext<any>(DDCFlowContext)
    const initialData: InsertBeforePayload = {
        brand_id: data.questionData.question.brand_id,
        node_id: data.questionData.question.question_id,
        new_node_id: "15",
        new_response: data.questionData.response.response,
        new_display_text: data.questionData.response.display_text
      }
    const [formData, setFormData] = useState(initialData)

    console.log(data)

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        let isMounted = true
        const value = event.target.value;
        if(isMounted) setFormData(values => ({...values, [field]: value}))
        return () => { isMounted = false }
    }

    async function onSubmit(e: any) {
        setIsLoading(true)
        // const token = await getAccessTokenSilently()
        const response = DataServiceDdc.moveOption(formData);
        try {
            const ddcResponse = await response
            //refresh
            setIsLoading(false)
            closeDrawer(false)
            incrementRefreshDDC()


        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setErrorMessage(e.response.errors)
            } else {
                setErrorMessage(e.message)
            }
        }
    }

    console.log(data.allQuestions)
    
    return <Box sx={{padding: '20px'}}>
        <Typography variant="h4" sx={{fontSize: '1.4rem', fontWeight: '800', marginBottom: '1rem'}}>Move Option</Typography>
        {isLoading && <LoadingSpinner/>}

        {errorMessage !== '' && errorMessage}

        <p>From ID: {formData.node_id}</p>
        <p>To ID: {formData.new_node_id}</p>

        <FormLabel label={"New Parent Node"} />
        <select onChange={(e)=>onFieldChange(e, 'new_node_id')}>
            <option>Select</option>
            {data.allQuestions.map((item: Question, index: number)=>{
                if(typeof item.question !== 'undefined' ) {
                    return <option key={index} value={item.question.question_id}>{item.question.question_id} - {item.question.question_text}</option>
                } else {
                    return <></>
                }
            })}
        </select>

        {/* <TextInputRow label={"To ID"} defaultValue={formData.new_node_id} field={"new_node_id"} onFieldChange={onFieldChange} inputGroup={false} buttonAction={()=>null} /> */}
        <TextInputRow label={"New Response"} defaultValue={formData.new_response} field={"new_response"} onFieldChange={onFieldChange} inputGroup={false} buttonAction={()=>null} />
        <TextInputRow label={"New Display Text"} defaultValue={formData.new_display_text} field={"new_display_text"} onFieldChange={onFieldChange} inputGroup={false} buttonAction={()=>null} />
        <Button onClick={(e)=>onSubmit(e)}>Move</Button>
    </Box>
}
export default InsertBefore
