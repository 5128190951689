import React, {useState, ChangeEvent, useContext, useEffect, FC, ReactNode} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ModalContext } from "../../contexts/modalContext";
import DataServiceAttribute from '../../services/DataServiceAttribute';
import Button from '../elements/Button';
import InlineError from '../elements/InlineError';
import TextInputRow from '../formfields/TextInputRow';
import LoadingSpinner from '../global/LoadingSpinner';

interface AddAttrProps {
    update: number
    setUpdate: (a: number) => void
}

const AddAttribute:FC<AddAttrProps> = (props) => {

    const [attrName, setAttrName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const { getAccessTokenSilently } = useAuth0();
    let { handleModal } = useContext<any>(ModalContext)

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setIsLoading(true)
        const token = await getAccessTokenSilently();
        const response = DataServiceAttribute.addAttribute(attrName, token)
        try {
            const brandResponse = await response
            props.setUpdate(props.update + 1)
            handleModal()
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    }

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        let isMounted = true
        const value = event.target.value;
        if(isMounted) setAttrName(value)
        return () => { isMounted = false }
    }

    return (
        <div className="position-relative">
            {isLoading && <LoadingSpinner />}
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            <TextInputRow label="Attribute name" defaultValue={attrName} onFieldChange={onFieldChange} field="attr_name" inputGroup={false} buttonAction={()=>null} />
            <Button label={'Add Attribute'} type={'primary'} action={handleSubmit} />
        </div>
    )
}
export default AddAttribute