import React, {ChangeEvent, useContext, useState, FC, useRef, useEffect} from 'react';
import CategoryModel from '../../interfaces/CategoryModel'
import DataServiceNta from '../../services/DataServiceNta';
import { useAuth0 } from "@auth0/auth0-react";
import FormLabel from '../formfields/FormLabel';
import { NTAModel } from '../../interfaces/ProductModel';

interface NtaProps {
    categories: number[];
    catChange: number;
    allCats: CategoryModel[];
    addNta: (a:NTAModel)=>void
    ntas: NTAModel[]
}

interface NtaResponseModel {
    display_name: string,
    name: string,
    nta_encoded: string,
    operator: string,
    type: string,
    description: string
}

const NonTextAttributes:FC<NtaProps> = (props) => {

    const { getAccessTokenSilently } = useAuth0();
    const [selectedParentCats, setSelectedParentCats] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [ntas, setNtas] = useState<NtaResponseModel[]>([])

    const getParentCats = () => {
        const selectedCats = props.allCats.filter((item: CategoryModel)=>props.categories.indexOf(item.category_id) !== -1)
        const parentIds: number[] = []
        selectedCats.map((item: CategoryModel)=>parentIds.push(item.parent_category_id))
        const selectedParents = props.allCats.filter((item: CategoryModel)=>parentIds.indexOf(item.category_id) !== -1)

        setSelectedParentCats(selectedParents.map((item: CategoryModel)=>item.category_id))
    }
    useEffect(()=> {
        getParentCats()
        console.log(props.categories)
    },[props.catChange])

    useEffect(()=> {

        let isMounted = true;  
        async function fetchProducts() {
            if (isMounted) setIsLoading(true)
            const token = await getAccessTokenSilently()
            const response = DataServiceNta.getCategoryNta(selectedParentCats, token)
            try {
                const dataResponse = await response
                if (isMounted) setNtas(dataResponse.data)
                if (isMounted) setIsLoading(false)
                
            } catch(e) {
                if (isMounted) setIsLoading(false)
            }
        }
        fetchProducts()
        return () => { isMounted = false }

    }, [selectedParentCats])


    return (
        <div className="row mb-3 align-items-center">
            {ntas.map((item: NtaResponseModel)=>(<NtaField key={item.nta_encoded} data={item} ntas={props.ntas} addNta={props.addNta} />))}
        </div>
    )
}

interface NtaFieldProps {
    data: NtaResponseModel;
    addNta: (a:NTAModel)=>void
    ntas: NTAModel[]
}

const NtaField:FC<NtaFieldProps> = (props) => {

    const updateNta = ( event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value
        if(props.data.type === 'seconds') value = ''+(parseInt(value) * 60)
        props.addNta({
            display_name: props.data.display_name,
            nta_encoded: props.data.nta_encoded,
            value: value
        })
    }

    const defaultValue = () => {
        const findNta = props.ntas.filter(item=>item.nta_encoded === props.data.nta_encoded)
        if( findNta.length > 0 ) {
            let value = findNta[0].value
            if(props.data.type === 'seconds') value = value / 60
            return value
        } else {
            return ''
        }
    }

    const getFieldType = () => {
        if( props.data.type == 'int' || props.data.type == 'float' ) return 'number'
        if( props.data.type == 'date' ) return 'date'
        if( props.data.type == 'time' ) return 'time'
        if( props.data.type == 'seconds' ) return 'number'
        return 'text'
    }

    return (
        <div className="col-3">
            <div className="mb-3">
                <FormLabel label={props.data.display_name} />
                <input className="form-control" id={props.data.nta_encoded} type={getFieldType()} onChange={(e) => updateNta(e)} defaultValue={defaultValue()} />
                <p className="form-text">{props.data.description}</p>
            </div>
        </div>
    )
}

export default NonTextAttributes