import EventModel from "../interfaces/EventModel"

function sameDate(date1: Date, date2: Date) {
    return date1.getDay() === date2.getDay() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
}
function sameDayCheck(date1: Date, date2: Date, date1a: Date, date2a: Date): boolean {
    return sameDate(date1, date2) || sameDate(date1a, date2a) || sameDate(date1, date2a) || sameDate(date1a, date2) ? true : false
}
function sameResourceCheck(res1: string, res2: string) {
    return  res1 === res2
}

function sameHourCheck(date1: Date, date2: Date, date1a: Date, date2a: Date) {
    return date1.getHours >= date2.getHours && date1a.getHours <= date2a.getHours
}

function checkEventClash(eventList: EventModel[], event: EventModel): EventModel[] {
    const startTimeDate = new Date(event.start.split('.')[0])
    const endTimeDate = new Date(event.end.split('.')[0])

    // startTimeDate is same as events
    const sameDayItems = eventList.filter(eventItem => {
        const eventFilterStart = new Date(eventItem.start.split('.')[0])
        const eventFilterEnd = new Date(eventItem.end.split('.')[0])

        return sameDayCheck(eventFilterStart, startTimeDate, eventFilterEnd, endTimeDate) && 
                sameResourceCheck(eventItem.resource, event.resource) &&
                sameHourCheck(eventFilterStart, startTimeDate, eventFilterEnd, endTimeDate)
    })

    return sameDayItems
}
export default checkEventClash