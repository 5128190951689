import React, {FC, ReactNode} from 'react';

interface FlexProps{
    children: ReactNode;
    justify: string | null;
    align: string | null;
    onClick?: () => void;
}

const Flex: FC<FlexProps> = (props) => {
    return (
        <div className={'d-flex ' + props.justify + ' ' + props.align} onClick={()=>(props.onClick !== undefined)?props.onClick():()=>null}>
            {props.children}
        </div>
    )
}

export default Flex