import React, {Component, useState, ChangeEvent, useRef, FC, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import DataService from "../../services/data.service";
import DataServiceCategory from '../../services/DataServiceCategory';
import SelectAttributes from './SelectAttributes';
import CategoryModel from '../../interfaces/CategoryModel'
import NonTextAttributes from './NonTextAttributes';
import { NTAModel } from '../../interfaces/ProductModel';
import WhatChangedModel from '../../interfaces/WhatChangedModel';

interface ParentCatCheckProps {
    selectedCats: number[];
    isParent: boolean;
    parentId: number;
    data: CategoryModel;
    setCat: (a: number)=> void;
}

const ParentCategoryCheck:FC<ParentCatCheckProps> = (props) => {

    const onParentSelect = (e: ChangeEvent<HTMLInputElement>) => {
        props.setCat(parseInt(e.target.value))
    }

    return(
        <div className="col-3 mb-1">
            <div className={(props.isParent?'form-check d-flex align-items-center':'form-check d-flex align-items-center form-switch')}>
                <input 
                    className="form-check-input" 
                    name='parent'
                    type='radio'
                    role='radio'
                    id={props.data.category_id.toString()} 
                    value={props.data.category_id} 
                    checked={props.parentId == props.data.category_id}
                    onChange={(e)=>onParentSelect(e)}
                />
                <label className="form-check-label d-flex align-items-center" htmlFor={props.data.category_id.toString()}>    
                    <img src={props.data.icon_url} style={{maxHeight:"25px", marginLeft:"5px", marginRight:"5px"}} />
                    <span>{props.data.category_name}</span>
                </label>
            </div>
        </div>
    )
}

interface CatCheckProps {
    selectedCats: number[];
    isParent: boolean;
    parentId: number;
    data: CategoryModel;
    setCat: (a: number)=> void;
}

const CategoryCheck:FC<CatCheckProps> = (props) => {

    const [selected, setSelected] = useState((props.selectedCats.filter(item => item == props.data.category_id).length > 0 ))

    const onSelect = (selected: boolean | ((prevState: boolean) => boolean), e: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(selected)
        props.setCat(parseInt(e.target.value))
    }

    return(
        <div className="col-3 mb-1">
            <div className={(props.isParent?'form-check d-flex align-items-center':'form-check d-flex align-items-center form-switch')}>
                <input 
                    className="form-check-input" 
                    name='child'
                    type='checkbox'
                    role='switch'
                    id={props.data.category_id.toString()} 
                    value={props.data.category_id} 
                    checked={selected}
                    onChange={(e)=>onSelect(!selected, e)}
                />
                <label className="form-check-label d-flex align-items-center" htmlFor={props.data.category_id.toString()}>    
                    <img src={props.data.icon_url} style={{maxHeight:"25px", marginLeft:"5px", marginRight:"5px"}} />
                    <span>{props.data.category_name}</span>
                </label>
            </div>
        </div>
    )
}

interface ParentCatProps {
    setCat: (a: number)=> void
    parentId: number
    parentCatList: CategoryModel[]
    selectedCats: number[]
}

const ParentCats:FC<ParentCatProps> = (props) => {
    if( props.parentCatList.length > 0) {
        return <div className="row mb-3 align-items-center">{props.parentCatList.map((category)=><ParentCategoryCheck setCat={props.setCat} key={category.category_id} data={category} isParent={true} selectedCats={props.selectedCats} parentId={props.parentId} />)}</div>
    } else {
        return <p>Nothing found</p>
    }
}

interface ChildCatProps {
    setCat: (a: number)=> void
    parentId: number
    childCatList: CategoryModel[]
    selectedCats: number[]
}

const ChildCats:FC<ChildCatProps> = (props) => {
    if( props.childCatList.length > 0) {
        return <div className="row mb-3 align-items-center">{props.childCatList.map((category)=><CategoryCheck setCat={props.setCat} key={category.category_id} data={category} isParent={false} selectedCats={props.selectedCats} parentId={props.parentId} />)}</div>
    } else {
        return <p>Select a parent category</p>
    }
}

interface SelectProps {
    
    setAllCats: (a: CategoryModel[])=>void;
    categories: number[]
    setCategories: (a: number[])=> void 
    attributes: string[] 
    setAttributes: (a: string[])=> void 
    ntas: NTAModel[]
    setNtas: (a: NTAModel[])=> void
    showattrs: boolean
    setWhatChanged: (a: WhatChangedModel)=>void
}

const SelectCategories: FC<SelectProps> = (props) => {
    
    const [catList, setCatList] = useState<CategoryModel[]>([])
    const [parentCatList, setParentCatList] = useState<CategoryModel[]>([])
    const [childCatList, setChildCatList] = useState<CategoryModel[]>([])
    const [parentId, setParentId] = useState<number>(0)
    const [isLoading, setIsLoading] = useState(false)
    const [catChange, setCatChange] = useState(0)

    useEffect(function effectFunction() {
        let isMounted = true;       
        async function fetchCats() {
            if (isMounted) setIsLoading(true)
            const response = DataServiceCategory.getCatList()
            try {
                const dataResponse = await response
                if (isMounted) props.setAllCats(dataResponse.data.search_categories)
                if (isMounted) setCatList(dataResponse.data.search_categories)
                if (isMounted) setParentCatList(dataResponse.data.search_categories.filter((item: CategoryModel) => item.parent_category_id == 0))

                if( props.categories.length > 0 ) {
                    if (isMounted) setParentId(dataResponse.data.search_categories.filter( (item: CategoryModel) => item.category_id == props.categories[0] )[0].parent_category_id)
                }
                if (isMounted) setIsLoading(false)
                
            } catch(e) {
                if (isMounted) setIsLoading(false)
            }
        }
        fetchCats()
        return () => { isMounted = false }

    }, [])

    const addCategory = (catId: number) => {
        let selectedCats = props.categories
        let change = 'ADD'
        setCatChange(catChange+1)
        if( selectedCats.includes(catId) ) {
            selectedCats.splice(selectedCats.indexOf(catId), 1)
            change = 'REMOVE'
        } else {
            selectedCats.push(catId)
        }
        props.setWhatChanged({
            change: change,
            id: catId
        })
        props.setCategories(selectedCats)
    } 

    const addAttribute = (attrId: string) => {
        let selectedAttrs = props.attributes
        if( selectedAttrs.includes(attrId) ) {
            selectedAttrs.splice(selectedAttrs.indexOf(attrId), 1)
        } else {
            selectedAttrs.push(attrId)
        }
        props.setAttributes(selectedAttrs)
    }

    const addNta = (nta: NTAModel) => {
        let selectedNTAs = props.ntas
        // check if NTA is in NTA array
        if( selectedNTAs.filter(item=> item.nta_encoded == nta.nta_encoded).length > 0 ) {
            // if true then remove
            const index = selectedNTAs.map(item=> item.nta_encoded).indexOf(nta.nta_encoded)
            selectedNTAs.splice(index, 1)
            selectedNTAs.push(nta)
            // add nta to array
        } else {
            // add nta to array
            selectedNTAs.push(nta)
        }
        props.setNtas(selectedNTAs)
    }

    useEffect(function effectFunction() {
        let isMounted = true;     
        function selectParent() {
            if (isMounted && catList !== undefined) setChildCatList(catList.filter((item: CategoryModel) => item.parent_category_id == parentId))
        }
        selectParent()
        return () => { isMounted = false }
    }, [parentId])

    if( isLoading ) return <LoadingSpinner />
    return (
        <div className="row mb-3 align-items-center">
            <h3>Select Parent Category</h3>
            <ParentCats setCat={setParentId} parentId={parentId} parentCatList={parentCatList} selectedCats={props.categories} />
            <h3 className="mt-3">Select Child Categories</h3>
            <ChildCats setCat={addCategory} parentId={parentId} childCatList={childCatList} selectedCats={props.categories} />

            { props.showattrs &&
                <div>
                    <h3 className="mt-3">Select Attributes</h3>
                    <SelectAttributes allCats={catList} selectedCats={props.categories} selectedAttrs={props.attributes} catChange={catChange} setAttribute={addAttribute} />

                    <NonTextAttributes categories={props.categories} allCats={catList} catChange={catChange} ntas={props.ntas} addNta={addNta} />
                </div>
            }
        </div>
    )
}

export default SelectCategories