import React, {FC, useState, useRef, useEffect, ChangeEvent, useContext} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import LoadingSpinner from '../global/LoadingSpinner';
import DataServiceResource from '../../services/DataServiceResource';
import DataServiceProduct from '../../services/DataServiceProduct';
import DataServiceOrganisation from '../../services/DataServiceOrganisation';
import ResourceModel from '../../interfaces/ResourceModel';
import {ProductResourcesModel} from '../../interfaces/ProductModel';
import { OrganisationContext } from '../../contexts/OrganisationContext';
import getID from '../../helpers/getID';

interface HeaderRowProps {
    resources: ResourceModel[]
}

const HeaderRow:FC<HeaderRowProps> = (props) => {
    return(
        <div className="d-flex justify-content-between w-100 border-bottom link-res-header">
            <div className="product-name">Products</div>
            {props.resources.map((resource)=><div key={resource.res_id} className="res-name">{resource.res_name}</div>)}
        </div>
    )
}

interface ResourceItemProps {
    productResources: string[]
    resource: ResourceModel
    setProductData: (a: ProductDataModel)=>void
    productData: ProductDataModel
    orgId: string
    setIsLoading: (a: boolean)=>void
}

const ResourceItem:FC<ResourceItemProps> = (props) => {

    const [selected, setSelected] = useState<boolean>(props.productResources.includes(props.resource.res_id))

    const selectChange = (selected: boolean, e: ChangeEvent<HTMLInputElement>) => {
        const id = e.target.value
        setSelected(selected)
        const resList = props.productData.resource_list       

        if( resList.includes(id) ) {
            // remove
            resList.splice(resList.indexOf(id), 1)
        } else {
            // add
            resList.push(id)
        }

        const productData = {
            "resource_list": resList,
            "org_id": props.productData.org_id,
            "products": props.productData.products
        }

        props.setProductData(productData)
    }

    return(
        <div className="res-name">
            <input 
                type="checkbox" 
                className="form-check-input" 
                onChange={(e)=>selectChange(!selected, e)} 
                checked={selected}
                value={props.resource.res_id} />
        </div>
    )
}

interface ProductRowProps {
    product: ProductResourcesModel
    orgId: string
    setIsLoading: (a: boolean)=>void
    resources: ResourceModel[]
}

interface ProductDataModel {
    resource_list: string[]
    org_id: string
    products: string[]
}

const ProductRow:FC<ProductRowProps> = (props) => {
    const isMounted = useRef(false)

    const [productData, setProductData] = useState<ProductDataModel>({
        "resource_list": props.product.resources,
        "org_id": props.orgId,
        "products": [
            props.product.product_id
        ]
    })

    const {getAccessTokenSilently} = useAuth0();

    useEffect(function effectFunction() {

        async function linkResource() {
            props.setIsLoading(true)
            const token = await getAccessTokenSilently()
            const response = DataServiceResource.linkResource(productData, token)
            try {
                const dataResponse = await response
                props.setIsLoading(false)
                
            } catch(e) {
                props.setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            linkResource()
        } else {
            isMounted.current = true;
        }
        

    }, [productData])

    

    return(
        <div className="d-flex justify-content-between w-100 border-bottom">
            <div className="product-name">{props.product.item}</div>
            {props.resources.map((resource)=><ResourceItem productData={productData} setProductData={setProductData} productResources={props.product.resources} key={resource.res_id} resource={resource} orgId={props.orgId} setIsLoading={props.setIsLoading} />)}
        </div>
    )
}

interface ProductsProps {
    products: ProductResourcesModel[]
    orgId: string
    setIsLoading: (a: boolean)=>void
    resources: ResourceModel[]
}

const Products:FC<ProductsProps> = (props) => {
    
    return(
        <div>
            {props.products.map((product)=><ProductRow key={product.product_id} orgId={props.orgId} setIsLoading={props.setIsLoading} product={product} resources={props.resources} />)}
        </div>
    )
}


const sortProducts = (dataArray: ProductResourcesModel[]) => {
    return dataArray.sort(function(a, b) {
        const nameA = a.item.toUpperCase(); // ignore upper and lowercase
        const nameB = b.item.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
    });
}

const sortResources = (dataArray: ResourceModel[]) => {
    return dataArray.sort( (a, b) => {
        const nameA = a.res_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.res_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
    })
}

const LinkProductsResources:FC = () => {

    const [resources, setResources] = useState<ResourceModel[]>([])
    const [products, setProducts] = useState<ProductResourcesModel[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(0)
    const [update, setUpdate] = useState(0)
    const [orgData, setOrgData] = useContext(OrganisationContext);

    const {getAccessTokenSilently} = useAuth0();

    useEffect(function effectFunction() {

        async function resourceDetails() {
            setIsLoading(true)
            
            const token = await getAccessTokenSilently()
            const response = DataServiceOrganisation.resourceDetail(getID(), token)
            try {
                const dataResponse = await response
                const orderedRes = sortResources(dataResponse.data)
                setResources(orderedRes)
                
            } catch(e) {
                setIsLoading(false)
            }
        }
        resourceDetails()

        async function fetchProducts() {
            setIsLoading(true)
            const token = await getAccessTokenSilently()
            const response = DataServiceProduct.getProducts(getID(), token)
            try {
                const dataResponse = await response
                const orderedData = sortProducts(dataResponse.data)
                setProducts(orderedData)
                setIsLoading(false)
                
            } catch(e) {
                setIsLoading(false)
            }
        }
        fetchProducts()
        

    }, [reload])

    return(
        <div className="link-products">
            {isLoading && <LoadingSpinner />}
            <HeaderRow resources={resources} />
            <Products setIsLoading={setIsLoading} resources={resources} products={products} orgId={getID()} />
        </div>
    )
}

export default LinkProductsResources