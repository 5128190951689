import React, {FC, useState, useRef, useEffect, ChangeEvent} from 'react';
import ResourceModel from '../../interfaces/ResourceModel';
import InlineError from '../elements/InlineError';

interface AddResourceProps {
    addResource: (a: ResourceModel[])=>void
    resources: ResourceModel[]
}

const AddResource:FC<AddResourceProps> = (props) => {
    const blankInputs: ResourceModel = {
        name: '',
        ID: '',
        res_name: '',
        res_id: ''
    }
    const [inputs, setInputs] = useState(blankInputs)

    const addResource = () => {
        props.addResource([inputs])
        setInputs(blankInputs)
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const checkID = () => {
        const exists = props.resources.filter(resource => resource.ID == inputs.ID)
        if( exists.length > 0) return true
        return false
    }

    return (
        <div className="row mb-3">
            <h5>Add Resource</h5>
            {checkID() && <div className="col-12"><InlineError errorMessage="ID already exists" /></div>}
            <div className="col-5">
                <div className="form-floating">
                    <input type="text" className="form-control" name="name" value={inputs.name} onChange={handleChange} />
                    <label htmlFor="floatingInput">Name</label>
                </div>
            </div>
            <div className="col-5">
                <div className="form-floating">
                    <input type="text" className="form-control" name="ID"  value={inputs.ID} onChange={handleChange} />
                    <label htmlFor="floatingInput">ID (lower case and numbers only)</label>
                </div>
            </div>
            <div className="col-2">
                <button disabled={checkID()} className="btn btn-outline-primary w-100 h-100" onClick={()=>addResource()}>Add Resource</button>
            </div>
        </div>
    )
}

export default AddResource