import React, {FC, useState, ChangeEvent, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataServiceBrand from "../../services/DataServiceBrand";
import LoadingSpinner from '../global/LoadingSpinner';
import InlineError from '../elements/InlineError';
import UploadIcon from './UploadIcon';
import TextInputRow from '../formfields/TextInputRow';
import Button from '../elements/Button';

interface AddBrandProps {
    closeModal: ()=>void
    setMessage: (a: string)=>void
}

const AddBrand:FC<AddBrandProps> = (props) => {

    const {user, getAccessTokenSilently } = useAuth0();

    const [iconUrl, setIconUrl] = useState('')
    const [inputs, setInputs] = useState({
        brand_name: '',
        user_id: user?user.sub:'',
        display_name: '',
        primary: '#0080c0',
        background: '#e0e0e0',
        icon_file: iconUrl,

        sandbox_brand: false,
        donor_brand: '',
        org_limit: 0
    })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const handleSubmit = async () => {
        setIsLoading(true)
        const token = await getAccessTokenSilently();
        const body = JSON.stringify({
            brand_name: inputs.brand_name,
            user_id: inputs.user_id,
            display_name: inputs.display_name,
            primary:inputs.primary,
            background: inputs.background,
            logo_url: iconUrl,

            sandbox_brand: inputs.sandbox_brand,
            donor_brand: inputs.donor_brand,
            org_limit: inputs.org_limit
        })
        const response = DataServiceBrand.createBrand(body, token)
        try {
            const brandResponse = await response
            props.closeModal()
            props.setMessage(brandResponse.data.message)
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        const name = field;
        const value = event.target.value;

        setInputs(values => ({...values, [name]: value}))
    }

    return (
        <div className="position-relative">
            {isLoading && <LoadingSpinner />}
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            <TextInputRow label={'Brand Name'} description={'Brand name must be lower case with no spaces.'} defaultValue={inputs.brand_name} field={'brand_name'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label={'Display Name'} defaultValue={inputs.display_name} field={'display_name'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <UploadIcon iconUrl={iconUrl} setIconUrl={setIconUrl} />
            <TextInputRow label={'Primary Colour'} type="color" defaultValue={inputs.primary} field={'primary'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label={'Background Colour'} type="color" defaultValue={inputs.background} field={'background'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <div className="mb-3">
                <h3>Sandbox Brand</h3>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="sandbox_brand" id="defaultsyes" value="true" onChange={(e)=>handleChange(e, 'sandbox_brand')} />
                    <label className="form-check-label" htmlFor="defaultsyes">Yes - populate brand with default organisations</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="sandbox_brand" id="defaultsno" value="false" onChange={(e)=>handleChange(e, 'sandbox_brand')} />
                    <label className="form-check-label" htmlFor="defaultsno">No - leave the brand empty</label>
                </div>
            </div>
            <TextInputRow label={'Donor Brand'} defaultValue={inputs.donor_brand} field={'donor_brand'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label={'Organisation Limit'} type="number" defaultValue={inputs.org_limit} field={'org_limit'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />

            <Button label={'Save Changes'} type={'primary'} action={handleSubmit} />

        </div>
    )
}

export default AddBrand