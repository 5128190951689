import axios from 'axios';
import { getConfig } from "../config";

const API_URL = 'https://cal.develop.nonprodsvc.com/';
const { apiOrigin = "//afp-com.development.nonprodsvc.com/v1", audience } = getConfig();


// const API_URL = 'http://localhost:8000/';
// const { apiOrigin = "http://localhost:8506/v1", audience } = getConfig();

class DataService {
    getOrganisations() {
        return axios.get(API_URL + 'orgs');
    }

    getBookings(orgId: string, resName: string, fromDate: string) {
        return axios.get(API_URL + 'get-resource-events-fromdate' +
            '?res_name=' + resName +
            '&org_id=' + orgId +
            '&start_date=' + fromDate);
    }

    getCalendarResource(orgId: string) {
        return axios.get(API_URL + 'get-organisation-resources' +
            '?org_id=' + orgId);
    }

    getAllEvents(orgId: string, startDate: string) {
        return axios.get(API_URL + 'get_organisation_events_fromdate' +
        '?org_id=' + orgId +
        '&start_date=' + startDate);
    }

    getAllResources() {
        return axios.get(API_URL + 'allResources');
    }
    
    getAllOrgs() {
        return axios.get(API_URL + 'get-organisations');
    }

    getOrgDefaults(orgId: string) {
        return axios.get(API_URL + 'get-organisation-defaults?org_id=' + orgId)
    }

    sendOrgDefaults(data: any) {
        return axios({
            method: 'put',
            url: API_URL + 'update-org-schedule',
            headers: {'Content-Type': 'application/json'}, 
            data: data
        });
    }

    getResSchedule(orgId: string, resId: string) {
        return axios.get(API_URL + 'retrieve-res-details?org_id=' + orgId + '&res_id=' + resId)
    }

    sendResSchedule(data: string) {
        return axios({
            method: 'put',
            url: API_URL + 'update-res-schedule',
            headers: {'Content-Type': 'application/json'}, 
            data: data
        });
    }

    createBooking(data: string) {
        return axios({
            method: 'post',
            url: API_URL + 'create-event',
            headers: {'Content-Type': 'application/json'}, 
            data: data
        });
    }

    createBookingNta(data: string) {
        return axios({
            method: 'post',
            url: API_URL + 'create-event-nta',
            headers: {'Content-Type': 'application/json'}, 
            data: data
        });
    }
    
    getSampleProducts() {
        return axios.get(API_URL + 'sample-products');
    }

    getEvents(id: string) {
        return axios.get(API_URL + 'get-customer-events' +
            '?cust_uuid=' + id);
    }

    deleteEvent(id: string) {
        return axios.delete(API_URL + 'delete-event' +
            '?event_id=' + id);
    }

    getSpecialEvents(id: string) {
        return axios.get(API_URL + 'list-special-events?org_id=' + id)
    }

    deleteSpecialEvent(id: string, orgId: string) {
        return axios.delete(API_URL + 'delete-special-event?id=' + id + '&org_id=' + orgId)
    }

    getSpecialEventById(id: string, orgId: string) {
        return axios.get(API_URL + 'retrieve-special-event-by-id?org_id=' + orgId + '&special_event_id=' + id)
    }

    getEvent(id: string) {
        return axios.get(API_URL + 'get-event_details?event_id=' + id)
    }

    updateSpecialEvent(data: any) {
        return axios({
            method: 'put',
            url: API_URL + 'update-special-event',
            headers: {'Content-Type': 'application/json'}, 
            data: data
        });
    }

    createSpecialEvent(data: any) {
        return axios({
            method: 'post',
            url: API_URL + 'create-special-event',
            headers: {'Content-Type': 'application/json'}, 
            data: data
        });
    }

    getClashingEvents(id: string) {
        return axios.get(API_URL + 'list-clashing-events?org_id=' + id)
    }

    overrideProblem(data: any) {
        return axios({
            method: 'put',
            url: API_URL + 'override-problem',
            headers: {'Content-Type': 'application/json'}, 
            data: data
        })
    }



    checkPlaces(string: any) {
        const GOOGLE_MAP_API_KEY = 'AIzaSyBQlhPy8ePwqBWYpfQSOUAlK2nd_UZID0A';
        return axios({
            method: 'get',
            url: `//maps.googleapis.com/maps/api/place/queryautocomplete/json?input=${string}&fields=postal_code&types=address&key=${GOOGLE_MAP_API_KEY}`,
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
        });
    }

    checkPlace(string: any) {
        const GOOGLE_MAP_API_KEY = 'AIzaSyBQlhPy8ePwqBWYpfQSOUAlK2nd_UZID0A';
        return axios({
            method: 'get',
            url: `//maps.googleapis.com/maps/api/place/details/json?place_id=${string}&key=${GOOGLE_MAP_API_KEY}`,
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
        });
    }

    //admin
    // getCategories(catId) {
    //     return axios({
    //         method: 'get',
    //         url: `${apiOrigin}/category-attributes?cat_id=${catId}`,
    //         headers: { 'Content-Type': 'application/json' , 'accept': 'application/json' }
    //     });
    // }

}

export default new DataService();
