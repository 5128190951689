import React, {FC, useState, useRef, useEffect, useContext, ChangeEvent} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import ProductModel from '../../interfaces/ProductModel';
import DataServiceProduct from '../../services/DataServiceProduct';
import DataServiceResource from '../../services/DataServiceResource';
import getID from '../../helpers/getID';
import TextInputRow from '../formfields/TextInputRow';
import FormRow from '../formfields/FormRow';
import FormLabel from '../formfields/FormLabel';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ResourceModel from '../../interfaces/ResourceModel';
import DataServiceClient from '../../services/DataServiceClient';
import LoadingSpinner from '../global/LoadingSpinner';
import Attribute from '../../interfaces/AttributeModel';

interface NTAModel {
    display_name: string,
    value: number,
    nta_encoded: string
}

interface NTAListProps {
    ntaList: NTAModel[],
    onFieldChange: (arg1: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, arg2: string)=>void
}

const NtaListItems:FC<NTAListProps> = (props) => {

    return (
        <div>
        {props.ntaList.map(nta =>
            <TextInputRow 
                label={nta.display_name} 
                key={nta.nta_encoded}
                defaultValue={nta.value} 
                field={nta.nta_encoded} 
                onFieldChange={props.onFieldChange} 
                inputGroup={false} 
                buttonAction={()=>null} />
        )}
        </div>
    )
}

interface AttributeListProps {
    attributeListItem: any
    handleChange: (a: React.ChangeEvent<HTMLInputElement>, b: boolean)=>void
}

const AttributeListItem:FC<AttributeListProps> = (props) => {

    const attribute = props.attributeListItem

    return (
        <FormControlLabel control={<Checkbox onChange={props.handleChange} />} name={attribute.display_name} label={attribute.display_name} />
    )
}

interface CreateEventSearchParamsModel {
    date: string,
    time: string,
    nta_capacity?: string,
    nta_duration: string,
    attribute_set: string[]
}

interface CreateEventModel {
    brand: string,
    product_id: string,
    org_id: string,
    resource: string,
    search_params: CreateEventSearchParamsModel,
    client_uuid: string,
    save_flag: string,
    customer: string,
    call_type: string,
    text_notes: string
}

interface AddEventProps {
    time: string,
    date: string,
    resource: string,
    brandId: string,
    resources: ResourceModel[]
    refreshCalendar: (a: number)=>void
}
const AddEvent:FC<AddEventProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState<ProductModel[]>([])
    const [reloadProducts, setReloadProducts] = useState(0)
    const [ ntaList, setNtaList ] = useState<NTAModel[]>([])
    const [attributeList, setAttributeList] = useState<string[]>([])
    const [ errorMessage, setErrorMessage] = useState('')
    const {
        getAccessTokenSilently
    } = useAuth0();

    const [eventData, setEventData] = useState<CreateEventModel>({
        brand: props.brandId,
        product_id: '',
        org_id: getID(),
        resource: props.resource,
        search_params: {
            date: props.date,
            time: props.time,
            nta_capacity: '',
            nta_duration: '',
            attribute_set: []
        },
        client_uuid: 'aa6d312b-2d6e-4f02-98d5-91e4c749b425',
        save_flag: 'False',
        customer: '',
        call_type: 'console',
        text_notes: ''
    })

    useEffect(function effectFunction() {

        let isMounted = true;  

        async function fetchProducts() {
            setIsLoading(true)
            const token = await getAccessTokenSilently()
            const response = DataServiceProduct.getProducts(getID(), token)
            try {
                const dataResponse = await response
                setProducts(dataResponse.data)
                setIsLoading(false)
                
            } catch(e) {
                setIsLoading(false)
            }
        }
        if(isMounted) fetchProducts()
        return () => { isMounted = false }
    }, [reloadProducts])

    async function onSubmit(e: any) {
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        const response = DataServiceClient.createEvent(eventData);
        try {
            const eventsResponse = await response
            props.refreshCalendar(Math.random())
            // set probelm events number
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setErrorMessage(e.response.data.detail[0])
            } else {
                setErrorMessage(e.message)
            }
        }
    }


    const selectProduct = (e: SelectChangeEvent) => {
        setEventData(values => ({...values, ['product_id']: e.target.value}))

        // get NTAs
        const product = products.find(item => item.product_id === e.target.value)
        if( product !== undefined ) {
            const ntaList = product.NTA
            setNtaList(ntaList)
            setAttributeList(product.attribute_set)

            
            let searchParams = eventData.search_params;

            ntaList.map(item => {
                if( item.nta_encoded == 'nta_capacity') searchParams.nta_capacity = item.value;
                if( item.nta_encoded == 'nta_duration') searchParams.nta_duration = item.value;
            })
                
            setEventData(values => ({...values, ['search_params']: searchParams}))
            
        }
        
    }

    const selectResource = (e: SelectChangeEvent) => {
        setEventData(values => ({...values, ['resource']: e.target.value}))        
    }

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        let isMounted = true
        const value = event.target.value;
        if(field == 'date') {
            let searchParams = eventData.search_params;
            searchParams.date = value;
            if(isMounted) setEventData(values => ({...values, ['search_params']: searchParams}))
        }
        else if(field == 'time') {
            let searchParams = eventData.search_params;
            searchParams.time = value;
            if(isMounted) setEventData(values => ({...values, ['search_params']: searchParams}))
        }
        else if(field == 'nta_capacity') {
            let searchParams = eventData.search_params;
            searchParams.nta_capacity = value;
            if(isMounted) setEventData(values => ({...values, ['search_params']: searchParams}))
        }
        else if(field == 'nta_duration') {
            let searchParams = eventData.search_params;
            searchParams.nta_duration = value;
            if(isMounted) setEventData(values => ({...values, ['search_params']: searchParams}))
        }
        if(isMounted) setEventData(values => ({...values, [field]: value}))
        return () => { isMounted = false }
    }

    const attrCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let name = event.target.name
        const oldArray = eventData.search_params.attribute_set

        //check if in array
        let exists = oldArray.filter(item => item == name)

        // let attributes: string[] = []

        if( !exists.length ) {
            oldArray.push(event.target.name)
        } else {
            // remove from array
            oldArray.splice(oldArray.indexOf(event.target.name), 1)
        }

        let searchParams = eventData.search_params;
        searchParams.attribute_set = oldArray;
        setEventData(values => ({...values, ['search_params']: searchParams}))
    };

    return (
        <Box sx={{padding: '20px'}}>
        
            <h3>Add Event</h3>
            {isLoading && <LoadingSpinner/>}

            {errorMessage !== '' && errorMessage}

            <TextInputRow label="Customer Name" defaultValue={eventData.customer} onFieldChange={onFieldChange} field="customer" inputGroup={false} buttonAction={()=>null} />

            <FormRow>
                <FormLabel label={'Select Product'} />
                <Select
                    sx={{width: '100%'}} 
                    value={eventData.product_id}
                    label="Select Product"
                    onChange={(e)=>selectProduct(e)}
                >
                    {products.map(item=>(<MenuItem key={item.product_id} value={item.product_id}>{item.item}</MenuItem>))}
                </Select>
            </FormRow>

            <TextInputRow label="Date" type="date" defaultValue={eventData.search_params.date} onFieldChange={onFieldChange} field="date" inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label="Time" type="time" defaultValue={eventData.search_params.time} onFieldChange={onFieldChange} field="time" inputGroup={false} buttonAction={()=>null} />

            <FormRow>
                <FormLabel label={'Select Resource'} />
                <Select
                    sx={{width: '100%'}} 
                    value={eventData.resource}
                    label="Select Resource"
                    onChange={(e)=>selectResource(e)}
                >
                    {props.resources.map(item=>(<MenuItem key={item.name} value={item.name}>{item.res_name}</MenuItem>))}
                </Select>
            </FormRow>

            { ntaList.length > 0 &&
                <NtaListItems onFieldChange={onFieldChange} ntaList={ntaList} />
            }

            { attributeList.length > 0 &&
            
                <FormGroup>
                    {attributeList.map(item=><AttributeListItem key={item} handleChange={attrCheckboxChange} attributeListItem={item} />)}
                </FormGroup>
            }

            <FormRow>
                <FormLabel label={'Notes'} />
                <textarea onChange={(e)=>onFieldChange(e, 'text_notes')} className="form-control" name="text_notes" defaultValue={eventData.text_notes}/>
            </FormRow>

            <Button onClick={(e)=>onSubmit(e)}>Add Event</Button>
        </Box>
    )
}

export default AddEvent