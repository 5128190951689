import React, {FC, ReactNode} from 'react';

interface ListGroupProps{
    children: ReactNode;
}

const ListGroup: FC<ListGroupProps> = (props) => {
    return (
        <div className="list-group mb-4">
            {props.children}
        </div>
    )
}

export default ListGroup