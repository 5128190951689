import React, {FC, useState, useRef, useEffect, ChangeEvent} from 'react';
import ResourceModel from '../../interfaces/ResourceModel';
import Resource from '../organisationDetails/Resource';
import AddResource from './AddResource';

interface ResourcesProps {
    resources: ResourceModel[]
    addResource: (a: ResourceModel[])=>void
    removeResource: (a:string)=>void
    orgId: string
}

const InlineResources: FC<ResourcesProps> = (props) => {
    return (
        <div>
            
            { props.resources.length > 0 &&
                <div className="list-group mb-3">
                    {props.resources.map((resource)=><Resource orgId={props.orgId} removeResource={props.removeResource} data={resource} key={resource.ID} />)}
                </div>
            }
            <AddResource resources={props.resources} addResource={props.addResource} />
        </div>
    )
}

export default InlineResources