import React, {ChangeEvent, useContext, useState, FC, useRef, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import DataServiceProduct from '../../services/DataServiceProduct';
import InlineError from '../elements/InlineError';
import LoadingSpinner from '../global/LoadingSpinner';
import SelectCategories from './SelectCategories';
import ProductModel from '../../interfaces/ProductModel';
import {NTAModel} from '../../interfaces/ProductModel';
import FormRow from '../formfields/FormRow';
import FormLabel from '../formfields/FormLabel';
import TextInputRow from '../formfields/TextInputRow';
import Button from '../elements/Button';
import CategoryModel from '../../interfaces/CategoryModel'
import { ModalContext } from "../../contexts/modalContext";

interface AddOrgProps {
    brandId: string;
    orgId: string;
    data: ProductModel;
    setReloadProducts: (a:number)=>void;
    reloadProducts: number;
}

const EditProduct:FC<AddOrgProps> = (props) => {

    const CAT_ROOMS = [ 45, 34, 35, 36]

    let { handleModal } = useContext<any>(ModalContext)
    const isMounted = useRef(false)
    const {
        user,
        getAccessTokenSilently,
    } = useAuth0();

    const [inputs, setInputs] = useState<ProductModel>({  
        "brand_id": props.brandId,
        "org_id": props.orgId,
        "product_id": props.data.product_id,
        "item": '',
        "description": '',
        "categories": [0],
        "attribute_set": [''],
        "NTA": []
    })

    useEffect(function effectFunction() {

        let isMounted = true;  
        async function fetchProducts() {
            if (isMounted) setIsLoading(true)
            const token = await getAccessTokenSilently()
            const response = DataServiceProduct.getProductDetail(props.data.product_id, token)
            try {
                const dataResponse = await response
                if (isMounted) setInputs({
                    "brand_id": props.brandId,
                    "org_id": props.orgId,
                    "product_id": props.data.product_id,
                    "item": dataResponse.data.item,
                    "description": dataResponse.data.description,
                    "categories": dataResponse.data.categories,
                    "attribute_set": dataResponse.data.attribute_set,
                    "NTA": dataResponse.data.NTA
                })
                if (isMounted) setIsLoading(false)
                
            } catch(e) {
                if (isMounted) setIsLoading(false)
            }
        }
        fetchProducts()
        return () => { isMounted = false }

    }, [])
    

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [minSlotIsValid, setMinSlotIsValid] = useState(true)
    const [allCats, setAllCats] = useState<CategoryModel[]>([])

    const handleChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        
            const name = field;
            const value = event.target.value;

            setInputs(values => ({...values, [name]: value}))

    }

    const submitForm = async () => {

        console.log(inputs)

        const token = await getAccessTokenSilently()
        const response = DataServiceProduct.editProduct(inputs, token)
        try {
            const brandResponse = await response
            props.setReloadProducts(props.reloadProducts+1)
            setIsLoading(false)
            handleModal()
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.errors[0])
            } else {
                setError(e.message)
            }
        }
    }
    
    const setCategories = (cats: number[]) => {
        setInputs(values => ({...values, ['categories']: cats}))
    }

    const setNtas = (ntas: NTAModel[]) => {
        setInputs(values => ({...values, ['NTA']: ntas}))
    }

    const setAttributes = (attributes: string[]) => {
        setInputs(values => ({...values, ['attribute_set']: attributes}))
    }

    // const msToMins = () => {
    //     return inputs.duration / 60
    // }

    // const updateSlotLength = (e: ChangeEvent<HTMLInputElement>) => {

    //     const value = parseInt(e.target.value)

    //     if( value % 5 === 0 ) {
    //         const minSlot = value * 60
    //         setInputs(values => ({...values, ['duration']: minSlot}))
    //         setError('')
    //         setMinSlotIsValid(true)
    //     } else {
    //         setError('Slot length must be a multiple of 5')
    //         setMinSlotIsValid(false)
    //     }

    // }

    // const showCapacity = (): boolean => {
    //     if( inputs.categories.some(r=> CAT_ROOMS.includes(r)) ) return true
    //     return false
    // }

    // const capacityString = () => {
    //     if( props.data.NTA == undefined ) return ''
    //     const capacity = props.data.NTA.filter(item=> item.nta_encoded === 'capacity')
    //     if( capacity.length === 0) return ''
    //     return capacity[0].value
    // }

    return (
        <div className="position-relative">
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            { isLoading ?
                <LoadingSpinner/>
                :
                <div>
                    
                    <TextInputRow label={'Name'} defaultValue={inputs.item} field="item" onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
                    <TextInputRow label={'Description'} defaultValue={inputs.description} field={'description'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
                    <FormRow>
                        <SelectCategories 
                            setAllCats={setAllCats} 
                            categories={inputs.categories} 
                            setCategories={setCategories} 
                            attributes={inputs.attribute_set} 
                            setAttributes={setAttributes}
                            ntas={inputs.NTA} 
                            setNtas={setNtas}
                            showattrs={true}
                            setWhatChanged={()=>null} />
                    </FormRow>
                    <FormRow>
                        <Button label={'Save Changes'} type={'primary'} action={()=>submitForm()} />
                    </FormRow>
                </div>
            }
        </div>
    )
}

export default EditProduct