import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { ComponentType } from 'react';
import { EdgeProps, getBezierPath, Position } from 'reactflow';

const foreignObjectSize = 102;

const onEdgeClick = (evt: any, id: any) => {
  evt.stopPropagation();
  alert(`remove ${id}`);
};

const DdcConnector:ComponentType<EdgeProps<any>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}) => {
  
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <Box sx={{
          background: 'pink',
          width: '100px',
          height: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          border: '1px solid rgba(0,0,0,0.5)',
          marginTop: '1px',
          marginLeft: '1px'
        }}>
          <Typography variant="h2" sx={{fontSize: '1.4rem'}}>{data.option.response}</Typography>
          {/* <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
           
          </button> */}
        </Box>
      </foreignObject>
    </>
  );
}

export default DdcConnector