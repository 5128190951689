import axios from 'axios';
import { getConfig } from "../config";

const { apiOrigin = "https://suite.develop.nonprodsvc.com/v1", audience } = getConfig();

class DataServiceResource {

    addResource(data: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/resource/add-organisation-resource`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
            data: data
        });
    }
    
    deleteResource(brand: any, orgId: any, resId: any, token: any) {
        return axios({
            method: 'delete',
            url: `${apiOrigin}/resource/organisation-resource?brand=${brand}&org_id=${orgId}&res_id=${resId}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }

    resourceProducts(resId: any, orgId: any, token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/resource/resource-products?res_id=${resId}&org_id=${orgId}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }

    linkResource(body: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/resource/link-resource`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
            data: body
        });
    }

    updateResource(orgId: any, body: any, token: any) {
        return axios({
            method: 'put',
            url: `${apiOrigin}/resource/update-resource?org_id=${orgId}&res_id=${body.res_id}&res_name=${body.res_name}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }
}
export default new DataServiceResource();