import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class Resource extends Component {
    constructor(props) {
        super(props);
        this.handleClick.bind(this);
    }

    handleClick = () => {
        // Simply call the setStateOfParent function from 
        // prop and pass required argument
        this.props.setResourceId(this.props.data.name, this.props.data.res_name );
    }
    render() {
        return (
            <button className="list-group-item" onClick={this.handleClick} key={this.props.data.name}>{this.props.data.res_name}</button>
        )
    }
}

function ResourceList(props){
    if( props.resources.length > 0) {
        return props.resources.map((organisation)=><Resource setResourceId={props.setResourceId} data={organisation} key={organisation.name} />)
    } else {
        return <p>Nothing found</p>
    }
}

const BookingResources = (props) => {
    return (
        <div>
            <h2>Resources</h2>
            <ul className="list-group">
                <ResourceList setResourceId={props.setResourceId} resources={props.resources} />
            </ul>
        </div>
    );
}

export default BookingResources;