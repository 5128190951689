import React, {FC, createContext, useContext, ReactNode} from "react";
import useModal from "../hooks/useModal";
import Modal from "../components/global/modal";
import { ModalContextType } from '../interfaces/ModalContextType'

let ModalContext: React.Context<Partial<ModalContextType>> | React.Context<unknown> | React.Context<{ modalContent: any; modalTitle: any; handleModal: any; modal: any; wide: any }> | React.Context<{ handleModal: any; }>
let { Provider } = (ModalContext = createContext<Partial<ModalContextType>>({
    modal: false, 
    modalTitle: '', 
    handleModal: () => {}, 
    modalContent: '',
    wide: false
}));

interface Props {
    children: ReactNode;
}

let ModalProvider:FC<Props> = ({ children }) => {
    let { modal, modalTitle, handleModal, modalContent, wide } = useModal();
    return (
        <Provider value={{ modal, modalTitle, handleModal, modalContent, wide }}>
            <Modal />
            {children}
        </Provider>
    );
};

export { ModalContext, ModalProvider };