import React, {FC, useState, useRef, useEffect, useContext, MouseEvent} from 'react';
import DataService from "../../services/data.service";
import LoadingSpinner from '../global/LoadingSpinner';
import { OrganisationContext } from '../../contexts/OrganisationContext';
import ResourceModel from '../../interfaces/ResourceModel';
import SpecialEventModel from '../../interfaces/SpecialEventModel';
import { Button, Drawer, Grid, Popover, Toolbar, Typography } from '@mui/material';
import EditSpecialEvent from './EditSpecialEvent';
import AddSpecialEvent from './AddSpecialEvent';


interface ResListProps {
    selectedResources: string[]
    allResources: ResourceModel[]
}
const ResourcesList:FC<ResListProps> = (props) => {
    let resList = <div>None</div>

    let findResById = (id: string, resources: ResourceModel[]) => {
        let matching = resources.find(resource=>resource.name==id)
        if( matching ) {
            return resources.find(resource=>resource.name==id)
        } else {
            return {res_name: ''}
        }
    }
    
    if( props.selectedResources.length ) {
        resList = <div>{props.selectedResources.map(
            (resource)=>{
                const res = findResById(resource, props.allResources)
                return <span key={resource} className="badge bg-info">{res? res.res_name : 'Unknown'}</span>
            }
        )}</div>
    } 
    return (
        resList
    )
}

interface EventItemProps {
    deleteEvent: (a: string)=>void
    data: SpecialEventModel
    allResources: ResourceModel[]
    orgId: string
    setUpdate: (a: number)=>void
}
const EventItem:FC<EventItemProps> = (props) => {

    type Anchor = 'top' | 'left' | 'bottom' | 'right';

    const [editOpen, setEditOpen] = useState(false)
    // const [confirmDelete, setConfirmDelete] = useState(false)

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const deleteEvent = () => {
        props.deleteEvent(props.data.id? props.data.id: '')
    }

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setEditOpen(false);
            };

    const editEvent = () => {
        // open drawer
        setEditOpen(true)
    }

    const getDate = () => {
        return new Date(props.data.date).toDateString()
    }

    const makeSpecialEventUpdate = () => {
        props.setUpdate(Math.random())
        setEditOpen(false)
    } 

    const confirmDelete = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="special-events-item">
            <div className="d-flex w-100 justify-content-between align-items-center">
                <div className="special-events-heading">
                    <h5 className="mb-0">{props.data.recurring_description}</h5>
                    <p className="mb-0"><ResourcesList allResources={props.allResources} selectedResources={props.data.resource_set} /></p>
                </div>
                <p className="mb-0">{getDate()} | {props.data.start_time}-{props.data.end_time}</p>
                <p className="mb-0 special-events-recurring">
                    <small>
                        Recurring: {props.data.recurring}
                        <br/>Recurring End Date: {props.data.recurring_end}
                    </small>
                </p>
                <p className="mb-0 text-end position-relative"><small>
                    <button className="btn btn-link p-0" onClick={(e) => editEvent()}>Edit</button><br/>

                        <Popover 
                            sx={{textAlign: 'center'}}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >   
                            <Typography paragraph={true} sx={{margin: '15px', marginBottom: 0}}>You are Deleting '{props.data.recurring_description}'. Are you sure?</Typography>
                            <Button sx={{margin: '15px'}} variant="outlined" color={"secondary"} onClick={()=>handleClose()}>No - Cancel</Button>
                            <Button sx={{margin: '15px'}} variant="outlined" color={"primary"} onClick={()=>deleteEvent()}>Yes - Delete</Button>
                            {/* <Button sx={{margin: '15px'}} variant="outlined" color={"error"} onClick={()=>props.setDeleteId(orgData.org_id)}>Yes - Delete</Button> */}
                        </Popover>

                    <button className="btn btn-link p-0" onClick={(e) => confirmDelete(e)}>Remove</button></small>
                </p>
            </div>
            <Drawer
                anchor={'right'}
                open={editOpen}
                onClose={toggleDrawer('right', editOpen)}
                sx={{padding: '20px'}}
                >
                    <Toolbar />
                    <EditSpecialEvent 
                        specialEventId={props.data.id? props.data.id: ''} 
                        orgId={props.orgId} 
                        resources={props.allResources} 
                        makeSpecialEventUpdate={()=>makeSpecialEventUpdate()}  /></Drawer>
        </div>
    )
}

const SpecialEvents:FC = () => {
    const [orgData, setOrgData] = useContext(OrganisationContext);
    const isMounted = useRef(false);
    const [isLoading, setIsLoading] = useState(false)
    const [specialEventsArray, setSpecialEvents] = useState([])
    const [deleteId, setDeleteId] = useState('')
    const [showError, setShowError] = useState('')
    const [resources, setResources] = useState<ResourceModel[]>([])
    const [update, setUpdate] = useState(0)

    type Anchor = 'top' | 'left' | 'bottom' | 'right';

    const [addOpen, setAddOpen] = useState(false)

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setAddOpen(false);
            };

    const addEvent = () => {
        // open drawer
        setAddOpen(true)
    }

    useEffect( () => {

        async function fetchResources() {
            setIsLoading(true)
            const response = DataService.getCalendarResource(orgData.org_id)

            try {
                const resourcesResponse = await response;

                if( resourcesResponse.data.length == 0) {
                    setShowError('error loading resources')
                    setIsLoading(false)
                } else {
                    setResources(resourcesResponse.data)
                    setIsLoading(false)
                }
            } catch (e) {
                setShowError('error')
            }
        }
        fetchResources();

    }, [orgData.org_id, update])

    useEffect(function effectFunction() {

        async function fetchSpecialEvents() {
            setIsLoading(true)
            const response = DataService.getSpecialEvents(orgData.org_id)
            try {
                const eventsResponse = await response
                setIsLoading(false)
                setSpecialEvents(eventsResponse.data)
                
            } catch(e) {
                setShowError('error')
                setIsLoading(false)
            }
        }
        fetchSpecialEvents();

    }, [deleteId, orgData.org_id, update])

    useEffect(function effectFunction() {

        async function deleteEvent() {
            setIsLoading(true)
            const response = DataService.deleteSpecialEvent(deleteId, orgData.org_id)
            try {
                const eventsResponse = await response
                setIsLoading(false)
                setDeleteId('')
                // props.setUpdateClashing(props.updateClashing+1)
                
            } catch(e) {
                setShowError('error')
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            if( deleteId != '') {
                deleteEvent()
            }
        } else {
            isMounted.current = true;
        }
        

    }, [deleteId])

    const makeSpecialEventUpdate = () => {
        setUpdate(Math.random())
        setAddOpen(false)
    } 

    return (
        <div className="special-events">
            <Grid sx={{
                justifyContent: 'space-between', 
                display: 'flex', 
                alignItems: 'center', 
                marginBottom: '20px',
                '& h3': {
                    margin: 0
                }}}>

                <h3>Special Events</h3>
                <Button variant={"contained"} onClick={(e)=>addEvent()}>Add Special Event</Button>
                
                <Drawer
                    anchor={'right'}
                    open={addOpen}
                    onClose={toggleDrawer('right', addOpen)}
                    sx={{padding: '20px'}}
                    >
                        <Toolbar />
                        <AddSpecialEvent 
                            orgId={orgData.org_id} 
                            resources={resources} 
                            makeSpecialEventUpdate={makeSpecialEventUpdate}  />
                </Drawer>
            </Grid>

            {isLoading && <LoadingSpinner/> }
            <div className="special-events-list">
                {specialEventsArray.map((specialEvent: SpecialEventModel)=>
                    <EventItem 
                        allResources={resources} 
                        key={specialEvent.id} 
                        data={specialEvent} 
                        orgId={orgData.org_id}
                        deleteEvent={setDeleteId}
                        setUpdate={setUpdate}  />)}
            </div>
        </div>
    )
}

export default SpecialEvents