import React, {useState, ChangeEvent, useContext, useEffect, FC} from 'react';
import DataService from "../../services/data.service";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import Modal from '../Modal';
import Pagination from '../Pagination';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import Popover from '../global/Popover';
import { ModalContextType } from '../../interfaces/ModalContextType'
import { ModalContext } from "../../contexts/modalContext";
import Button from '../elements/Button';
import CategoryModel from '../../interfaces/CategoryModel';
import Flex from '../elements/Flex';
import { AttributeMain } from '../../interfaces/AttributeModel';
import EditAttribute from './EditAttribute';
import { NtaModel } from '../../interfaces/NtaModels';
import EditNta from './EditNta';

interface AttributeProps {
    update: number
    setUpdate: (a:number)=>void
    data: NtaModel
    setDeleteItem: (a: NtaModel)=>void
    setConfirmDelete: (a: NtaModel, b: string)=>void
    deleteText: string
    types: string[]
    operators: string[]
    fieldDesc: any
    catList: CategoryModel[]
}

const NtaItem:FC<AttributeProps> = (props) => {
    let { handleModal } = React.useContext<any>(ModalContext)

    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    
    const setDeleteItem = (text?: string) =>{
        setIsDeleting(true)
        props.setDeleteItem(props.data)
    }
    const confirmDeleteItem = (text?: string) =>{
        const confirmText = text?text:''
        props.setConfirmDelete(props.data, confirmText)
        setIsDeleting(false)
    }

    const openEditModal = () => {
        handleModal(
            <EditNta update={props.update} data={props.data} setUpdate={props.setUpdate} types={props.types} operators={props.operators} fieldDesc={props.fieldDesc} />
            , 
            `Edit NTA`,
            true
        )
    }

    const getCats = ():CategoryModel[] => {
        const catIds = props.data.category_set 
        return props.catList.filter(item=>catIds.indexOf(item.category_id) !== -1)
    }

    return (
            <Flex justify={`justify-content-between list-group-item`} align={'align-items-center'}>
                <div className={`mb-0 me-auto align-self-center `}>
                    <h5 style={{width: '100%'}}>
                        {props.data.display_name }
                    </h5>
                    <div>
                        {getCats().map(item=>(<span key={item.category_id} className="badge bg-primary me-1">{item.category_name}</span> )) }
                    </div>
                </div>
                <div className="d-inline" style={{position: 'relative', width: '100px'}}>
                    <Button label={'Edit NTA'} type={'link'} action={openEditModal} />
                </div>
                <div className="d-inline" style={{position: 'relative', width: '100px'}}>
                    {isDeleting && <Popover showInput={true} popWidth={300} closePop={setIsDeleting} confirmPop={confirmDeleteItem} message={props.deleteText} />}
                    <Button label={'Delete NTA'} type={'link'} action={(e)=>setDeleteItem()} />
                </div>
            </Flex>
    )
}

export default NtaItem