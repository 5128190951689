import React, {FC, MouseEvent} from 'react';

import Button from '@mui/material/Button';

interface ButtonProps{
    label: string
    type: string
    action: (e: MouseEvent)=> void
    size?: string
    disabled?: boolean
}

const getSize = (size: string | undefined) => {
    if( size !== undefined) {
        if( size == 'small') {
            return "small"
        }
        return "medium"
    } else {
        return "medium"
    }
}

const getColor = (type: string) => {
    if( type.includes('secondary')) return "secondary"
    if( type.includes('error')) return "error"
    return "primary"
}

const getVariant = (type: string) => {
    //"contained" | "text" | "outlined"
    if( type.includes('outline') ) return "outlined"
    if( type.includes('text') ) return "text"
    if( type.includes('link')) return "text"
    return "contained"
}

const ButtonUi: FC<ButtonProps> = (props) => {
    const size = getSize(props.size)
    const color = getColor(props.type)
    const variant = getVariant(props.type)

    return (
        <Button
            variant={variant}
            size={size}
            color={color}
            disabled={props.disabled} 
            onClick={(e)=>props.action(e)}>{props.label}</Button>
    )
}

export default ButtonUi