import { Grid } from '@mui/material';
import React, {useCallback, useEffect, FC} from 'react';
import ReactFlow, { Controls, Background, useNodesState, useEdgesState, ConnectionLineType, addEdge, MiniMap, Position } from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { Question } from './ManageDdc';
import DdcNode from './DdcNode';
import { DDCFlowContext } from '../../contexts/DdcFlowContext';
import DdcConnector from './DdcConnector';

interface FlowProps {
    questions: Question[]
}

const nodeTypes = {
    question: DdcNode,
};

const edgeTypes = {
    customEdge: DdcConnector,
  };

const minimapStyle = {
    height: 120,
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 350;
const nodeHeight = 300;

const getLayoutedElements = (nodes: any[], edges: any[], direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

export interface IQuestionOption {
    response: string,
    display_text: string,
    next?: string
}

const DdcFlow:FC<FlowProps> = ({questions}) => {

    let initialNodes: any[] | (() => any[]) = []
    let initialEdges: any[] | (() => any[]) = []

    let { incrementRefreshDDC } = React.useContext<any>(DDCFlowContext)


    const direction = 'LR';

    dagreGraph.setGraph({ rankdir: direction });

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(initialNodes, initialEdges);

    const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

    useEffect(()=>{


        const initNodes =  questions.map((question, i) => {

            const sameID = questions.filter(item => item.question?.question_id == question.question?.question_id)

            const responses = sameID.map(item => {
                if (typeof(item.response) != "undefined" && typeof(item.response.response) != "undefined" && typeof(item.response.display_text) != "undefined") {
                    const response = item.response;
                    if( typeof(response.next != "undefined" ) ) {
                        response.next = item.next?.question_id
                    }
                    return response
                }
            }) as IQuestionOption[]
            
            if (typeof(question.question) != "undefined") {
                return {
                    id: question.question?question.question.question_id:i+'',
                    type: 'question',
                    sourcePosition: Position.Top,
                    targetPosition: Position.Bottom,
                    data: { 
                        label: question.question.question_text + ' ' + question.question.question_text_2, 
                        questionData: question,
                        flowheader: question.flowheader?question.flowheader:{},
                        responses: responses.filter(item=>item.display_text !== null),
                        allQuestions: questions
                    },
                    position: { x: 0, y: 0 }
                }
            }
        })

        const initEdges = questions.map((question, i) => {
            if (typeof(question.question) != "undefined" && typeof(question.next) != "undefined") {
                return {
                    id: question.question.question_id.toString() + '-' + question.next?.question_id.toString(),
                    source: question.question.question_id.toString(),
                    target: question.next?.question_id.toString(),
                    label: 'handle_'+question.response?.response+question.question.question_id,
                    sourceHandle: 'handle_'+question.response?.response+question.question.question_id,
                    type: 'customEdge',  animated: true,
                    data: {
                        option: question.response
                    }
                }
            } else {
                return { id: '1-2', source: '1', target: '2', type: 'smoothstep',  animated: true }
            }
        })

        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            initNodes,
            initEdges
          );
          

        setNodes(layoutedNodes)
        setEdges(layoutedEdges)


    },[questions])
    

    const onConnect = useCallback(
        (params: any) =>
        setEdges((eds) =>
            addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds)
        ),
        []
    );
    const onLayout = useCallback(
        (direction: string | undefined) => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            nodes,
            edges,
            direction
        );

        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);
        },
        [nodes, edges]
    );

    

return (
    <Grid sx={{width: '100%', height: '100%'}}>
        <ReactFlow
            nodes={nodes}
            onNodesChange={onNodesChange}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
        >
            <Background />
            <Controls />
            <MiniMap style={minimapStyle} zoomable pannable />
        </ReactFlow>
    </Grid>
)
}

export default DdcFlow
