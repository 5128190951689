import React, { useState, FC, ReactNode } from 'react';
import ProblemEventModel from '../interfaces/ProblemEventModel';


const ProblemEventsContext = React.createContext<[number, (a: number)=>void]>([0, () => {}]);

interface Props {
    children: ReactNode;
}

const ProblemEventProvider:FC<Props> = ({ children }) => {
    const [problemEventData, setProblemEventData] = useState(0);

    return (
        <ProblemEventsContext.Provider value={[problemEventData, setProblemEventData]}>
            {children}
        </ProblemEventsContext.Provider>
    );
};

export { ProblemEventsContext, ProblemEventProvider };