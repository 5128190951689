import React, {FC, ChangeEvent} from 'react';
import CategoryModel from '../../interfaces/CategoryModel';

interface Props {
    parentCats: CategoryModel[]
    selected: number
    onFieldChange: (a:ChangeEvent<HTMLSelectElement>, b: string)=>void
}

const SelectCategory:FC<Props> = (props) => {
    return(
    <div className="mb-3">
        <label className="form-label">Select Parent Category</label>
        <select className="form-select" onChange={(e)=>props.onFieldChange(e, 'parent_category_id')} defaultValue={props.selected}>
            <option value="0">None</option>
            {props.parentCats.map(item =><option key={item.category_id} value={item.category_id} >{item.category_name}</option>)}
        </select>
    </div>
    )
}

export default SelectCategory