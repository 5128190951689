import {useState} from "react";

const useModal = () => {
    let [modal, setModal] = useState(false);
    let [modalContent, setModalContent] = useState("");
    let [modalTitle, setModalTitle] = useState("");
    let [wide, setWide] = useState(false)

    let handleModal = (content = false, title = false, wide = false) => {
        setModal(!modal);
        if (content) {
            setModalContent(content);
            setModalTitle(title)
            setWide(wide)
        }
    };

    return { modal, modalTitle, handleModal, modalContent, wide };
};

export default useModal