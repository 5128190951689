import React, {Component, useState, useRef, useEffect} from 'react';
import DataService from "../../services/data.service";
import LoadingSpinner from '../global/LoadingSpinner';
import Modal from "../Modal"
import ConsoleBookingCalendar from './ConsoleBookingCalendar';
import ResourcesList from "./ResourcesList";

const EventItem = (props) => {

    const findSpecialEventName = (idToFind) => {
        const clash = props.specialEvents.find(event => event.id == idToFind)
        if( clash === undefined && typeof clash == 'undefined' ) {
            return 'Schedule'
        } else {
            return clash.recurring_description
        }
    }

    const deleteEvent = () => {
        props.deleteEvent(props.data.id)
        props.setUpdate(props.update+1)
    }

    const editEvent = () => {
        props.editEvent({
            editId: props.data.id,
            product: props.data.product,
            customer: props.data.detail,
            cust_uuid: props.data.cust_uuid
        })
        props.setUpdate(props.update+1)
    }

    const getDate = () => {
        return new Date(props.data.date).toDateString()
    }

    let findResById = (id, resources) => {
        let matching = resources.find(resource=>resource.name==id)
        if( matching ) {
            return resources.find(resource=>resource.name==id)
        } else {
            return {res_name: ''}
        }
    }

    return (
        <div className="special-events-item">
            <div className="d-flex w-100 justify-content-between align-items-center">
                <div className="special-events-heading">
                    <h5 className="mb-0">{props.data.product}</h5>
                </div>
                <p className="mb-0"><small>Date &amp; Time:</small><br/> 
                    {getDate()} | {props.data.start}-{props.data.end}
                </p>
                <p className="mb-0"><small>Customer:</small><br/> 
                    {props.data.detail}
                </p>
                <p className="mb-0"><small>Resource:</small><br/>
                    {findResById(props.data.resource, props.allResources).res_name}
                </p>
                <p className="mb-0"><small>Clashes With:</small><br/>
                    {findSpecialEventName(props.data.special_event_clash)}
                </p>
                <p className="mb-0 text-end"><small>
                    <button className="btn btn-link p-0" onClick={(e) => editEvent()}>Edit</button><br/>
                    <button className="btn btn-link p-0" onClick={(e) => deleteEvent()}>Remove</button></small>
                </p>
            </div>
        </div>
    )
}

const ClashingEvents = (props) => {
    const isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [editEvent, setEditEvent] = useState({})
    const [selectedResource, setSelectedResource] = useState('')
    const [bookingData, setBookingData] = useState({})
    const [specialEvents, setSpecialEvents] = useState([])
   
    const deleteEvent = (id) => {
        setDeleteId(id)
    }

    const makeEditEvent = (object) => {
        setEditEvent(object)
    }

    const removeModal = () => {
        setEditEvent({})
    }

    const createEvent = (object) => {
        
        console.log('create',object)
        setBookingData({
            resource: selectedResource,
            customer: editEvent.customer,
            cust_uuid: editEvent.cust_uuid,
            org_id: props.orgId,
            product: editEvent.product,
            date: object.date,
            end_time: object.end_time,
            start_time: object.start_time
        })
    }

    useEffect(function effectFunction() {

        async function deleteClashingEvent() {
            setIsLoading(true)
            const response = DataService.deleteEvent(deleteId)
            try {
                const eventsResponse = await response
                console.log(eventsResponse)
                setIsLoading(false)
                
                setDeleteId('')
                removeModal()
                props.setUpdate(props.update+1)
            } catch(e) {
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            if( deleteId != '') {
                deleteClashingEvent()
            }
        } else {
            isMounted.current = true;
        }
        

    },[deleteId])

    useEffect(function effectFunction() {

        async function makeBooking() {
            setIsLoading(true)

            const response = DataService.createBooking(bookingData)
            try {
                const bookingResponse = await response
                setIsLoading(false)
                setDeleteId(editEvent.editId)
            } catch(e) {
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            makeBooking()
        } else {
            isMounted.current = true;
        }
        

    },[bookingData])

    useEffect(function effectFunction() {

        async function fetchSpecialEvents() {
            setIsLoading(true)
            const response = DataService.getSpecialEvents(props.orgId)
            try {
                const eventsResponse = await response
                setIsLoading(false)
                setSpecialEvents(eventsResponse.data)
                
            } catch(e) {
                props.showError('error')
                setIsLoading(false)
            }
        }
        fetchSpecialEvents();

    }, [])

    return (
        <div className="special-events">
            {isLoading && <LoadingSpinner/> }
            <div className="special-events-list">
                {props.clashingEvents.map((clashingEvent)=><EventItem update={props.update} setUpdate={props.setUpdate} specialEvents={specialEvents} allResources={props.resources} editEvent={makeEditEvent} deleteEvent={deleteEvent} key={clashingEvent.id} data={clashingEvent} />)}
            </div>
            
            {Object.entries(editEvent).length !== 0 &&
                <Modal title="Edit Event" modalAction={removeModal}>
                    <div className="row">
                        <div className="col-6">
                            <div className="list-group">
                                <ResourcesList selectedResource={selectedResource} setSelectedResource={setSelectedResource} resources={props.resources}/>
                            </div>
                        </div>
                        <div className="col-6">
                            {selectedResource != '' &&
                                <ConsoleBookingCalendar setBooking={createEvent} resourceId={selectedResource} orgId={props.orgId} duration={15} step={900} />
                            }
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}

export default ClashingEvents