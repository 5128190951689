import React, {FC, useState, ChangeEvent, useEffect} from 'react';
import { Link } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import DataServiceOrganisation from '../../services/DataServiceOrganisation';
import DataServiceBrand from '../../services/DataServiceBrand';
import LoadingSpinner from '../global/LoadingSpinner';
import Modal from '../Modal';
import AddOrganisation from '../organisationDetails/AddOrganisation';
import Pagination from '../Pagination';
import OrganisationModel from '../../interfaces/OrganisationModel';
import ButtonUi from '../elements/Button';
import { Button, List, ListItem, ListItemText, Box, Card } from '@mui/material';


interface OrganisationProps {
    data: OrganisationModel
}

const Organisation:FC<OrganisationProps> = (props) => {
    return (
        <ListItem sx={{ background: 'white', borderBottom: '1px solid #eee'}}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <ListItemText sx={{ fontFamily: 'Source Sans Pro' }} >
                    {props.data.name}
                </ListItemText>
                <div>
                    <Button size="small" href={'/organisation/details/'+props.data.org_id}>View Details</Button>
                    <Button size="small" href={'/organisation/'+props.data.org_id}>View Calendar</Button>
                </div>
            </Box>
        </ListItem>

    )
}


interface BrandOrgProps {
    brandId: string
}
const BrandOrganisations:FC<BrandOrgProps> = (props) => {
    const [organisations, setOrganisations] = useState<OrganisationModel[]>([])
    const [loadedOrgs, setLoadedOrgs] = useState<OrganisationModel[]>([])
    const [filteredOrgs, setFilteredOrgs] = useState<OrganisationModel[]>([])
    const [reloadOrgs, setReloadOrgs] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [addOrg, setAddOrg] = useState(false)
    const { user, getAccessTokenSilently } = useAuth0();

    const closeAddModal = () => setAddOrg(false)

    useEffect(function effectFunction() {
        let isMounted = true;  
        
        async function fetchOrgs() {
            if(isMounted) setIsLoading(true)
            const response = DataServiceOrganisation.getBrandOrgs(props.brandId)
            try {
                const orgsResponse = await response
                if(isMounted) setLoadedOrgs(orgsResponse.data)
                if(isMounted) setIsLoading(false)
                
            } catch(e) {
                if(isMounted) setIsLoading(false)
            }
        }
        fetchOrgs()
        return () => { isMounted = false }

    }, [reloadOrgs])

    const filterOrgs = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase()
        setFilteredOrgs(loadedOrgs.filter( (item: OrganisationModel) => item.name.toLowerCase().includes(value)))
    }

    return (
        <div className="brand-organisations">
            {isLoading && <LoadingSpinner/> }
            <div className="d-flex justify-content-between align-items-center mb-1">
                <h3 className="mt-4">Organisations</h3>
                <ButtonUi label={'Add Organisation'} type={'primary'} action={()=>setAddOrg(true)}  />
            </div>
            <div className="form-floating mb-3">
                <input type="text" className="form-control" id="filter" onChange={(e)=>filterOrgs(e)} />
                <label htmlFor="filter">Search for Organisation</label>
            </div>
            <Card variant="outlined">
                <List>
                    { organisations.map((organisation: OrganisationModel, index)=><Organisation data={organisation} key={organisation.org_id + index} />)}
                </List>
            </Card>
            <Pagination setItems={setOrganisations} originalItems={loadedOrgs} filteredItems={filteredOrgs} />
            {addOrg &&
                <Modal modalAction={closeAddModal} title="Add Organisation">
                    <AddOrganisation reloadOrgs={reloadOrgs} setReloadOrgs={setReloadOrgs} closeModal={closeAddModal} brandId={props.brandId} />
                </Modal>
            }
        </div>
    )

}

export default withAuthenticationRequired(BrandOrganisations, {
    onRedirecting: () => <LoadingSpinner />,
});