import React, { useState, FC, ReactNode, createContext } from 'react';
import { DDCFlowType } from '../interfaces/ModalContextType';

// let DDCFlowContext: React.Context<Partial<DDCFlowType>> | React.Context<unknown> | React.Context<{ update: number; setUpdate: (a: number)=>void}>
// let { Provider } = (DDCFlowContext = createContext<Partial<DDCFlowType>>({
//     update: Math.floor(Math.random() * 111),
//     setUpdate: ()=>Math.floor(Math.random() * 111),
// }));

const defaultValue = {
    refreshDDC: 0,
    incrementRefreshDDC: () => {}
};

export const DDCFlowContext = React.createContext(defaultValue);

interface Props {
    children: ReactNode;
}

const DDCFlowProvider:FC<Props> = ({ children }) => {

    const [refreshDDC, setRefreshDDC] = useState(0);

    return (
        <DDCFlowContext.Provider value={{refreshDDC, incrementRefreshDDC: () => {
            setRefreshDDC((ctr) => ctr + 1);
          }}}>
            {children}
        </DDCFlowContext.Provider>
    );
};
export { DDCFlowProvider };