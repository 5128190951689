import React, {FC, useState, useContext, ChangeEvent, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import UploadOrgIcon from '../organisation/UploadOrgIcon';
import DataServiceOrganisation from '../../services/DataServiceOrganisation';
import TextInputRow from '../formfields/TextInputRow';
import Button from '../elements/Button';
import { ModalContext } from "../../contexts/modalContext";

interface OrganisationModel {
    icon_url: string
    name: string
    description: string
    street_address: string
    postcode: string
    website: string
}

interface EditOrgProps {
    data: OrganisationModel
    setReloadOrgs: (a: number)=>void
}

const EditOrganisation:FC<EditOrgProps> = (props) => {
    let { handleModal } = useContext<any>(ModalContext)
    const { getAccessTokenSilently } = useAuth0();

    const [inputs, setInputs] = useState(props.data)
    const [iconUrl, setIconUrl] = useState(inputs.icon_url)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const handleChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [field]: value}))
    }

    useEffect(() => {
        let isMounted = true;     
        if(isMounted) setInputs(values => ({...values, ['icon_url']: iconUrl}))
        return () => { isMounted = false }
    }, [iconUrl])

    const submitForm = async (e: any) => {
        const token = await getAccessTokenSilently()
        const response = DataServiceOrganisation.updateOrg(inputs, token)
        console.log(inputs)
        try {
            const brandResponse = await response
            props.setReloadOrgs(Math.random())
            handleModal()
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    }

    return (
        <div>
            <TextInputRow label={'Name'} defaultValue={inputs.name} field={'name'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=> null } />
            <TextInputRow label={'Description'} defaultValue={inputs.description} field={'description'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=> null } />
            <UploadOrgIcon iconUrl={iconUrl} setIconUrl={setIconUrl} />
            <TextInputRow label={'Street Address'} defaultValue={inputs.street_address} field={'street_address'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=> null } />
            <TextInputRow label={'Postcode'} defaultValue={inputs.postcode} field={'postcode'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=> null } />
            <TextInputRow label={'Website'} defaultValue={inputs.website} field={'website'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=> null } />
            <Button label={'Save Changes'} type={'primary'} action={submitForm} />
        </div>
    )
}

export default EditOrganisation