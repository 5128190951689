import React, {FC, ChangeEvent} from 'react';
import Button from '../elements/Button'

interface TextInputProps{
    defaultValue: string | number
    field: string
    onFieldChange: (arg1: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, arg2: string) => void
    inputGroup: boolean
    buttonAction: ()=> void
    type?: string,
    buttonText?: string
}

const InputElement: FC<TextInputProps> = (props) => {

    return <input type={props.type} className="form-control" defaultValue={props.defaultValue} onChange={(e) => props.onFieldChange(e, props.field)} />
}

const TextInput: FC<TextInputProps> = (props) => {

    const type = (props.type === undefined)? 'text' : props.type
    
    if(props.inputGroup) {
        return (
            
            <div className="input-group">
                <InputElement type={type} inputGroup={props.inputGroup} defaultValue={props.defaultValue} onFieldChange={props.onFieldChange} field={props.field} buttonAction={props.buttonAction} />
                <Button label={props.buttonText? props.buttonText : 'Add New'} type={'primary'} action={props.buttonAction} />
            </div>
        )
    } else {
        return <InputElement type={type} inputGroup={props.inputGroup} defaultValue={props.defaultValue} onFieldChange={props.onFieldChange} field={props.field} buttonAction={props.buttonAction} />
    }

}

export default TextInput