import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../services/data.service";
import LoadingSpinner from './global/LoadingSpinner';

function Organisation(props){
    return (
        <div href="#" className="list-group-item list-group-item-action" aria-current="true">
            <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{props.data.organisation_name}</h5>
                <p><a href={'/organisation/'+props.data.organisation_id}>View</a> {/*| <a href={'/organisation/'+props.data.organisation_id+'/edit'}>Edit</a>*/}</p>
            </div>
        </div>
    )
}

function OrganisationsList(props){
    if( props.orgs.length > 0) {
        return props.orgs.map((organisation)=><Organisation data={organisation} key={organisation.organisation_id} />)
    } else {
        return <p>Nothing found</p>
    }
}

class Organisations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organisations: []
        };
    }

    componentDidMount() {
        DataService.getAllOrgs().then(
            response => {
                this.setState({
                    organisations: response.data
                });
                // console.log(response.data)
            },
            error => {
            }
        );
    }

    render() {
        return (
            <div className="container">
                <div className="d-flex justify-content-between align-items-center mb-5">
                    <h1>Organisations</h1>
                    <button className="btn">Add Organisation</button>
                </div>
                <div className="list-group">
                    <OrganisationsList orgs={this.state.organisations}/>
                </div>
            </div>
        );
    }
}

export default withAuthenticationRequired(Organisations, {
    onRedirecting: () => <LoadingSpinner />,
});