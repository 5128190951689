import React, {FC, MouseEvent, useContext, ChangeEvent, useState, useRef, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import ResourceModel from '../../interfaces/ResourceModel';
import DataServiceResource from '../../services/DataServiceResource';
import Button from '../elements/Button';
import TextInputRow from '../formfields/TextInputRow';

interface ResourceProps {
    removeResource: (a: string)=>void
    data: ResourceModel,
    orgId: string
}

const Resource:FC<ResourceProps> = (props) => {
    const {getAccessTokenSilently} = useAuth0();

    const [editing, setEditing] = useState(false)
    const [defaults, setDefaults] = useState<ResourceModel>({
        name: props.data.name,
        res_name: props.data.res_name,
        ID: props.data.ID,
        res_id: props.data.res_id
    })

    const onChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        setDefaults(values => ({...values, [field]: event.target.value}))
    }

    const saveChanges = async () => {
        console.log(defaults)
        setEditing(false)

        if(props.orgId !== '') {
            const token = await getAccessTokenSilently()
            const response = DataServiceResource.updateResource( props.orgId, defaults, token)
            try {
                const dataResponse = await response
                
            } catch(e) {
            }
        }
    }

    return (
        <div className="list-group-item list-group-item-action align-items-center">
            <div className="row align-items-center">
                <div className="col-5">
                    {!editing &&
                        <span>Name: {defaults.name}{defaults.res_name}</span>
                    }
                    {editing &&
                        <TextInputRow label={'Name'} defaultValue={(defaults.name!==undefined)?defaults.name:defaults.res_name} field={'res_name'} onFieldChange={onChange} inputGroup={false} buttonAction={()=>null} />
                    }
                </div>
                <div className="col-5">
                    <span>ID: {props.data.ID}{props.data.res_id}</span>
                </div>
                <div className="col-2">
                    {!editing &&
                        <span>
                            <Button label={'Remove'} type={'link btn-sm'} action={()=>props.removeResource(props.data.res_id)} />
                            <Button label={'Edit'} type={'link btn-sm'} action={()=>setEditing(true)} />
                        </span>
                    }
                    {editing &&
                        <Button label={'Save'} type={'link btn-sm'} action={()=>saveChanges()} />
                    }
                </div>
            </div>
        </div>
    )
}

export default Resource