import React, {FC, ReactNode} from 'react';

interface FormRowProps{
    children: ReactNode;
}

const FormRow: FC<FormRowProps> = (props) => {
    return (
        <div className="mb-3">
            {props.children}
        </div>
    )
}

export default FormRow