import { Box, Button, Chip, Drawer, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Toolbar, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { memo, useContext, useState } from 'react';
import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow';
import AddQuestion from './AddQuestion';
import { IQuestionOption } from './DdcFlow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DataServiceDdc from '../../services/DataServiceDdc';
import { DDCFlowContext } from '../../contexts/DdcFlowContext';
import MoveOption from './MoveOption';
import InsertBefore from './InsertBefore';


function DdcNode(data: any) {

    type Anchor = 'top' | 'left' | 'bottom' | 'right';

    const [addOpen, setAddOpen] = useState(false)

    type DrawerType = 'ADDOPTION' | 'EDITOPTION' | 'EDITQUESTION' | 'MOVEOPTION' | 'INSERT' | null;

    const [typeOfDrawer, setTypeOfDrawer] = useState<DrawerType>(null)

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setAddOpen(false);
            };


    const [optionAnchorEl, setOptionAnchorEl] = React.useState<null | HTMLElement>(null);
    const optionOpen = Boolean(optionAnchorEl);
    const openOptionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOptionAnchorEl(event.currentTarget);
    };
    const handleOptionMenuClose = () => {
        setOptionAnchorEl(null);
    };

    const options = data.data.responses?.map((item: IQuestionOption, i: number) =>
        <Box key={i} sx={{
            position: 'relative',
            marginRight: '2px',
            '& .react-flow__handle-bottom': {
                bottom: '-4px'
            }
        }}>
            {/* <Chip
                label={item.response}
                size="small"
                variant="outlined"
                onClick={() => null}
                onDelete={openOptionMenu}
                deleteIcon={<EditIcon />} /> */}
            {/* <Handle type="source" position={Position.Bottom} id={'handle_'+data.data.questionData.question.question_id.toString() + '-' + data.data.questionData.next.question_id.toString()} /> */}

            {/* <Menu
                id="edit-option-menu"
                anchorEl={optionAnchorEl}
                open={optionOpen}
                onClose={handleOptionMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'option-menu-button',
                }}
            >
                <MenuList>
                    <MenuItem>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText onClick={() => openAddDrawer()}>Edit Option</ListItemText>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText onClick={() => deleteOption(item.next)}>Delete Option</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu> */}
            <Handle type="source" position={Position.Bottom} id={'handle_' + item.response + data.data.questionData.question.question_id} />
        </Box>

    )

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openAddDrawer = () => {
        setTypeOfDrawer('ADDOPTION')
        setAddOpen(true)
        handleClose()
    }

    const openMoveDrawer = () => {
        setTypeOfDrawer('MOVEOPTION')
        setAddOpen(true)
        handleClose()
    }

    const openInsertDrawer = () => {
        setTypeOfDrawer('INSERT')
        setAddOpen(true)
        handleClose()
    }

    const openEditDrawer = () => {
        setTypeOfDrawer('EDITQUESTION')
        // setAddOpen(true)
        handleClose()
    }

    const openEditOption = () => {
        setTypeOfDrawer('EDITOPTION')
        // setAddOpen(true)
        handleClose()
    }

    let { incrementRefreshDDC } = useContext<any>(DDCFlowContext)

    const deleteOption = async (questionid: string | undefined) => {
        // setIsLoading(true)
        // const token = await getAccessTokenSilently()
        if (typeof (questionid) !== 'undefined') {
            const response = DataServiceDdc.removeOptionFlow(data.data.questionData.question.brand_id, questionid);
            try {
                const ddcResponse = await response
                //refresh
                incrementRefreshDDC()


            } catch (e: any) {
                // setIsLoading(false)
                if (e.response) {
                    // setErrorMessage(e.response.errors)
                } else {
                    // setErrorMessage(e.message)
                }
            }
        }
    }

    return (
        <div>
        <Box sx={{
            maxWidth: '290px', background: '#ffffff', borderRadius: '10px', border: '1px solid #c0c0c0', display: 'flex',
            flexDirection: 'column', overflow: 'hidden'
        }}>
            <Handle type="target" position={Position.Top} />

            <Box sx={{ display: 'flex', alignItems: 'flex-start', padding: '30px 30px 10px 30px' }}>
                <Typography variant="h4" sx={{ fontSize: '1.4rem', fontWeight: '800', borderColor: 'white' }}>{data.data.label}</Typography>
                <Button
                    aria-label="edit"
                    id="menu-button"
                    aria-controls={open ? 'edit-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={openMenu}
                    variant="outlined"
                    sx={{ minWidth: '30px', height: '30px', padding: '5px', marginLeft: '10px' }}
                >
                    <EditIcon sx={{}} />
                </Button>
            </Box>
            <Menu
                id="edit-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu-button',
                }}
            >
                <MenuList>
                    <MenuItem>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText onClick={() => openMoveDrawer()}>Move Node</ListItemText>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText onClick={() => openInsertDrawer()}>Insert Before</ListItemText>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <AddIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText onClick={() => openAddDrawer()}>Add Option</ListItemText>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText onClick={() => openEditDrawer()}>Edit Question</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>


            <Drawer
                anchor={'right'}
                open={addOpen}
                onClose={toggleDrawer('right', addOpen)}
                sx={{ padding: '20px' }}
            >
                <Toolbar />
                {typeOfDrawer === 'ADDOPTION' &&
                    <AddQuestion data={data.data} closeDrawer={setAddOpen} />
                }
                {typeOfDrawer === 'MOVEOPTION' &&
                    <MoveOption data={data.data} closeDrawer={setAddOpen} />
                }
                {typeOfDrawer === 'INSERT' &&
                    <InsertBefore data={data.data} closeDrawer={setAddOpen} />
                }
            </Drawer>

            <Box sx={{ marginTop: '10px', padding: '0 30px 30px' }}>
                <Typography sx={{ fontWeight: '200', fontSize: '0.8rem' }}>Dialogue Type: {data.data.questionData.question.dialogue_type}</Typography>
                <Typography sx={{ fontWeight: '200', fontSize: '0.8rem' }}>Question Type: {data.data.questionData.question.question_type}</Typography>
                <Typography sx={{ fontWeight: '200', fontSize: '0.8rem' }}>Submit Text: {data.data.questionData.question.submit_text}</Typography>
            </Box>
            {data.data.responses.length > 0 &&
                <Box className="handles" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    {options}
                </Box>
            }
        </Box>
        </div>
    );
}

export default memo(DdcNode);