import React, {FC, useState, useRef, useEffect, useContext, ChangeEvent} from 'react';
// import {DayPilot, DayPilotNavigator} from "@daypilot/daypilot-lite-react";
import "../../css/CalendarStyles.css";

import DataService from "../../services/data.service";
import LoadingSpinner from "../global/LoadingSpinner";
import { OrganisationContext } from '../../contexts/OrganisationContext';
import ResourceModel from '../../interfaces/ResourceModel';
import EventModel from '../../interfaces/EventModel';
import Calendar, { OnChangeDateCallback, OnChangeDateRangeCallback } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Alert, AlertTitle, Box, Button, Drawer, Grid, Toolbar, Typography } from '@mui/material';
import { color } from '@mui/system';
import { useDimensions } from '../../hooks/useDimensions';
import AddEvent from './AddEvent';
import { ProblemEventsContext } from '../../contexts/ProblemEventsContext';
import checkEventClash from '../../helpers/checkEventClash';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers';
import DataServiceClient from '../../services/DataServiceClient';

let hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
let selectedDate = new Date()
// const [hourWidth, setHourWidth] = useState(0)

let hourWidth = 0

function pad(num: number, prefix: boolean) {
    if(prefix) {
        if(num < 10) {
            return '0' + num
        } else {
            return num
        }
    } else {
        if(num == 0) {
            return num + '0'
        } else if(num < 10){
            return '0' + num
        } else {
            return num
        }
    }
}

const OrgCalendar:FC = () => {

    const [orgData, setOrgData] = useContext(OrganisationContext);
    const [resources, setResources] = useState<ResourceModel[]>([])
    const [resource, setResource] = useState<string>('')
    const [events, setEvents] = useState<EventModel[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [cancelId, setCancelId] = useState('')
    const [update, setUpdate] = useState(0)
    const [selectedCalDay, setSelectedCalDay] = useState<Date>(new Date())
    const [showError, setShowError] = useState('')
    const [selectedEvent, setSelectedEvent] = useState<string>('')
    const [updated, setUpdated] = useState(0)
    const [selectedTime, setSelectedTime] = useState('')
    const [selectedResource, setSelectedResource] = useState('')
    const [reloadProblemEvents, setReloadProblemEvents] = useContext(ProblemEventsContext)
    const [value, setValue] = React.useState<Dayjs | null>(null);
    const [ errorMessage, setErrorMessage] = useState('')

    useEffect(function effectFunction() {
        
        // setSelectedCalDay(startDate)

        async function fetchResources() {
            setIsLoading(true)
            const response = DataService.getCalendarResource(orgData.org_id)

            try {
                const resourcesResponse = await response;

                if( resourcesResponse.data.length == 0) {
                    setShowError('error loading resources')
                    setIsLoading(false)
                } else {
                    setResources(resourcesResponse.data)
                    setIsLoading(false)
                }
            } catch (e) {
                setShowError('error')
            }
        }
        fetchResources();

        async function fetchEvents() {
            setIsLoading(true)
            const dateOb = selectedCalDay
            const date = dateOb.getFullYear() + '-' + pad(dateOb.getMonth()+1, true) + '-' + pad(dateOb.getDate(), true)
            const response = DataService.getAllEvents(orgData.org_id, date)
            try {
                const eventsResponse = await response;
                setEvents([]);
    
                // filter to today
                
                const selectedDay = dateOb.getDate()
                
                // TODO - check month
                const selectedMonth = dateOb.getMonth()

                const daysEvents = eventsResponse.data.filter((event: { start: string | number | Date; end: string | number | Date; })=>selectedDay==new Date(event.start).getDate()&&selectedMonth==new Date(event.start).getMonth()||selectedDay==new Date(event.end).getDate()&&selectedMonth==new Date(event.end).getMonth())
    
                setEvents(daysEvents);
                setIsLoading(false)
                setAddOpen(false)
                setReloadProblemEvents(Math.random())
                
                // set problem events

            } catch (e) {
                setShowError('error')
                setIsLoading(false)
            }
        }
        fetchEvents();
 
    }, [selectedCalDay, update])

    const isMounted = useRef(false)

    useEffect(function effectFunction() {
        async function cancelEvent() {
            setIsLoading(true)
            const response = DataService.deleteEvent(cancelId)
            try {
                const cancelResponse = await response;
                setUpdate(update+1)
                setIsLoading(false)
            } catch (e) {
                setShowError('error')
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            cancelEvent()
        } else {
            isMounted.current = true;
        }
    }, [cancelId])

    const handleChange = (value: Date) => {
        setSelectedCalDay(new Date(value))
    }

    type Anchor = 'top' | 'left' | 'bottom' | 'right';

    const [addOpen, setAddOpen] = useState(false)

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setAddOpen(false);
            };

    const makeBooking = (time: string, res: ResourceModel) => {
        setSelectedTime(time)
        setSelectedResource(res.name)
        setAddOpen(true)
    }

    const cellRef = useRef<HTMLDivElement>(null);
    const timeCellRef = useRef<HTMLDivElement>(null);
    const [colWidth, setColWidth] = useState(0)
    const [colHeight, setColHeight] = useState(0)

    const { width, height } = useDimensions(cellRef, isLoading)
    useEffect(() => {
        setColWidth( width ? width : 0 )
        setColHeight( height ? height : 0)
    }, [width]);

    const styles = {
        '.react-calendar': {
            width: '100%',
            borderRadius: '5px',
            borderColor: 'rgba(0,0,0,0.1)',
            marginBottom: '20px',

            '.react-calendar__tile': {
                borderRadius: '36px',
                '&--now': {
                    background: 'rgba(0,0,0,0.1)'
                },
                '&--active': {
                    background: '#1087ff;'
                }
            }
        },
        '.datepicker': {
            '& .MuiDialogActions-root': {
                display: 'none'
            }
        },
        '.calendar-rows': {
            position: 'relative',

            '.calendar-row': {
                display: 'flex',
                alignContent: 'stretch',
                width: '100%',
                '& .time-cell': {
                    width: '100%',
                    background: '#eee',
                    minHeight: '30px',
                    boxShadow: '-3px -3px 0px 0px #f9f9f9 inset',
                    display: 'flex',
                    alignItems: 'stretch',
                    flexDirection: 'column',

                    '& .pad': {
                        padding: '10px'
                    },

                    '& button': {
                        background: 'none',
                        position: 'relative',
                        minHeight: '12px',
                        border: 'none',
                        boxShadow: '0px -1px 0px 0px rgba(255, 255, 255, 0.5) inset',

                        '& span': {
                            position: 'absolute',
                            bottom: '100%',
                            left: '50%',
                            color: '#0a58ca',
                            background: '#ddd',
                            transform: 'translateX(-50%)',
                            display: 'none',
                            padding: '5px 10px',
                            borderRadius: '5px 5px 0 0'
                        },

                        '&:hover': {
                            background: '#ddd',
                            boxShadow: '-3px -1px 0px 0px #f9f9f9 inset',

                            '& span': {
                                display: 'block'
                            }
                        }
                    }
                }
            },
            '.calendar-event': {
                position: 'absolute',
                display: 'table-cell',
                padding: '0 0 0 0.5vw',
                background: '#ccc',
                borderRadius: '3px',
                border: '1px solid rgba(0, 0, 0, 0.115)',
                backgroundColor: '#e0e0e0',
                boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.1)',
                backgroundSize: '10px 10px',
                overflow: 'hidden',
                backgroundImage: 'repeating-linear-gradient(45deg, #00000007 0, #00000007 4px, #f3f3f3 0, #f3f3f3 50%)',

                '&.booking': {
                    backgroundColor: '#0060df',
                    border: '0px solid rgba(#0060df, 0.5)',
                    background: '#0d6efd',
                    backgroundImage: 'repeating-linear-gradient(45deg, #0d6efd 0, #0d6efd 4px, #3e86f0 0, #3e86f0 50%)',
                    backgroundSize: '10px 10px',
                    boxShadow: '1px 1px 1px rgba(121, 121, 121, 0.5)',
                    zIndex: '888',

                    '& > div': {
                        background: '#fff',
                    },
                },

                '& > div': {
                    padding: '0.5vw',
                    borderRadius: '0 3px 0 0',
                    display: 'none',
                    background: '#fff',
                },

                '&:hover': {
                    zIndex: '999',
                    overflow: 'visible',

                    '& > div': {
                        minHeight: '100px',
                        boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.1)',
                        borderRadius: '3px',
                        display: 'block',
                        position: 'absolute',
                        top: '50%',
                        left: '100%',
                        transform: 'translateY(-50%)',

                        '& > .arrow': {
                            width: '10px',
                            height: '10px',
                            position: 'absolute',
                            top: '50%',
                            right: '100%',
                            borderTop: '10px solid transparent',
                            borderRight: '10px solid #fff',
                            borderBottom: '10px solid transparent'
                        }

                        
                    }
                },

                '&.end': {
                    '&:hover': {
                        '& > div': {
                            left: 'auto',
                            right: '100%',
                            '& > .arrow': {
                                right: 'auto',
                                left: '100%',
                                borderRight: '0',
                                borderLeft: '10px solid #fff',
                            }
                        }
                    },
                },

                '& p': {
                    lineHeight: '1',
                    fontSize: '0.9rem',

                    '& span': {
                        display: 'block',
                        textTransform: 'uppercase',
                        fontSize: '0.6rem'
                    },

                },

                
            }
        }
    }

    const getEventLeft = (resId: string) => {
        let pos = resources.map(function (e) {
            return e.name;
        }).indexOf(resId);
        return (pos+1) * colWidth
    }

    const resIndex = (resId: string) => {
        let pos = resources.map(function (e) {
            return e.name;
        }).indexOf(resId);
        return (pos +1)
    }

    const getEventTop = (start: string, end: string) => {
        const startDate = new Date(start)
        const endDate = new Date(end)
        const startHour = (startDate.getDate() < selectedCalDay.getDate())? 0 : startDate.getHours()
        let startMinute = (startDate.getDate() < selectedCalDay.getDate())? 0 : startDate.getMinutes()

        const minuteHeight = colHeight / 60

        return (colHeight * (startHour+1)) + (minuteHeight*startMinute)
    }

    const getEventHeight = (start: string, end: string) => {
        
        const startDate = new Date(start)
        const endDate = new Date(end)
        let startHour = (startDate.getDate() < selectedCalDay.getDate())? 0 : startDate.getHours()
        let endHour = (endDate.getDate() > selectedCalDay.getDate())? 24 : endDate.getHours()

        let startMinute = (endDate.getDate() > selectedCalDay.getDate())? 0 : startDate.getMinutes()
        let endMinute = (endDate.getDate() > selectedCalDay.getDate())? 0 : endDate.getMinutes()

        if( selectedCalDay.getMonth() > startDate.getMonth() ) {
            startHour = 0
        }
        if( selectedCalDay.getMonth() < endDate.getMonth() ) {
            endHour = 24
            endMinute = 0
        }

        const hourHeight = colHeight * (endHour - startHour)
        const minuteHeight = (colHeight / 60) * (endMinute - startMinute)

        return hourHeight + minuteHeight
    }

    // async function onDelete(e: any, eventId: string, refreshCalendar: (a: number)=>void, setErrorMessage: (a: string)=>void ) {
    //     setIsLoading(true)
    //     const token = await getAccessTokenSilently()
    //     const response = DataServiceClient.deleteEvent(eventId);
    //     try {
    //         const eventsResponse = await response
    //         refreshCalendar(Math.random())
    //         // set probelm events number
    //         setIsLoading(false)
    //     } catch (e: any) {
    //         setIsLoading(false)
    //         if( e.response ) {
    //             setErrorMessage(e.response.data.detail[0])
    //         } else {
    //             setErrorMessage(e.message)
    //         }
    //     }
    // }

    const eventItem = (item: EventModel, i: number, refreshCalendar: (a: number)=>void, setErrorMessage: (a: string)=>void) => {

        const clashingItems = checkEventClash(events, item)
        const clashingIndex = clashingItems.map(function(event) { return event.id; }).indexOf(item.id);

        let classList = item.type === 'booking'? 'calendar-event booking' : 'calendar-event'
        if( resIndex(item.resource) === resources.length ) classList = classList + ' end'

        return <Box className={classList} key={i} sx={{
            left: getEventLeft(item.resource) + ((colWidth-10)/clashingItems.length)*clashingIndex+1,
            top: getEventTop(item.start, item.end),
            height: getEventHeight(item.start, item.end),
            width: (colWidth-10)/(clashingItems.length)
        }}>
            <div>
                <div className="arrow"></div>
                <h3>{item.text}</h3>
                <p><span>Start</span>
                    <strong>{new Date(item.start).toLocaleDateString('en-GB')}</strong><br /> 
                    {new Date(item.start).getHours()}:{pad(new Date(item.start).getMinutes(), true)}</p>
                
                <p><span>End</span>
                    <strong>{new Date(item.end).toLocaleDateString('en-GB')}</strong><br />
                    {new Date(item.end).getHours()}:{pad(new Date(item.end).getMinutes(), true)}</p>
                
                {item.type === 'booking' && <Button onClick={(e)=>setCancelId(item.id)}>Delete Event</Button>}

                {/* onDelete(e, item.id, refreshCalendar, setErrorMessage) */}
            </div>
        </Box>
    }

    return ( 
        <Grid className="orgcal" sx={styles}>
            {isLoading && <LoadingSpinner/>}

            <h3 className="card-title">Organisation Calendar</h3>

            <div>
                <Grid direction="row" sx={{background: 'white', display: 'flex', padding: '10px 20px', alignItems: 'center', justifyContent: 'space-evenly'}}>
                    <Typography sx={{fontWeight: '600', fontSize: '2rem', padding: '65px 0 65px 82px', width: '270px', border: '5px solid #1565c0', boxSizing: 'border-box', height: '270px', borderRadius: '50%'}}>
                        {selectedCalDay.toUTCString().split(' ')[0]}<br/>
                        {selectedCalDay.toUTCString().split(' ')[1] + ' ' +
                        selectedCalDay.toUTCString().split(' ')[2]}<br/>
                        {selectedCalDay.toUTCString().split(' ')[3]}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDatePicker
                            className="datepicker"
                            value={selectedCalDay}
                            displayStaticWrapperAs="mobile"
                            orientation="landscape"
                            openTo="day"
                            showToolbar={false}
                            onChange={(newValue) => {
                                if( newValue) {
                                    handleChange(new Date(newValue.toString()));
                                }
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <div className="calendar-container">
                    <div className="calendar-rows">
                        <div className="calendar-row">
                            <Box className="time-cell" ref={cellRef} sx={{height: '48px'}}></Box>
                            {resources.map(
                                (item, i)=> (
                                    <div key={i} className="time-cell"><span className="pad">{item.res_name}</span></div>
                                ))
                            }
                        </div>
                        {hours.map(
                            (hour)=> (
                                <div key={hour} className="calendar-row">
                                    <div className="time-cell"><span className="pad">{pad(hour, true)}</span></div>
                                    {resources.map(
                                    (item, i)=> (
                                        <div key={i} className="time-cell"  ref={timeCellRef}>
                                            <button onClick={()=>makeBooking(`${pad(hour, true)}:00`, item)}><span>{pad(hour, true)}:00</span></button>
                                            <button onClick={()=>makeBooking(`${pad(hour, true)}:15`, item)}><span>{pad(hour, true)}:15</span></button>
                                            <button onClick={()=>makeBooking(`${pad(hour, true)}:30`, item)}><span>{pad(hour, true)}:30</span></button>
                                            <button onClick={()=>makeBooking(`${pad(hour, true)}:45`, item)}><span>{pad(hour, true)}:45</span></button>
                                        </div>))}
                                </div>
                            )
                        )}
                        <div className="calendar-events">
                            {events.map(
                                (item, i)=> eventItem(item, i, setUpdate, setErrorMessage)
                            )}
                        </div>
                    </div>
                </div>
                <Drawer
                    anchor={'right'}
                    open={addOpen}
                    onClose={toggleDrawer('right', addOpen)}
                    sx={{padding: '20px'}}
                    >
                        <Toolbar />
                        <AddEvent refreshCalendar={setUpdate} brandId={orgData.brand_name} resources={resources} resource={selectedResource} time={selectedTime} date={`${selectedCalDay.getFullYear()}-${pad(selectedCalDay.getMonth()+1, true)}-${pad(selectedCalDay.getDate(), true)}`} />
                </Drawer>
            </div>

        </Grid>
    );

}

export default OrgCalendar
function getAccessTokenSilently() {
    throw new Error('Function not implemented.');
}

