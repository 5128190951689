import { Divider, Drawer, Grid, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import React, {useState, ChangeEvent, useCallback, useEffect, FC, ReactNode} from 'react';

import 'reactflow/dist/style.css';
import { DDCFlowContext} from '../../contexts/DdcFlowContext';
import DataServiceDdc from '../../services/DataServiceDdc';
import DdcFlow from './DdcFlow';

interface QuestionFlow {
    flow_id: string,
    title: string,
    brand_id: string
}

interface QuestionItem {
    question_text: string,
    question_text_2: string,
    question_type: string,
    submit_text: string,
    flow_id: string,
    dialogue_type: string,
    question_id: string,
    brand_id: string
}

interface Response {
    response: string,
    display_text: string,
    next?: string
}
interface FlowHeader {
    first_question: QuestionItem,
    detail: QuestionFlow
}
export interface Question {
    question?: QuestionItem,
    response?: Response
    next?: QuestionItem | null
    flowheader?: FlowHeader
    previous: string
}

const ManageDdc:FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [flows, setFlows] = useState<QuestionFlow[]>([])
    const [questions, setQuestions] = useState<Question[]>([])
    const [selectedFlow, setSelectedFlow] = useState('')

    let { refreshDDC } = React.useContext<any>(DDCFlowContext)

    const sidebarStyles = {
        width: 260,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { 
            width: 260, 
            boxSizing: 'border-box' 
        },
        [`& .MuiButtonBase-root`]: {
            width: '100%'
        }
    }

    useEffect(() =>{
        let isMounted = true;  
        if(isMounted) {
            async function fetchQuestions() {
                setIsLoading(true)
                const response = DataServiceDdc.getFlows('org_KwblXw8S7v7xfbWm')
                try {
                    const ddcResponse = await response
                    setIsLoading(false)
                    setError('')
                    setFlows(ddcResponse.data)
                } catch(e) {
                    setIsLoading(false)
                }
            }
            fetchQuestions()
        }
        return () => { isMounted = false }
    },[])

    useEffect(() =>{
        let isMounted = true;  
        if(isMounted) {
            async function fetchFlow() {
                setIsLoading(true)
                const response = DataServiceDdc.getQuestions('org_KwblXw8S7v7xfbWm', selectedFlow)
                try {
                    const ddcResponse = await response
                    setIsLoading(false)
                    setError('')
                    setQuestions(ddcResponse.data.filter((item: Question) => typeof(item.flowheader) === "undefined" ))
                } catch(e) {
                    setIsLoading(false)
                }
            }
            fetchFlow()
        }
        return () => { isMounted = false }
    },[selectedFlow, refreshDDC])


    
  

    return (
        <Grid>
                <Toolbar/>
                    <Drawer variant="permanent"
                    sx={sidebarStyles}>
                        <Toolbar/>
                        <Typography sx={{fontWeight: 'bold', fontSize: '1.6rem', padding: '15px'}}>Manage DDC</Typography>
                        <Divider sx={{ my: 1 }} />

                        <List component="nav">
                            {flows.map((flow, i)=><ListItemButton key={i} component="button" onClick={ ()=>setSelectedFlow(flow.flow_id)}>
                                <ListItemText primary={flow.title} />
                            </ListItemButton>)}
                            
                        </List>
                    </Drawer>
                    
                <Grid sx={{padding: '20px', marginLeft: '260px', height: 'calc(100% - 90px)', width: 'calc(100% - 260px)', position: 'absolute'}}>
                    <DdcFlow questions={questions} />
                </Grid>
        </Grid>
    )
}

export default ManageDdc