import React, {Component, useState, useRef, useEffect} from 'react';
import {DayPilot, DayPilotCalendar, DayPilotNavigator} from "@daypilot/daypilot-lite-react";
// import {DayPilot, DayPilotScheduler, DayPilotCalendar, DayPilotNavigator} from "daypilot-pro-react";
import "../../css/CalendarStyles.css";

import DataService from "../../services/data.service";
import LoadingSpinner from "../global/LoadingSpinner";


let hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
let selectedDate = new Date()
// const [hourWidth, setHourWidth] = useState(0)

let hourWidth = 0

function pad(num, prefix) {
    if(prefix) {
        if(num < 10) {
            return '0' + num
        } else {
            return num
        }
    } else {
        if(num == 0) {
            return num + '0'
        } else if(num < 10){
            return '0' + num
        } else {
            return num
        }
    }
}

function EventRow(props) {
    const startTimeDate = new Date(props.data.start.split('.')[0])
    const isYesterday = (selectedDate.getDate() > startTimeDate.getDate())

    const startHour = (isYesterday)? 0 : startTimeDate.getHours()
    const startMinute = (isYesterday)? 0 : startTimeDate.getMinutes()

    const endTimeDate = new Date(props.data.end.split('.')[0])
    
    const isTomorrow = (selectedDate.getDate() < endTimeDate.getDate())
    const isNextMonth = (selectedDate.getMonth() < endTimeDate.getMonth())

    const endHour = ( isTomorrow || isNextMonth )? 23 : endTimeDate.getHours()
    const endMinute = ( isTomorrow || isNextMonth )? 59 : endTimeDate.getMinutes()

    // const hourWidth = 30
    const minuteWidth = hourWidth/60
    

    const startDistance = ((startHour) * hourWidth)+(startMinute * minuteWidth)
    const length = ((endHour - startHour) * hourWidth)+(endMinute * minuteWidth)-(startMinute/1.6)

    const viewDetails = (e) => {
        props.setSelectedEvent(props.data.id)
    }

    const cancelEvent = (e) => {
        props.setCancelId(props.data.id)
    }

    return (
        <div className={"event-item " + (props.data.type == "booking" ? 'event-item-booking' : '')  + (props.data.type == undefined ? ' event-item-special-event' : '')} style={{left:startDistance, width: length + 'px'}}>
            <div className="event-tooltip">
                <h5>{props.data.text}</h5> 
                <p className="mb-0">{pad(startHour, true)}:{pad(startMinute, false)} - {pad(endHour, true)}:{pad(endMinute, false)}</p>
                {props.data.type == "booking" && 
                    <p className="mb-0"><small><a href="#" onClick={(e)=>viewDetails(e)}>View Details</a></small> | <small><a href="#" onClick={(e)=>cancelEvent(e)}>Cancel</a></small></p>
                }
            </div>
        </div>
    )
}


function ResourceRow(props){
    
    const setResource = () => {
        props.setResource(props.data.name)
    }
    
    return (
        <div className="resource-row">
            <div className="resource-column resource-name">{props.data.res_name}<br/><small><button className="btn btn-link p-0" onClick={(e) => setResource()}>Edit</button></small></div>
            <div className="resource-column resource-time resource-grid">
                {hours.map((hour)=><Hours int={hour} key={hour} />)}
                {props.events.length > 0 && 
                    props.events.map((event)=><EventRow setCancelId={props.setCancelId} setSelectedEvent={props.setSelectedEvent} data={event} key={event.id + event.start} />)
                }
            </div>
        </div>
    )
}

function ResourceRows(props){
    if( props.resources.length > 0) {
        return props.resources.map((resource)=><ResourceRow setCancelId={props.setCancelId} setSelectedEvent={props.setSelectedEvent} setResource={props.setResource} data={resource} key={resource.name} events={props.events.filter(event=>resource.name==event.resource)} />)
    } else {
        return <p>Nothing found</p>
    }
}

const Hours = (props) => {
    const ref = useRef(null);
    useEffect(() => {        
        // setHourWidth(ref.current ? ref.current.offsetWidth : 0)
        hourWidth = (ref.current ? ref.current.offsetWidth : 0)+0.34

    }, [ref.current]);
    return <div ref={ref} className="hour-item"><span className="time">{pad(props.int, true)}:00</span>&nbsp;</div>
}

function ResourceHeader(props){
    return (
        <div className="resource-row resource-row-heading">
            <div className="resource-column resource-name"></div>

            <div className="resource-column resource-time">
                {hours.map((hour)=><Hours int={hour} key={hour} />)}
            </div>
        </div>    
    )
}

const OrganisationCalendar = (props) => {

    const [startDate, setStartDate] = useState(DayPilot.Date.today())
    const [resources, setResources] = useState([])
    const [events, setEvents] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [cancelId, setCancelId] = useState(0)
    const [update, setUpdate] = useState(0)

    useEffect(function effectFunction() {
        
        props.setSelectedCalDay(startDate)

        async function fetchResources() {
            setIsLoading(true)
            const response = DataService.getCalendarResource(props.orgId)

            try {
                const resourcesResponse = await response;

                if( resourcesResponse.data.length == 0) {
                    props.showError('error loading resources')
                    setIsLoading(false)
                } else {
                    setResources(resourcesResponse.data);
                    props.setResources(resourcesResponse.data)
                    setIsLoading(false)
                }
            } catch (e) {
                props.showError('error')
            }
        }
        fetchResources();

        async function fetchEvents() {
            setIsLoading(true)
            const dateOb = new Date(startDate.value)
            const date = dateOb.getFullYear() + '-' + pad(dateOb.getMonth()+1, true) + '-' + pad(dateOb.getDate(), true)
            const response = DataService.getAllEvents(props.orgId, date)
            try {
                const eventsResponse = await response;
                setEvents([]);
    
                // filter to today
                
                const selectedDay = dateOb.getDate()
                
                // TODO - check month
                const selectedMonth = dateOb.getMonth()

                const daysEvents = eventsResponse.data.filter(event=>selectedDay==new Date(event.start).getDate()&&selectedMonth==new Date(event.start).getMonth()||selectedDay==new Date(event.end).getDate()&&selectedMonth==new Date(event.end).getMonth())
    
                setEvents(daysEvents);
                setIsLoading(false)
            } catch (e) {
                props.showError('error')
                setIsLoading(false)
            }
        }
        fetchEvents();
 
    }, [startDate, props.scheduleUpdate, update])

    const isMounted = useRef(false)

    useEffect(function effectFunction() {
        async function cancelEvent() {
            setIsLoading(true)
            const response = DataService.deleteEvent(cancelId)
            try {
                const cancelResponse = await response;
                setUpdate(update+1)
                setIsLoading(false)
            } catch (e) {
                props.showError('error')
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            cancelEvent()
        } else {
            isMounted.current = true;
        }
    }, [cancelId])

    return ( 
        <div className="orgcal">
            {isLoading && <LoadingSpinner/>}
            <div className="orgcal-monthSelect">
                <DayPilotNavigator
                    selectMode={"day"}
                    showMonths={1}
                    skipMonths={1}
                    startDate={startDate}
                    selectionDay={startDate}
                    onTimeRangeSelected={ args => {
                        setStartDate( args.day );
                        selectedDate = new Date(args.day);
                        props.setSelectedCalDay(args.day)
                    }}
                />
            </div>
            <div className="orgcal-bookings-scroll">
                <div className="orgcal-bookings">
                    <ResourceHeader />
                    <ResourceRows setCancelId={setCancelId} setSelectedEvent={props.setSelectedEvent} resources={resources} events={events} selectedDay={startDate.value} setResource={props.setResource} />
                </div>
            </div>
        </div>
    );

}

export default OrganisationCalendar
