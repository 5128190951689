import axios from 'axios';
import { getConfig } from "../config";

const { apiOrigin = "https://suite.develop.nonprodsvc.com/v1", audience } = getConfig();

class DataServiceBrand {
    getCatList() {
        return axios({
            method: 'get',
            url: `${apiOrigin}/client/full-categories?client_uuid=d7fa4273-7f04-4567-b3fe-7a7d670f9da5&brand_id=org_tH4Ua9McxHXz9pfL`,
            headers: { 'Content-Type': 'application/json' , 'accept': 'application/json' }
        });
    }

    uploadCategoryIcon(body: any, headers: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/admin/category-icon`,
            headers: headers, 
            data: body
        });
    }

    addCategory(body: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/admin/add-category?category_name=${encodeURIComponent(body.category_name)}&parent_category_id=${body.parent_category_id}&icon_url=${body.icon_url}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        });
    }

    updateCategory(body: any, token: any) {
        return axios({
            method: 'put',
            url: `${apiOrigin}/admin/update-category?category_id=${body.category_id}&category_name=${encodeURIComponent(body.category_name)}&parent_category_id=${body.parent_category_id}&icon_url=${body.icon_url}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        });
    }

    deletePrimeCat(id: any, token: any) {
        return axios({
            method: 'delete',
            url: `${apiOrigin}/admin/remove-prime-category?category_id=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        });
    }

    deleteSearchCat(id: any, token: any) {
        return axios({
            method: 'delete',
            url: `${apiOrigin}/admin/remove-search-category?category_id=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        });
    }
}
export default new DataServiceBrand();