import React, {Component, useState, useRef, useEffect} from 'react';
 
import {
  AvailabilityCalendar,
  AvailabilityEvent,
  MsSinceMidnightRange,
  Booking,
  Range,
  CalendarThemeProp,
} from 'react-availability-calendar';
import moment from 'moment';
import DataService from "../../services/data.service";
import LoadingSpinner from '../global/LoadingSpinner';
 
import 'bootstrap/dist/css/bootstrap.min.css';
import '../appviews/booking.css';
 
const msInHour = 60 * 60 * 1000;

function pad(num, prefix) {
    if(prefix) {
        if(num < 10) {
            return '0' + num
        } else {
            return num
        }
    } else {
        if(num == 0) {
            return num + '0'
        } else {
            return num
        }
    }
}


function makeDuration(durationInMins) {
    console.log(durationInMins)
    return (durationInMins / 60)*msInHour
}
 
const ConsoleBookingCalendar = (props) => {
    const now = new Date();
    const bookingData = {}
    let date = ''
    let startTime = ''
    let endTime = ''

    const [bookings, setBookings] = useState([])
    const [queryDate, setQueryDate] = useState(now.getFullYear() + '-' + pad(now.getMonth()+1) + '-' + pad(now.getDate()))
    const [isLoading, setIsLoading] = useState(false)

    const onAvailabilitySelected = (a) => {
        date = a.startDate.getFullYear() + '-' + pad(a.startDate.getMonth()+1, true) + '-' + pad(a.startDate.getDate(), true)
        startTime = pad(a.startDate.getHours(), true) + ':' + pad(a.startDate.getMinutes(), false)
        endTime = pad(a.endDate.getHours(), true) + ':' + pad(a.endDate.getMinutes(), false)
        bookingData.start_time = startTime 
        bookingData.end_time = endTime
        bookingData.date = date
        props.setBooking(bookingData)
    }
    
    const onChangedCalRange = (fromDate) => {
        const date = fromDate.start.getFullYear() + '-' + pad(fromDate.start.getMonth()+1) + '-' + pad(fromDate.start.getDate())
        setQueryDate(date)
    }

    const blockOutPeriods = [
        // [0 * msInHour, 9 * msInHour],
        // [19 * msInHour, 24 * msInHour],
    ];

    const buildBookings = (apiBookings) => {
        const bookingsList = []
    
        if( apiBookings.length > 0) {
            for (let i = 0; i < apiBookings.length; i++) { 
                const element = apiBookings[i]
                const startDate = element.start.replace("T", " ")
                const endDate = element.end.replace("T", " ")
                bookingsList.push(
                    {
                        startDate: new Date(startDate), 
                        endDate: new Date(endDate)
                    }
                )
            }
        }
    
        setBookings(bookingsList)
        setIsLoading(false)
    }

    useEffect(function effectFunction() {

        async function fetchBookings() {
            setIsLoading(true)
            const response = DataService.getBookings(props.orgId, props.resourceId, queryDate)
            try {
                const bookingsResponse = await response
                buildBookings(bookingsResponse.data)
            } catch(e) {
                setIsLoading(false)
            }
        }
        fetchBookings()

    },[queryDate, props.resourceId])
  
    const providerTimeZone = 'Europe/London';
    
    return (
        <div className="position-relative">
            {isLoading && <LoadingSpinner/> }
            {bookings.length > 0 &&
                <AvailabilityCalendar
                    bookings={bookings}
                    providerTimeZone={providerTimeZone}
                    moment={moment}
                    initialDate={now}
                    onAvailabilitySelected={onAvailabilitySelected}
                    onCalRangeChange={onChangedCalRange}
                    blockOutPeriods={blockOutPeriods}
                    slotLengthMs={makeDuration(props.duration/60)}
                    slotStepMs={makeDuration(props.step/60)}
                />
            }
        </div>
    );
};
 
export default ConsoleBookingCalendar;