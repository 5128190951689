import React, {useState, useEffect} from 'react';
import DataService from "../../services/data.service";
import LoadingSpinner from '../global/LoadingSpinner';

function pad(num, prefix) {
    if(prefix) {
        if(num < 10) {
            return '0' + num
        } else {
            return num
        }
    } else {
        if(num == 0) {
            return num + '0'
        } else if(num < 10){
            return '0' + num
        } else {
            return num
        }
    }
}

const ViewEvent = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [eventDetails, setEventDetails] = useState({})

    useEffect(function effectFunction() {

        async function fetchEventDetails() {
            setIsLoading(true)
            const response = DataService.getEvent(props.eventId)
            try {
                const eventsResponse = await response
                const eventsData = eventsResponse.data
                setEventDetails(eventsData)
                setIsLoading(false)
            } catch(e) {
                setIsLoading(false)
            }
        }
        fetchEventDetails();

    }, [])

    const getDate = (date) => {
        console.log(date)
        if(typeof date == 'undefined') {
            return ''
        } else {
            return new Date(date).toDateString()
        } 
    }
    const getResourceName = (id) => {
        const resource = props.resources.find(i => i.name === id)
        if(typeof resource == 'undefined') {
            return ''
        } else {
            return resource.res_name
        }
    }

    return (
        <div className="view-event-details">
            {isLoading && <LoadingSpinner/>}
            {eventDetails.length !== 0 &&
                <div className="details">
                    <h4>{eventDetails.product} <em>with</em> {getResourceName(eventDetails.resource)}</h4>
                    <p>{getDate(eventDetails.date)}</p>
                    <p>{eventDetails.start} - {eventDetails.end}</p>
                    <p>Customer: {eventDetails.detail}</p>
                </div>
            }
        </div>
    )
}

export default ViewEvent