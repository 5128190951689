export const sendMessage = (eventName: string, channelName: string, message: string, username: string) => {
    const data = {
        channel:  channelName,
        event_name: eventName,
        message: message,
        username: username,
        date: new Date()
    }
    fetch('https://suite.develop.nonprodsvc.com/v1/messaging/send-message', {method: 'POST', body: JSON.stringify(data), headers: {'Content-Type': 'application/json'} }).then(()=> {
    })
}