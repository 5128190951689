import * as React from 'react';
import Button from '@mui/material/Button';
import { Toolbar } from '@mui/material';

const Admin = () => {
    const style = {
        my: 2, color: 'white', '&:hover': { color: 'white', textDecoration: 'underline' }
    }
    return(
        <div>
            <Toolbar />
            <h1>Administration</h1>
            <div className={'row'}>
                <div className="col-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Categories</h5>
                            <Button variant="contained"
                                href={'/admin/manage-categories'}
                                sx={style}
                            >
                                Manage Categories
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Attributes</h5>
                            <Button variant="contained"
                                href={'/admin/manage-attributes'}
                                sx={style}
                            >
                                Manage Attributes
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">NTAs</h5>
                            <Button variant="contained"
                                href={'/admin/manage-ntas'}
                                sx={style}
                            >
                                Manage NTAs
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">DDC</h5>
                            <Button variant="contained"
                                href={'/admin/manage-ddc'}
                                sx={style}
                            >
                                Manage DDC
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Admin