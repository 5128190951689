import React, {useState, ChangeEvent, useContext, useEffect, FC} from 'react';
import DataService from "../../services/data.service";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import Modal from '../Modal';
import Pagination from '../Pagination';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import Popover from '../global/Popover';
import { ModalContextType } from '../../interfaces/ModalContextType'
import { ModalContext } from "../../contexts/modalContext";
import Button from '../elements/Button';
import CategoryModel from '../../interfaces/CategoryModel';
import Flex from '../elements/Flex';

interface CategoryProps {
    update: number
    setUpdate: (a:number)=>void
    data: CategoryModel
    setDeleteItem: (a: CategoryModel)=>void
    parentCats: CategoryModel[]
    filter: boolean
}

const CategoryItem:FC<CategoryProps> = (props) => {
    let { handleModal } = React.useContext<any>(ModalContext)

    const [confirmDelete, setConfirmDelete] = useState(false)
    const deleteItem = () =>{
        setConfirmDelete(false)
        props.setDeleteItem(props.data)
    }

    const openEditModal = () => {
        handleModal(
            <EditCategory update={props.update} data={props.data} setUpdate={props.setUpdate} />
            , 
            'Edit Category'
        )
    }

    const isFiltered = () => {
        return props.filter?'list-group-item':''
    }

    return (
            <Flex justify={`justify-content-between ${isFiltered()}`} align={'align-items-center'}>
                { props.data.icon_url !== '' && 
                    <img src={props.data.icon_url} className="me-3" style={{width: '25px', height: '25px'}} />
                }
                <div className={`mb-0 me-auto align-self-center ${(props.filter)?'mt-1':''}`}>
                    <h5 style={{width: '100%'}}>
                        {props.data.parent_category_id == 0 && <strong>{props.data.category_name}</strong> }
                        {props.data.parent_category_id !== 0 && props.data.category_name }
                        {props.filter && <span className="ms-3 badge bg-primary">{props.data.parent_category_name}</span> }
                    </h5>
                </div>
                <div className="d-inline" style={{position: 'relative'}}>
                    <Button label={'Edit Category'} size="small" type={'link'} action={openEditModal} />
                </div>
                <div className="d-inline" style={{position: 'relative'}}>
                    {confirmDelete && <Popover popWidth={200} closePop={setConfirmDelete} confirmPop={deleteItem} message={"Are you sure you want to delete " + props.data.category_name} />}
                    <Button label={'Delete Category'} size="small" type={'link error'} action={(e)=>setConfirmDelete(true)} />
                </div>
            </Flex>
    )
}

export default CategoryItem