import React, { useState, FC, ReactNode } from 'react';
import OrganisationModel from '../interfaces/OrganisationModel';


const orgModelDefaults = {
    name: '',
    description: '',
    icon_url: '',
    street_address: '',
    postcode: '',
    website: '',
    org_id: '',
    brand_name: '',
    latitude: '',
    longitude: ''
}

const OrganisationContext = React.createContext<[OrganisationModel, (a: OrganisationModel)=>void]>([orgModelDefaults, () => {}]);

interface Props {
    children: ReactNode;
}

const OrganisationProvider:FC<Props> = ({ children }) => {
    const [orgData, setOrgData] = useState(orgModelDefaults);
    return (
        <OrganisationContext.Provider value={[orgData, setOrgData]}>
            {children}
        </OrganisationContext.Provider>
    );
};

export { OrganisationContext, OrganisationProvider };