import axios from 'axios';
import { getConfig } from "../config";

const { apiOrigin = "https://suite.develop.nonprodsvc.com/v1", audience } = getConfig();

class DataServiceNta {
    getCategoryNta(cats: number[], token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/admin/category-nta`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
            data: cats
        });
    }
    addNta(body: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/admin/add-nta`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
            data: body
        });
    }
    
    deleteNta(nta: any, text: string, token: any) {
        return axios({
            method: 'delete',
            url: `${apiOrigin}/admin/nta?nta_encoded=${nta}&confirm_delete=${text}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }

    updateNta(body: any, token: any) {
        return axios({
            method: 'put',
            url: `${apiOrigin}/admin/update_nta`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' },
            data: body
        });
    }

    linkCategoryNta(body: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/admin/link-nta-category`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' },
            data: body
        });
    }

    getTypes(token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/admin/nta-types`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }

    getOperators(token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/admin/nta-operators`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }
}
export default new DataServiceNta();