import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class Product extends Component {
    constructor(props) {
        super(props);
        this.handleClick.bind(this);
    }

    handleClick = () => {
        // Simply call the setStateOfParent function from 
        // prop and pass required argument
        this.props.setProduct(this.props.data.name, this.props.data.duration );
    }
    render() {
        return (
            <button className="list-group-item" onClick={this.handleClick} key={this.props.data.name}>{this.props.data.name}</button>
        )
    }
}

function ProductList(props){
    if( props.products.length > 0) {
        return props.products.map((product)=><Product setProduct={props.setProduct} data={product} key={product.name} />)
    } else {
        return <p>Nothing found</p>
    }
}

const BookingProducts = (props) => {
    return (
        <div>
            <h2>Products</h2>
            <ul className="list-group">
                <ProductList setProduct={props.setProduct} products={props.products} />
            </ul>
        </div>
    );
}

export default BookingProducts;