import { Grid, Card, Chip, Alert, Box, Button, Typography } from '@mui/material';
import React, {FC, MouseEvent, useState, useContext, useRef, useEffect, ChangeEvent} from 'react';
import ProblemEventModel, { ProblemEventProblem } from '../../interfaces/ProblemEventModel'
import FormRow from '../formfields/FormRow';
import TextInputRow from '../formfields/TextInputRow';
import DataService from "../../services/data.service";
import { ProblemEventsContext } from '../../contexts/ProblemEventsContext';
import ProblemEventsCheck from '../../helpers/problemEventCheck';

interface ProblemEventProps {
    event: ProblemEventModel
    setReloadProblemEvents: (a: number) => void
}

const getDate = (date: string, dayTime: string) => {
    const d = new Date(date)
    const iso = d.toISOString()
    const isoSplit = iso.split('T')
    if( dayTime == 'day') {
        return new Date(isoSplit[0]).toDateString()
    }
    return isoSplit[1].split('.')[0]
}

interface ProblemProps {
    data: ProblemEventProblem,
    event_id: string,
    org_id: string,
    setReloadProblemEvents: (a: number)=>void
}

const Problem:FC<ProblemProps> = (props) => {
    const [overrideOpen, setOverrideOpen] = useState(false)
    const [overrideMessage, setOverrideMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setOverrideMessage(e.target.value)
    }

    async function overrideProblem( ) {
        setIsLoading(true)
        const response = DataService.overrideProblem({
            event_id: props.event_id,
            org_id: props.org_id,
            problem_code: props.data.problem_code,
            override_reason: overrideMessage
        })
        try {
            const eventsResponse = await response
            props.setReloadProblemEvents(Math.random())
            setOverrideMessage('')
            setOverrideOpen(false)
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setErrorMessage(e.response.data.detail[0])
            } else {
                setErrorMessage(e.message)
            }
        }

    }

    return (
        <Alert sx={{marginBottom: '5px'}} severity={props.data.override?'info':'error'}>
            <p>{props.data.problem}</p>
            {props.data.override ? (
                <p><strong>Overridden</strong>: {props.data.override}</p>
            ) : (
                <Button variant="outlined" size="small" color="error" onClick={()=>setOverrideOpen(true)}>Override Problem</Button>
            )}
            {overrideOpen && 
                <div>
                    <TextInputRow label={'Override Reason'} defaultValue={''} field={'override-reason'} onFieldChange={(e)=>onChange(e)} inputGroup={true} buttonAction={overrideProblem} buttonText={'Overrride'} />
                </div>
            }
        </Alert>
        )
}

const ProblemEvent:FC<ProblemEventProps> = (props) => {    

    return <Card variant="outlined" sx={{ padding: '10px 20px', marginBottom: '10px' }}>
        <Grid container direction="row" sx={{width: '100%', justifyContent: 'space-between'}}>
            <Grid item>
                <Typography sx={{fontWeight: 'bold'}}>{props.event.product_desc}</Typography>
                <Typography sx={{textTransform: 'uppercase', fontWeight: '200', fontSize: '12px'}}>{props.event.type}</Typography>
            </Grid>
            <Grid item>
                <Typography sx={{textTransform: 'uppercase', fontWeight: '200', fontSize: '12px', textAlign: 'right'}}>Start: {getDate(props.event.start, 'day')} | {getDate(props.event.start, 'time')}</Typography>
                <Typography sx={{textTransform: 'uppercase', fontWeight: '200', fontSize: '12px', textAlign: 'right'}}>End: {getDate(props.event.start, 'day')} | {getDate(props.event.end, 'time')}</Typography>
            </Grid>
        </Grid>
        <Typography sx={{marginTop: '10px', textTransform: 'uppercase', fontWeight: '200', fontSize: '12px'}}>Customer Name</Typography>
        <Typography sx={{fontWeight: 'bold', marginBottom: '10px'}}>{props.event.detail}</Typography>

        {
            props.event.problems.map((item, index)=>(
                <Problem key={index} data={item} event_id={props.event.id} org_id={props.event.org_id} setReloadProblemEvents={props.setReloadProblemEvents} />
            ))
        }

        <Box>
            <Button>View Event</Button>
        </Box>
    </Card>
}

interface ProblemEventsProps {
    problemEvents: ProblemEventModel[]
    setReloadProblemEvents: (a: number) => void
}

const ProblemEvents:FC<ProblemEventsProps> = (props) => {

    return <Box sx={{minWidth: '400px', padding: '20px'}}>
        <h3 className="card-title">Problem Events</h3>
        <div>{props.problemEvents.map((event, index)=>(<ProblemEvent setReloadProblemEvents={props.setReloadProblemEvents} key={index} event={event} />))}</div>
    </Box>
}

export default ProblemEvents