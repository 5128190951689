import React, {Component, useState, useRef, useEffect} from 'react';
import DataService from "../../services/data.service";
import LoadingSpinner from '../global/LoadingSpinner';




const ResourcesList = (props) => {
    let resList = <div>None</div>

    let findResById = (id, resources) => {
        let matching = resources.find(resource=>resource.name==id)
        if( matching ) {
            return resources.find(resource=>resource.name==id)
        } else {
            return {res_name: ''}
        }
    }
    
    if( props.selectedResources.length ) {
        resList = props.selectedResources.map(
            (resource)=>{
                const res = findResById(resource, props.allResources)
                return <span key={resource} className="badge bg-info">{res.res_name}</span>
            }
        )
    } 
    return (
        resList
    )
}

const EventItem = (props) => {

    const deleteEvent = () => {
        props.deleteEvent(props.data.id)
    }

    const editEvent = () => {
        props.setSpecialEventId(props.data.id)
    }

    const getDate = () => {
        return new Date(props.data.date).toDateString()
    }

    return (
        <div className="special-events-item">
            <div className="d-flex w-100 justify-content-between align-items-center">
                <div className="special-events-heading">
                    <h5 className="mb-0">{props.data.recurring_description}</h5>
                    <p className="mb-0"><ResourcesList allResources={props.allResources} selectedResources={props.data.resource_set} /></p>
                </div>
                <p className="mb-0">{getDate()} | {props.data.start_time}-{props.data.end_time}</p>
                <p className="mb-0 special-events-recurring">
                    <small>
                        Recurring: {props.data.recurring}
                        <br/>Recurring End Date: {props.data.recurring_end}
                    </small>
                </p>
                <p className="mb-0 text-end"><small>
                    <button className="btn btn-link p-0" onClick={(e) => editEvent()}>Edit</button><br/>
                    <button className="btn btn-link p-0" onClick={(e) => deleteEvent()}>Remove</button></small>
                </p>
            </div>
        </div>
    )
}

const SpecialEvents = (props) => {
    const isMounted = useRef(false);
    const [isLoading, setIsLoading] = useState(false)
    const [specialEventsArray, setSpecialEvents] = useState([])
    const [deleteId, setDeleteId] = useState('')

    useEffect(function effectFunction() {

        async function fetchSpecialEvents() {
            setIsLoading(true)
            const response = DataService.getSpecialEvents(props.orgId)
            try {
                const eventsResponse = await response
                setIsLoading(false)
                setSpecialEvents(eventsResponse.data)
                
            } catch(e) {
                props.showError('error')
                setIsLoading(false)
            }
        }
        fetchSpecialEvents();

    }, [deleteId, props.specialEventUpdate])

    useEffect(function effectFunction() {

        async function deleteEvent() {
            setIsLoading(true)
            const response = DataService.deleteSpecialEvent(deleteId, props.orgId)
            try {
                const eventsResponse = await response
                setIsLoading(false)
                setDeleteId('')
                props.setUpdateClashing(props.updateClashing+1)
                
            } catch(e) {
                props.showError('error')
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            if( deleteId != '') {
                deleteEvent()
            }
        } else {
            isMounted.current = true;
        }
        

    }, [deleteId])

    return (
        <div className="special-events">
            {isLoading && <LoadingSpinner/> }
            <div className="special-events-list">
                {specialEventsArray.map((specialEvent)=><EventItem allResources={props.resources} key={specialEvent.id} data={specialEvent} deleteEvent={setDeleteId} setSpecialEventId={props.setSpecialEventId} />)}
            </div>
        </div>
    )
}

export default SpecialEvents