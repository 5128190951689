import React, {FC, useState, useEffect} from 'react';

interface FormLabelProps{
    label: string
    for?: string
}

const FormLabel: FC<FormLabelProps> = (props) => {
    return (
        <label className="form-label" htmlFor={props.for?props.for:''}>{props.label}</label>
    )
}

export default FormLabel