import React, {useState, ChangeEvent, useContext, useEffect, FC, ReactNode} from 'react';
import DataServiceAttribute from '../../services/DataServiceAttribute';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import Pagination from '../Pagination';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import Popover from '../global/Popover';
import { ModalContextType } from '../../interfaces/ModalContextType'
import { ModalContext } from "../../contexts/modalContext";
import Button from '../elements/Button';
import CategoryModel from '../../interfaces/CategoryModel';
import CategoryItem from './CategoryItem'
import ParentCategoryItem from './ParentCategoryItem'
import ListGroup from '../elements/ListGroup';
import Flex from '../elements/Flex';
import InlineError from '../elements/InlineError';
import { AttributeMain } from '../../interfaces/AttributeModel'
import AttributeItem from './AttributeItem';
import AddAttribute from './AddAttribute';
import EditAttribute from './EditAttribute';
import DataServiceBrand from '../../services/DataServiceBrand';
import CategorySelect from './CategorySelect';
import { Toolbar } from '@mui/material';

interface ManageAttrProps {
}

const ManageAttributes:FC<ManageAttrProps> = () => {
    let { handleModal } = useContext<any>(ModalContext)
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false)
    const [update, setUpdate] = useState(0)
    const [deleteItem, setDeleteItem] = useState<AttributeMain | undefined>()
    const [filterState, setFilterState] = useState('ALL')
    const [error, setError] = useState('')
    const [attrList, setAttrList] = useState<AttributeMain[]>([])
    const [loadedAttrList, setLoadedAttrList] = useState<AttributeMain[]>([])
    const [filteredAttrList, setFilteredAttrList] = useState<AttributeMain[]>([])
    const [catFilterId, setCatFilterId] = useState(0)
    const [catList, setCatList] = useState<CategoryModel[]>([])

    useEffect(()=> {
        let isMounted = true
        if(isMounted) {
            async function fetchAttrs() {
                setIsLoading(true)
                const token = await getAccessTokenSilently()
                const response = (catFilterId === 0) ? 
                    DataServiceAttribute.getAllAttributes(token) : 
                    DataServiceAttribute.getCategoryAttributes(catFilterId, token)

                try {
                    const attrResponse = await response
                    setIsLoading(false)
                    setAttrList(attrResponse.data)
                    setLoadedAttrList(attrResponse.data)
                    setError('')
                    
                } catch(e) {
                    setIsLoading(false)
                }
            }
            fetchAttrs()
        }
        return () => { isMounted = false }

    }, [update, catFilterId])

    useEffect(()=> {

        if( deleteItem !== undefined) {
            async function deleteAttr() {
                setIsLoading(true)
                const token = await getAccessTokenSilently();
                const response = DataServiceAttribute.deleteAttribute(deleteItem?.attribute, token)
                try {
                    const orgsResponse = await response
                    setUpdate(update + Math.floor((Math.random() * 10) + 1))
                    setIsLoading(false)
                    
                } catch(e:any ) {
                    setError(e.response.data.errors[0])
                    setIsLoading(false)
                }
            }
            deleteAttr()
        }
    }, [deleteItem])

    const filterCats = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase()
        if( value === '') {
            setFilterState('ALL')
        } else {
            setFilterState('FILTER')
        }
        setFilteredAttrList(loadedAttrList.filter(item => item.display_name.toLowerCase().includes(value)))
    }

    return(
        <div>
            <Toolbar />
            <Flex justify='justify-content-between' align='align-items-center'>
                <h1>Manage Attributes</h1>
                <Button label={'Add Attribute'} type={'primary'} action={
                    ()=>handleModal(
                        <AddAttribute update={update} setUpdate={setUpdate} />
                        , 
                        'Add Attribute'
                    )} />
                
            </Flex>
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            {isLoading && <LoadingSpinner/>}
            <Flex justify='' align=''>
                <div className="form-floating mb-3 w-80 flex-fill">
                    <input type="text" className="form-control" id="filter" onChange={(e)=>filterCats(e)} />
                    <label htmlFor="filter">Search for Attribute</label>
                </div>
                
                <CategorySelect setCatList={setCatList} setCatFilterId={setCatFilterId} />
                
            </Flex>
            <ListGroup>
                {attrList.map(item=>(<AttributeItem catList={catList} key={item.attribute} update={update} setUpdate={setUpdate} data={item} setDeleteItem={setDeleteItem} />))}
            </ListGroup>
            {filterState === 'FILTER' &&
                <Pagination setItems={setAttrList} originalItems={loadedAttrList} filteredItems={filteredAttrList} />
            }
        </div>
    )
}

export default withAuthenticationRequired(ManageAttributes, {
    onRedirecting: () => <LoadingSpinner />,
});