import React, {FC, MouseEvent, useContext, ChangeEvent, useState, useRef, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import DataServiceProduct from '../../services/DataServiceProduct';
import LoadingSpinner from '../global/LoadingSpinner';
import Modal from '../Modal';
import AddProduct from './AddProduct'
import EditProduct from './EditProduct'
import Pagination from '../Pagination'
import ProductModel from '../../interfaces/ProductModel';
import { ModalContext } from "../../contexts/modalContext";
import Popover from '../global/Popover';
import Button from '../elements/Button';
import { OrganisationContext } from '../../contexts/OrganisationContext';
import getID from '../../helpers/getID';

interface ProductProps {
    setDeleteId: (a: string)=>void;
    data: ProductModel;
    reloadProducts: number;
    setReloadProducts: (a: number)=>void;
    orgId: string;
    brandId: string;
}

const Product: FC<ProductProps> = (props) => {
    let { handleModal } = useContext<any>(ModalContext)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const deleteProduct = () => {
        props.setDeleteId(props.data.product_id)
    }
    const editProduct = (e: MouseEvent) => {
        e.preventDefault()
        handleModal(
            <EditProduct data={props.data} reloadProducts={props.reloadProducts} setReloadProducts={props.setReloadProducts} brandId={props.brandId} orgId={props.orgId} />,
            `Edit ${props.data.item}`,
            true
        )
    }
    const checkNTA = () => {
        if( props.data.NTA == undefined ) return []
        return props.data.NTA
    }
    const getInMinutes = (secs: number) => {
        return secs/60
    }
    return (
        <div className="list-group-item list-group-item-action" aria-current="true">
            <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-0 mt-1">{props.data.item}</h5>
                <div>
                    <Button label={'Edit'} type={'link'} action={(e)=>editProduct(e)} /> | 
                    <div className="position-relative d-inline-block">
                        <Button label={'Delete'} type={'link'} action={(e)=>setConfirmDelete(true)} />
                        {confirmDelete && <Popover popWidth={200} closePop={setConfirmDelete} confirmPop={deleteProduct} message={"Are you sure you want to delete " + props.data.item} />}
                    </div>
                </div>
            </div>
            <p className="mb-0">{props.data.description}</p>
            {checkNTA().length > 0 &&
                <p className="mb-0">
                    {checkNTA().map((item, i)=>(
                        <span className="badge bg-info text-dark me-2" key={item.nta_encoded}>
                            {item.display_name}: &nbsp;
                            {item.nta_encoded ==='nta_duration' && getInMinutes(item.value) + ' minutes'}
                            {item.nta_encoded !=='nta_duration' && item.value}
                        </span>))}
                </p>
            }

        </div>
    )
}

interface ProductListProps {
    setDeleteId: (a: string)=>void;
    products: ProductModel[];
    reloadProducts: number;
    setReloadProducts: (a: number)=>void;
    orgId: string;
    brandId: string;
}

const ProductsList: FC<ProductListProps> = (props) => {
    if( props.products.length > 0) {
        return <div>{ props.products.map((product)=><Product setDeleteId={props.setDeleteId} data={product} key={product.product_id} orgId={props.orgId} brandId={props.brandId}  reloadProducts={props.reloadProducts} setReloadProducts={props.setReloadProducts} />) }</div>
    } else {
        return <p>Nothing found</p>
    }
}

const Products:FC = () => {
    let { handleModal } = useContext<any>(ModalContext)
    const isMounted = useRef(false)
    
    const [products, setProducts] = useState<ProductModel[]>([])
    const [loadedProducts, setLoadedProducts] = useState<ProductModel[]>([])
    const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([])
    const [reloadProducts, setReloadProducts] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [orgData, setOrgData] = useContext(OrganisationContext);
    const [deleteId, setDeleteId] = useState('')
    const [addProduct, setAddProduct] = useState(false)
    const {
        getAccessTokenSilently
    } = useAuth0();

    const addModal = () => {
        
        handleModal(
            <AddProduct reloadProducts={reloadProducts} setReloadProducts={setReloadProducts} brandId={orgData.brand_name} orgId={orgData.org_id} />,
            `Add Product`,
            true
        )
    }

    useEffect(function effectFunction() {

        let isMounted = true;  

        async function fetchProducts() {
            setIsLoading(true)
            const token = await getAccessTokenSilently()
            const response = DataServiceProduct.getProducts(getID(), token)
            try {
                const dataResponse = await response
                setProducts(dataResponse.data)
                setLoadedProducts(dataResponse.data)
                setIsLoading(false)
                
            } catch(e) {
                setIsLoading(false)
            }
        }
        if(isMounted) fetchProducts()
        return () => { isMounted = false }
    }, [reloadProducts])

    useEffect(function effectFunction() {

        async function deleteProduct() {
            setIsLoading(true)
            
            const token = await getAccessTokenSilently()
            const response = DataServiceProduct.deleteProduct(deleteId, token)
            try {
                const dataResponse = await response
                setDeleteId('')
                setReloadProducts(reloadProducts+1)

            } catch(e) {
                setIsLoading(false)
            }
        }
        if( isMounted.current && deleteId != '' ) {
            deleteProduct()
        } else {
            isMounted.current = true;
        }

    }, [deleteId])

    const filterProducts = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase()
        if( value == '') setProducts(loadedProducts)
        setFilteredProducts(loadedProducts.filter(item => item.item.toLowerCase().includes(value)))
    }

    return(
        <div className="product-listing-container">
            {isLoading && <LoadingSpinner/> }
            <div className="border-bottom border-light mb-3 pb-3">
                <button className="btn btn-primary" onClick={()=>addModal()}>Add New Product</button>
            </div>
            <div className="form-floating mb-3">
                <input type="text" className="form-control" id="filter" onChange={(e)=>filterProducts(e)} />
                <label htmlFor="filter">Search for Product</label>
            </div>
            <ProductsList products={products} setDeleteId={setDeleteId} reloadProducts={reloadProducts} setReloadProducts={setReloadProducts} orgId={orgData.org_id} brandId={orgData.brand_name} />
            <Pagination setItems={setProducts} originalItems={loadedProducts} filteredItems={filteredProducts} />

        </div>
    )
}

export default Products