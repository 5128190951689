import React, { Component, useState, useRef, useEffect } from "react";

import DataService from "../../services/data.service";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import OrganisationCalendar from "./OrganisationCalendar";
import OrganisationSchedule from "./OrganisationSchedule";
import ResourceSchedule from "./ResourceSchedule";
import SpecialEvents from "./SpecialEvents";
import EditSpecialEvent from "./EditSpecialEvent";
import AddSpecialEvent from "./AddSpecialEvent"
import ViewEvent from "./ViewEvent"
import Modal from "../Modal"
import ClashingEvents from "./ClashingEvents";
import LoadingSpinner from '../global/LoadingSpinner';
import AddEvent from "./AddEvent";

function getID() {
    const path = window.location.pathname
    return path.split("/")[2]
}

function pad(num, prefix) {
    if(prefix) {
        if(num < 10) {
            return '0' + num
        } else {
            return num
        }
    } else {
        if(num == 0) {
            return num + '0'
        } else {
            return num
        }
    }
}

const ViewOrganisation = (props) => {

    const [id, setId] = useState(getID())
    const [organisation, setOrganisation] = useState({})
    const [resources, setResources] = useState([])
    const [events, setEvents] = useState([])
    const [showCal, setShowCal] = useState(true)
    const [scheduleUpdate, setScheduleUpdate] = useState(0)
    const [specialEventUpdate, setSpecialEventUpdate] = useState(0)
    const [resName, setResName] = useState('')
    const [resId, setResId] = useState('')
    const [showResSchedule, setShowResSchedule] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [specialEventId, setSpecialEventId] = useState('')
    const [addSpecialEvent, setAddSpecialEvent] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedCalDay, setSelectedCalDay] = useState('')
    const [selectedEvent, setSelectedEvent] = useState('')
    const [clashingEvents, setClashingEvents] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [updateClashing, setUpdateClashing] = useState(0)
    const [addEvent, setAddEvent] = useState(false)

    useEffect(function effectFunction() {

        async function getOrgs() {
            const response = DataService.getAllOrgs()
            const orgsResponse = await response
            let item = orgsResponse.data.find(x => x.organisation_id === id);
            setOrganisation(item)
        }
        getOrgs()

    },[])

    useEffect(function effectFunction() {

        async function fetchClashingEvents() {
            setIsLoading(true)
            const response = DataService.getClashingEvents(id)
            try {
                const eventsResponse = await response
                setIsLoading(false)
                setClashingEvents(eventsResponse.data)
                
            } catch(e) {
                props.showError('Error getting clashing events')
                setIsLoading(false)
            }
        }
        fetchClashingEvents();

    },[updateClashing])

    const makeScheduleUpdate = () => {
        setScheduleUpdate(scheduleUpdate+1)
        hideResourceSchedule()
    }

    const makeSpecialEventUpdate = () => {
        setSpecialEventUpdate(specialEventUpdate+1)
        hideSpecialEvent()
    }

    const makeAddSpecialEvent = () => {
        setSpecialEventUpdate(specialEventUpdate+1)
        hideAddSpecialEvent()
    }

    const setResource = (resId) => {
        setResId(resId)
        setShowResSchedule(true)
    }

    const hideResourceSchedule = () => {
        setShowResSchedule(false)
    }

    const hideSpecialEvent = () => {
        setSpecialEventId('')
    }

    const showError = (errorMessage) => {
        setErrorMessage(errorMessage)
    }

    const hideError = () => {
        setErrorMessage('')
    }

    const showAddSpecialEvent = () => {
        setAddSpecialEvent(true)
    }

    const hideAddSpecialEvent = () => {
        setAddSpecialEvent(false)
    }

    const setActiveTab = (tab) => {
        setSelectedTab(tab)
    }

    const getCalendarDate = () => {
        const day = new Date(selectedCalDay)
        return day.toDateString()
    }

    const hideViewEvent = () => {
        setSelectedEvent('')
    }

    const hideAddModal = () => {
        setAddEvent(false)
    }

    const showAddModal = () => {
        setAddEvent(true)
    }

    

    return (
        <div>
            {errorMessage != '' && 
                <Modal title="" modalAction={hideError}>
                    <p>{errorMessage}</p>
                </Modal>
            }
            <h1>{organisation.organisation_name}</h1>
            {/* <p><a href={'/organisation/'+organisation.organisation_id+'/edit'}>Edit Details</a></p> */}

            <ul className="nav nav-pills mb-3">
                <li className="nav-item">
                    <button className={(selectedTab == 0)? 'nav-link active': 'nav-link' } onClick={(e)=>setActiveTab(0)} aria-current="page">Event Calendar</button>
                </li>
                <li className="nav-item">
                    <button className={(selectedTab == 1)? 'nav-link active': 'nav-link' } onClick={(e)=>setActiveTab(1)}>Organisation Schedule</button>
                </li>
                <li className="nav-item">
                    <button className={(selectedTab == 2)? 'nav-link active': 'nav-link' } onClick={(e)=>setActiveTab(2)}>Special Events</button>
                </li>
                <li className="nav-item">
                    <a className={(selectedTab == 3)? 'nav-link active': 'nav-link' } href={'/organisation/details/'+organisation.organisation_id}>View Organisation Details</a>
                </li>
                {clashingEvents.length > 0 &&
                    <li className="nav-item ms-auto">
                        <button className={(selectedTab == 3)? 'nav-link active position-relative': 'nav-link position-relative' } onClick={(e)=>setActiveTab(3)}>
                            Clashing Events
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {clashingEvents.length}
                                <span className="visually-hidden">clashing events</span>
                            </span>
                        </button>
                    </li>
                }
            </ul>

            {showCal &&
                <div className="org-schedules-container">
                    
                    {isLoading && <LoadingSpinner/> }

                    {selectedTab == 0 &&
                        <div>
                            <h3 className="card-title">Event Calendar</h3>
                            <div className="card mb-2">
                                <div className="card-header d-flex justify-content-start align-items-center">
                                    <button className="btn btn-primary me-4" onClick={(e)=>showAddModal(e)}>Add Event</button>
                                    <p className="today-label"><strong>{getCalendarDate()}</strong></p>
                                </div>
                                <div className="card-body">
                                    <OrganisationCalendar setSelectedEvent={setSelectedEvent} setSelectedCalDay={setSelectedCalDay} setResources={setResources} orgId={id} scheduleUpdate={scheduleUpdate} setResource={setResource} showError={showError} />
                                </div>
                            </div>
                            {selectedEvent != '' &&
                                <Modal title="View Event" modalAction={hideViewEvent}>
                                    <ViewEvent eventId={selectedEvent} orgId={id} showError={showError} resources={resources} />
                                </Modal>
                            }
                            {addEvent && 
                                <Modal title="Add Event" modalAction={hideAddModal}>
                                    <AddEvent orgId={id} resources={resources} closeModal={hideAddModal} makeScheduleUpdate={makeScheduleUpdate} />
                                </Modal>
                            }
                        </div>
                    }
                    {selectedTab == 1 &&
                        <div>
                            <h3 className="card-title">Organisation Schedule</h3>
                            <div className="card">
                                <div className="card-body">
                                    <OrganisationSchedule updateClashing={updateClashing} setUpdateClashing={setUpdateClashing} orgId={id} makeScheduleUpdate={makeScheduleUpdate} showError={showError} />
                                </div>
                            </div>
                        </div>
                    }
                    {showResSchedule &&
                        <Modal title="Resource Schedule" modalAction={hideResourceSchedule}>
                            <ResourceSchedule orgId={id} updateClashing={updateClashing} setUpdateClashing={setUpdateClashing} resName={resName} resId={resId} makeScheduleUpdate={makeScheduleUpdate} showError={showError} />
                        </Modal>
                    }
                    {selectedTab == 2 &&
                        <div>
                            <h3 className="card-title">Special Events</h3>
                            <div className="card mb-2">
                                <div className="card-header d-flex justify-content-end align-items-center">
                                    <button className="btn btn-primary" onClick={(e) => showAddSpecialEvent()}>Add Special Event</button>
                                </div>
                                <div className="card-body">
                                    <SpecialEvents updateClashing={updateClashing} setUpdateClashing={setUpdateClashing} resources={resources} orgId={id} makeScheduleUpdate={makeScheduleUpdate} specialEventUpdate={specialEventUpdate} showError={showError} setSpecialEventId={setSpecialEventId} />
                                </div>
                            </div>
                        </div>
                    }
                    {specialEventId != '' &&
                        <Modal title="Edit Special Event" modalAction={hideSpecialEvent}>
                            <EditSpecialEvent updateClashing={updateClashing} setUpdateClashing={setUpdateClashing} resources={resources} specialEventId={specialEventId} makeSpecialEventUpdate={makeSpecialEventUpdate} orgId={id} showError={showError}  />
                        </Modal>
                    }

                    {addSpecialEvent &&
                        <Modal title="Add Special Event" modalAction={hideAddSpecialEvent}>
                            <AddSpecialEvent updateClashing={updateClashing} setUpdateClashing={setUpdateClashing} resources={resources} makeAddSpecialEvent={makeAddSpecialEvent} orgId={id} showError={showError}  />
                        </Modal>
                    }

                    {selectedTab == 3 &&
                        <div>
                            <h3 className="card-title">Clashing Events</h3>
                            <div className="card">
                                <div className="card-body">
                                    <ClashingEvents orgId={id} resources={resources} update={updateClashing} setUpdate={setUpdateClashing} clashingEvents={clashingEvents} makeScheduleUpdate={makeScheduleUpdate} showError={showError} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
    
}

export default withAuthenticationRequired(ViewOrganisation, {
    onRedirecting: () => <LoadingSpinner />,
});