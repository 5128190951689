import axios from 'axios';
import { getConfig } from "../config";

const { apiOrigin = "https://suite.develop.nonprodsvc.com/v1", audience } = getConfig();

class DataServiceAttribute {
    getCategoryAttributes(id: number, token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/admin/category-attributes?cat_id=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        });
    }

    getAllAttributes(token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/admin/attributes`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        });
    }

    linkCategoryAttribute(data: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/admin/link-attribute-category?attribute=${data.attribute}&category_id=${data.category_id}&operation=${data.operation}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        });
    }

    addAttribute(name: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/admin/attribute?display_name=${name}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        });
    }

    deleteAttribute(name: any, token: any) {
        return axios({
            method: 'delete',
            url: `${apiOrigin}/admin/attribute?attribute=${name}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        })
    }

    updateAttribute(name: any, id: any, token: any) {
        return axios({
            method: 'put',
            url: `${apiOrigin}/admin/update-attribute?display_name=${name}&shortcode=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        })
    }

}
export default new DataServiceAttribute();