const Modal = (props) => {
    return(
        <div className="modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{props.title}</h5>
                    <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={props.modalAction}></button>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
                </div>
            </div>
        </div>
    )
}

export default Modal