import React, {Component, useState, useRef, useEffect} from 'react';
 
import {
  AvailabilityCalendar,
  AvailabilityEvent,
  MsSinceMidnightRange,
  Booking,
  Range,
  CalendarThemeProp,
} from 'react-availability-calendar';
import moment from 'moment';
import LoadingSpinner from '../global/LoadingSpinner';
import DataService from "../../services/data.service";
 
import 'bootstrap/dist/css/bootstrap.min.css';
import './booking.css';
 
const msInHour = 60 * 60 * 1000;

function pad(num, prefix) {
    if(prefix) {
        if(num < 10) {
            return '0' + num
        } else {
            return num
        }
    } else {
        if(num == 0) {
            return num + '0'
        } else {
            return num
        }
    }
}


function makeDuration(durationInMins) {
    return (durationInMins / 60)*msInHour
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function buildBookings(apiBookings) {
    const bookingsList = []

    if( apiBookings.length > 0) {
        apiBookings.forEach(element => {
            const startDate = element.start.replace("T", " ").replace("+00:00", "")
            const endDate = element.end.replace("T", " ").replace("+00:00", "")
            bookingsList.push(
                {
                    startDate: new Date(startDate), 
                    endDate: new Date(endDate)
                }
            )
        });
    }

    return bookingsList
}
 
const HarnessCalendar = (props) => {
    const now = new Date();
    const bookingData = {}
    let date = ''
    let startTime = ''
    let endTime = ''
    const [bookings, setBookings] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [fromDate, setFromDate] = useState(getParameterByName('fromDate'))
    const [confirmation, setConfirmation] = useState('')

    useEffect(function effectFunction() {

        async function fetchOrgs() {
            setIsLoading(true)
            const response = DataService.getBookings(getParameterByName('orgId'), getParameterByName('resId'), getParameterByName('fromDate'))
            try {
                const orgsResponse = await response
                setBookings(buildBookings(orgsResponse.data))
                setIsLoading(false)
                
            } catch(e) {
                setIsLoading(false)
            }
        }
        fetchOrgs()

    }, [fromDate])

    const createEvent = async (data) => {

        data.resource = getParameterByName('resId')
        data.customer = "customer_b"
        data.cust_uuid = "c9a5fcb0-f9e0-565f-999b-9e4abbb1cd36"
        data.org_id = getParameterByName('orgId')
        data.product = getParameterByName('productName')

        setIsLoading(true)

        DataService.createBooking(data).then(
            response => {

                if( response.data == 'unavailable') {
                    setConfirmation('booking slot is already taken')
                    setIsLoading(false)

                } else {
                    setConfirmation(`You have booked: ${response.data.product} @ ${response.data.start} on ${new Date(response.data.date).toDateString()}`)
                    setIsLoading(false)
                }
            },
            error => {
                setIsLoading(false)
            }
        )
    }

    const onAvailabilitySelected = (a) => {
        date = a.startDate.getFullYear() + '-' + pad(a.startDate.getMonth()+1, true) + '-' + pad(a.startDate.getDate(), true)
        startTime = pad(a.startDate.getHours(), true) + ':' + pad(a.startDate.getMinutes(), false)
        endTime = pad(a.endDate.getHours(), true) + ':' + pad(a.endDate.getMinutes(), false)
        bookingData.start_time = startTime 
        bookingData.end_time = endTime
        bookingData.date = date
        
        //set booking
        createEvent(bookingData)
    }
    
    const onChangedCalRange = (r) => {
        const date = r.start.getFullYear() + '-' + pad(r.start.getMonth()+1) + '-' + pad(r.start.getDate())
        setFromDate(date)
    }

    const blockOutPeriods = [
        // [0 * msInHour, 9 * msInHour],
        // [19 * msInHour, 24 * msInHour],
    ];
  
    const providerTimeZone = 'Europe/London';

    const restart = () => {
        setConfirmation('')
        setFromDate(now)
    }
    
    return (
        <div>
            {isLoading && <LoadingSpinner/> }
            {confirmation == 'booking slot is already taken' &&
                <p>{confirmation} <button onClick={(e)=>setConfirmation('')}>Retry</button></p>
            }
            {confirmation != '' &&
                <p>{confirmation} <button onClick={(e)=>restart()}>Book Again</button></p>
            }
            {confirmation == '' &&
                <AvailabilityCalendar
                    bookings={bookings}
                    providerTimeZone={providerTimeZone}
                    moment={moment}
                    initialDate={fromDate}
                    onAvailabilitySelected={onAvailabilitySelected}
                    onCalRangeChange={onChangedCalRange}
                    blockOutPeriods={blockOutPeriods}
                    slotLengthMs={makeDuration(getParameterByName('duration'))}
                    slotStepMs={makeDuration(getParameterByName('step')/60)}
                />
            }
        </div>
    );
};
 
export default HarnessCalendar;