import React, {FC, useState, useRef, useEffect, ChangeEvent} from 'react';

interface PlaceModel {
    description: string
    place_id: string
}

interface PlaceProps {
    setAddressText: (a: string, b: string)=>void
    data: PlaceModel
}

const Place: FC<PlaceProps> = (props) => {
    return (
        <div className="list-group-item list-group-item-action" onClick={()=>props.setAddressText(props.data.description, props.data.place_id)}>
            {props.data.description}
        </div>
    )
}

interface AddressListProps {
    setAddressText: (a: string, b: string)=>void
    data: PlaceModel[]
}

const AddressList: FC<AddressListProps> = (props) => {
    if( props.data.length > 0) {
        return (
            <div className="list-group position-absolute w-100 shadow-sm">
                {props.data.map((place)=><Place setAddressText={props.setAddressText} data={place} key={place.place_id} />)}
            </div>
        )
    } else {
        return <span></span>
    }
}

export default AddressList