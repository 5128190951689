import React, {FC, ChangeEvent} from 'react';
import FormLabel from './FormLabel';
import FormRow from './FormRow'
import TextInput from './TextInput'

interface TextInputRowProps{
    label: string
    defaultValue: string | number
    field: string
    onFieldChange: (arg1: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, arg2: string) => void
    inputGroup: boolean
    buttonAction: ()=> void
    buttonText?: string,
    type?: string
    description?: string
}

const TextInputRow: FC<TextInputRowProps> = (props) => {

    const type = (props.type === undefined)? 'text' : props.type

    return (
        <FormRow>
            <FormLabel label={props.label} />
            <TextInput type={type} inputGroup={props.inputGroup} defaultValue={props.defaultValue} onFieldChange={props.onFieldChange} field={props.field} buttonAction={props.buttonAction} buttonText={props.buttonText} />
            { props.description !== undefined && <div className="form-text">{props.description}</div> }
        </FormRow>
    )
}

export default TextInputRow