import React, {FC, useState, useRef, useEffect, useContext} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../services/data.service";
import DataServiceBrand from '../services/DataServiceBrand';
import LoadingSpinner from './global/LoadingSpinner';
import AddBrand from './brand/AddBrand';
import EditBrand from './brand/EditBrand';
import Modal from './Modal';
import BrandOrganisations from './brand/BrandOrganisations';
import { ModalContext } from "../contexts/modalContext";
import BrandModel from '../interfaces/BrandModel';

import ButtonUi from './elements/Button';
import { Box, Toolbar } from '@mui/material';

import { Alert, AlertTitle } from '@mui/material';


interface BrandsProps {
    showError: (a: string)=>void
}

const Brands:FC<BrandsProps> = (props) => {
    let { handleModal } = React.useContext<any>(ModalContext)

    const brandData = {
        id: '',
        name: '',
        display_name: '',
        branding: {
            colors: {
                page_background: '',
                primary: ''
            },
            logo_url: ''
        },
        metadata: {
            owner: ''
        }
    }

    const isMounted = useRef(false)
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [brand, setBrand] = useState<BrandModel>(brandData)
    const [deleteId, setDeleteId] = useState('')
    const [reloadBrands, setReloadBrands] = useState(0)
    const [editBrand, setEditBrand] = useState(false)
    const [addBrandMessage, setAddBrandMessage] = useState('')
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();

      
    const openModal = () => setShowModal(true)
    const closeModal = () => {
        setShowModal(false)
        setReloadBrands(reloadBrands+1)
    }
    const openEditBrand = () => setEditBrand(true)
    const closeEditBrand = () => setEditBrand(false)

    useEffect(function effectFunction() {

        async function fetchUserBrand() {
            setIsLoading(true)
            const token = await getAccessTokenSilently()
            const response = DataServiceBrand.getUserBrands(user?user.sub:'', token)
            console.log(user?user.sub:'');
            console.log(token);
            try {
                const brandResponse = await response
                console.log(brandResponse);
                if( Object.keys(brandResponse.data).length == 0 ) {
                    setBrand(brandData)
                } else {
                    setBrand(brandResponse.data)
                }
                setIsLoading(false)
                
            } catch(e) {
                setIsLoading(false)
            }
        }
        fetchUserBrand();

    }, [reloadBrands])

    useEffect(function effectFunction() {

        async function deleteBrand() {
            const token = await getAccessTokenSilently()
            setIsLoading(true)
            const response = DataServiceBrand.deleteBrand(deleteId, token)
            try {
                const brandResponse = await response
                setDeleteId('')
                setReloadBrands(reloadBrands+1)
                setBrand(brandData)
                setIsLoading(false)
                
            } catch(e) {
                props.showError('error')
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            if( deleteId !== '') {
                deleteBrand()
            }
        } else {
            isMounted.current = true;
        }

    }, [deleteId])

    return (
        <div className="container">
            <Toolbar />
            <div className="brand">
                {isLoading && <LoadingSpinner/> }
                {brand.id !== '' &&
                    <div className="p-4 bg-white">
                        <p>Your Brand:</p>
                        <div className="row">
                            <div className="col-2">
                                <img src={brand.branding.logo_url} className="mw-100" />
                            </div>
                            <div className="col-10">
                                <h2>{brand.display_name}</h2>

                                <Box sx={{ '& button': { mr: 1 } }}>

                                    <ButtonUi label={'Edit Brand'} type={'primary'} action={()=>handleModal(
                                            <EditBrand brand={brand} closeModal={closeEditBrand} reloadBrands={reloadBrands} setReloadBrands={setReloadBrands} />, 
                                            'Edit Brand'
                                        )}  />
                                    <ButtonUi label={'Delete'} type={'error'} action={()=>setDeleteId(brand.name)}  />
                                </Box>
                            </div>
                        </div>
                    </div>
                }
                {brand.id === '' &&
                    <div className="p-4 bg-white">
                        <p>You don't currently have a brand attached to your account.</p>
                        <ButtonUi label={'Add Brand'} type={'primary'} action={openModal}  />
                    </div>
                }
                {showModal &&
                    <Modal modalAction={closeModal} title="Add Brand">
                        <AddBrand closeModal={closeModal} setMessage={setAddBrandMessage} />
                    </Modal>
                }
            </div>

            { addBrandMessage !== '' && 
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    {addBrandMessage}
                </Alert>
            }

            {brand.id !== '' &&
                <BrandOrganisations brandId={brand.name} />
            }


        </div>
    )
}

export default withAuthenticationRequired(Brands, {
    onRedirecting: () => <LoadingSpinner />,
});