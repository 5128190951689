import React, {FC, useState, ChangeEvent, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataServiceBrand from "../../services/DataServiceBrand";
import LoadingSpinner from '../global/LoadingSpinner';
import InlineError from '../elements/InlineError';

interface UploadIconProps {
    iconUrl: string
    setIconUrl: (url: string)=>void
}

const UploadIcon:FC<UploadIconProps> = (props) => {

    const [file, setFile] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState('')
    const [uploadProgress, setUploadProgress] = useState(10)
    
    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();

    const handleSubmit = async (file: File ) => {
        setIsLoading(true)

        var formData = new FormData();

        formData.append(
            "icon_file",
            file,
            file.name
        );

        const token = await getAccessTokenSilently();
        const headers={'Content-Type': file.type, Authorization: `Bearer ${token}`}
        const response = DataServiceBrand.uploadBrandIcon(formData, headers)
        
        try {
            const iconResponse = await response
            setUploadProgress(100)
            setIsLoading(false)
            props.setIconUrl(iconResponse.data)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                // setError(e.response.data.detail[0].msg)
            } else {
                // setError(e.message)
            }
        }
    
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement> ) => {

        let files: any = event.target.files; 

        setUploadFile(files[0])
        handleSubmit(files[0])
    }

    const resetImage = () => {
        props.setIconUrl('')
    }

    return (
        <div>
            <label htmlFor="iconUpload" className="form-label">Upload Icon:</label>
            <div className="bg-light p-5 mb-3">
                {isLoading && 
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{width: uploadProgress + '%'}}></div>
                    </div>
                }
                {props.iconUrl == '' &&
                    <div>
                        <input type="file" name="iconUpload" onChange={handleChange} />
                    </div>
                }
                {props.iconUrl != '' &&
                    <div className="w-100">
                        <img src={props.iconUrl} className="w-50 mx-auto d-block" />
                        <button className="btn btn-link d-block mx-auto w-50" onClick={(e)=>resetImage()}>Remove</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default UploadIcon