import React, {useState, ChangeEvent, useContext, useEffect, FC, ReactNode} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ModalContext } from "../../contexts/modalContext";
import DataServiceAttribute from '../../services/DataServiceAttribute';
import Button from '../elements/Button';
import InlineError from '../elements/InlineError';
import TextInputRow from '../formfields/TextInputRow';
import LoadingSpinner from '../global/LoadingSpinner';
import CategoryModel from '../../interfaces/CategoryModel';
import SelectCategories from '../organisationDetails/SelectCategories';
import DataServiceNta from '../../services/DataServiceNta';
import {AddNtaModel} from '../../interfaces/NtaModels'
import FormRow from '../formfields/FormRow';
import FormLabel from '../formfields/FormLabel';
import SelectParentCategories from './SelectParentCategories';

interface AddNtaProps {
    update: number
    setUpdate: (a: number) => void
    types: string[]
    operators: string[]
    fieldDesc: any
}

const AddNta:FC<AddNtaProps> = (props) => {

    const [attrName, setAttrName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const { getAccessTokenSilently } = useAuth0();
    let { handleModal } = useContext<any>(ModalContext)
    const [allCats, setAllCats] = useState<CategoryModel[]>([])
    const [formData, setFormData] = useState<AddNtaModel>({
        display_name: '',
        operator: '=',
        category_set: [],
        type: 'bool',
        description: '', 
        deletable: 'N'
    })
    const [deletable, setDeletable] = useState(false)

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setIsLoading(true)
        const token = await getAccessTokenSilently();
        const response = DataServiceNta.addNta(formData, token)
        try {
            const brandResponse = await response
            props.setUpdate(props.update + 1)
            handleModal()
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    }

    const checkDeletable = () => {

    }

    const changeDeletable = (selected: boolean | ((prevState: boolean) => boolean), e: React.ChangeEvent<HTMLInputElement>) => {
        setDeletable(selected)
        const value = selected? 'Y' : 'N'
        setFormData(values => ({...values, ['deletable']: value}))

    }

    const setCategories = (cats: number[]) => {

        console.log(cats)

        // get cat list
        // const catObjects = allCats.filter((item: CategoryModel)=>cats.indexOf(item.category_id)>0)
        // // find parent IDs
        // const catParents = catObjects.map(item=>item.parent_category_id)

        formData.category_set = cats
    }

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        const name = field;
        const value = event.target.value;
        setFormData(values => ({...values, [name]: value}))
    }

    return (
        <div className="position-relative">
            {isLoading && <LoadingSpinner />}
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            <TextInputRow label="Display name" defaultValue={formData.display_name} onFieldChange={onFieldChange} field="display_name" inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label="Description" defaultValue={formData.description} onFieldChange={onFieldChange} field="description" inputGroup={false} buttonAction={()=>null} />
            <div className="row">
                <div className='col col-6'>
                    <FormRow>
                        <FormLabel label={'Operator'} />
                        <select className='form-select' onChange={(e)=>onFieldChange(e, 'operator')}>
                            {props.operators.map(item=>(<option key={item}>{item}</option>))}
                        </select>
                        <div className="form-text">{props.fieldDesc.operators}</div>
                    </FormRow>
                </div>
                <div className='col col-6'>
                    <FormRow>
                        <FormLabel label={'Type'} />
                        <select className='form-select' onChange={(e)=>onFieldChange(e, 'type')}>
                            {props.types.map(item=>(<option key={item}>{item}</option>))}
                        </select>
                        <div className="form-text">{props.fieldDesc.types}</div>
                    </FormRow>
                </div>
            </div>
            <SelectParentCategories 
                setAllCats={setAllCats} 
                categories={formData.category_set} 
                setCategories={setCategories} 
                attributes={[]} 
                setAttributes={()=>null} 
                ntas={[]} 
                setNtas={()=>null}
                showattrs={false}
                setWhatChanged={()=>null} />
            <FormRow>
                <FormLabel label={'Is Deletable?'} for={'deletable'} />
                <input 
                    className="form-check-input ms-2" 
                    type="checkbox" 
                    value='deletable'
                    id="deletable"
                    checked={deletable}
                    onChange={(e)=>changeDeletable(!deletable, e)} />
            </FormRow>

            <Button label={'Add NTA'} type={'primary'} action={handleSubmit} />
        </div>
    )
}
export default AddNta