import React, {useState, ChangeEvent, useContext, useEffect, FC, ReactNode} from 'react';
import DataServiceAttribute from '../../services/DataServiceAttribute';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import Pagination from '../Pagination';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import Popover from '../global/Popover';
import { ModalContextType } from '../../interfaces/ModalContextType'
import { ModalContext } from "../../contexts/modalContext";
import Button from '../elements/Button';
import CategoryModel from '../../interfaces/CategoryModel';
import CategoryItem from './CategoryItem'
import ParentCategoryItem from './ParentCategoryItem'
import ListGroup from '../elements/ListGroup';
import Flex from '../elements/Flex';
import InlineError from '../elements/InlineError';
import { AttributeMain } from '../../interfaces/AttributeModel'
import AttributeItem from './AttributeItem';
import AddAttribute from './AddAttribute';
import EditAttribute from './EditAttribute';
import DataServiceBrand from '../../services/DataServiceBrand';
import CategorySelect from './CategorySelect';
import DataServiceNta from '../../services/DataServiceNta';
import NtaItem from './NtaItem';
import AddNta from './AddNta';
import { NtaModel } from '../../interfaces/NtaModels';
import { Toolbar } from '@mui/material';

interface ManageNtaProps {
}

const ManageNtas:FC<ManageNtaProps> = () => {
    let { handleModal } = useContext<any>(ModalContext)
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false)
    const [update, setUpdate] = useState(0)
    const [deleteItem, setDeleteItem] = useState<NtaModel | undefined>()
    const [deleteText, setDeleteText] = useState('')
    const [deleteResponseText, setDeleteResponseText] = useState('')
    const [filterState, setFilterState] = useState('ALL')
    const [error, setError] = useState('')
    const [attrList, setAttrList] = useState<NtaModel[]>([])
    const [loadedAttrList, setLoadedAttrList] = useState<NtaModel[]>([])
    const [filteredAttrList, setFilteredAttrList] = useState<NtaModel[]>([])
    const [catFilterId, setCatFilterId] = useState(0)
    const [catList, setCatList] = useState<CategoryModel[]>([])
    const [fieldDesc, setFieldDesc] = useState({})

    const [typesList, setTypesList] = useState([])
    const [operatorList, setOperatorList] = useState([])

    useEffect(()=> {
        let isMounted = true
        if(isMounted) {
            async function fetchTypes() {
                setIsLoading(true)
                const token = await getAccessTokenSilently()
                const response = DataServiceNta.getTypes(token)

                try {
                    const attrResponse = await response
                    setIsLoading(false)
                    setTypesList(attrResponse.data.types)
                    setFieldDesc(values => ({...values, ['types']: attrResponse.data.display_text}))
                    setError('')
                    
                } catch(e) {
                    setIsLoading(false)
                }
            }
            async function fetchOperators() {
                setIsLoading(true)
                const token = await getAccessTokenSilently()
                const response = DataServiceNta.getOperators(token)

                try {
                    const attrResponse = await response
                    setIsLoading(false)
                    setOperatorList(attrResponse.data.operators)
                    setFieldDesc(values => ({...values, ['operators']: attrResponse.data.display_text}))
                    setError('')
                    
                } catch(e) {
                    setIsLoading(false)
                }
            }

            fetchTypes()
            fetchOperators()
        }
        return () => { isMounted = false }

    }, [])

    useEffect(()=> {
        let isMounted = true
        if(isMounted) {
            async function fetchAttrs() {
                setIsLoading(true)
                const token = await getAccessTokenSilently()
                const response = DataServiceNta.getCategoryNta([catFilterId],token)

                try {
                    const attrResponse = await response
                    setIsLoading(false)
                    setAttrList(attrResponse.data)
                    setLoadedAttrList(attrResponse.data)
                    setError('')
                    
                } catch(e) {
                    setIsLoading(false)
                }
            }
            fetchAttrs()
        }
        return () => { isMounted = false }

    }, [update, catFilterId])

    useEffect(()=> {

        if( deleteItem !== undefined) {
            async function deleteNta() {
                setIsLoading(true)
                const token = await getAccessTokenSilently();
                const response = DataServiceNta.deleteNta(deleteItem?.nta_encoded, deleteText, token)
                try {
                    const orgsResponse = await response
                    setDeleteResponseText(orgsResponse.data)
                    setDeleteText('')
                    setUpdate(update + Math.floor((Math.random() * 10) + 1))
                    setIsLoading(false)
                    
                } catch(e:any ) {
                    setError(e.response.data.errors[0])
                    setIsLoading(false)
                }
            }
            deleteNta()
        }
    }, [deleteItem])

    const setDelete = (deleteItem: NtaModel) => {
        setDeleteItem(deleteItem)
    }

    const confirmDelete = (deleteItem: NtaModel, confirmText: string) => {
        setDeleteText(confirmText)
        setDeleteItem(deleteItem)

    }

    const filterCats = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase()
        if( value === '') {
            setFilterState('ALL')
        } else {
            setFilterState('FILTER')
        }
        setFilteredAttrList(loadedAttrList.filter(item => item.display_name.toLowerCase().includes(value)))
    }

    return(
        <div>
            <Toolbar />
            <Flex justify='justify-content-between' align='align-items-center'>
                <h1>Manage NTAs</h1>
                <Button label={'Add NTA'} type={'primary'} action={
                    ()=>handleModal(
                        <AddNta update={update} setUpdate={setUpdate} types={typesList} operators={operatorList} fieldDesc={fieldDesc} />
                        , 
                        'Add NTA',
                        true
                    )} />
                
            </Flex>
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            {isLoading && <LoadingSpinner/>}
            <Flex justify='' align=''>
                <div className="form-floating mb-3 w-80 flex-fill">
                    <input type="text" className="form-control" id="filter" onChange={(e)=>filterCats(e)} />
                    <label htmlFor="filter">Search for NTA</label>
                </div>
                
                <CategorySelect setCatList={setCatList} setCatFilterId={setCatFilterId} />
                
            </Flex>
            <ListGroup>
                {attrList.map(item=>(<NtaItem 
                    key={item.display_name} 
                    update={update} 
                    setUpdate={setUpdate} 
                    data={item} 
                    setDeleteItem={setDelete} 
                    setConfirmDelete={confirmDelete} 
                    deleteText={deleteResponseText}
                    types={typesList}
                    operators={operatorList}
                    fieldDesc={fieldDesc}
                    catList={catList} />))}
            </ListGroup>
            {filterState === 'FILTER' &&
                <Pagination setItems={setAttrList} originalItems={loadedAttrList} filteredItems={filteredAttrList} />
            }
        </div>
    )
}

export default ManageNtas