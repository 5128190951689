import React, {useState, ChangeEvent, useContext, useEffect, FC} from 'react';
import DataService from "../../services/data.service";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import Modal from '../Modal';
import Pagination from '../Pagination';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import Popover from '../global/Popover';
import { ModalContextType } from '../../interfaces/ModalContextType'
import { ModalContext } from "../../contexts/modalContext";
import Button from '../elements/Button';
import CategoryModel from '../../interfaces/CategoryModel';
import Flex from '../elements/Flex';
import CategoryItem from './CategoryItem'

interface CategoryProps {
    update: number
    setUpdate: (a:number)=>void
    data: CategoryModel
    setDeleteItem: (a: CategoryModel)=>void
    childCats: CategoryModel[]
}

const ParentCategoryItem:FC<CategoryProps> = (props) => {
    let { handleModal } = React.useContext<any>(ModalContext)

    const [confirmDelete, setConfirmDelete] = useState(false)
    const deleteItem = () =>{
        setConfirmDelete(false)
        props.setDeleteItem(props.data)
    }
    const [childCats, setChildCats] = useState<CategoryModel[]>([])

    const openEditModal = () => {
        handleModal(
            <EditCategory update={props.update} data={props.data} setUpdate={props.setUpdate} />
            , 
            'Edit Category'
        )
    }

    const showChildren = () => {
        if( childCats.length > 0 ) {
            setChildCats([] )
        } else {
            setChildCats(props.childCats.filter(item => item.parent_category_id == props.data.category_id) )
        }
    }

    useEffect(() => {
        let isMounted = true;  

        if(isMounted) { 
            setChildCats([] )
        }
        return () => { isMounted = false }
    },[props.update])

    const viewHideText = (): string => {
        if (childCats.length === 0) return 'View'
        return 'Hide'
    }

    return (
        <div className="list-group-item list-group-item-action" aria-current="true">
            <Flex justify={'justify-content-between'} align={''}>
                <div className={'list-group-target flex-fill d-flex'} onClick={()=>showChildren()}>
                    { props.data.icon_url !== '' && 
                        <img src={props.data.icon_url} className="me-3" style={{width: '30px', height: '30px'}} />
                    }
                    <div className="mb-0 mt-1 me-auto flex-fill">
                        <h5 className="position-relative">
                            {props.data.parent_category_id == 0 && <strong>{props.data.category_name}</strong> }
                            <span className="hoverLabel">{viewHideText()} Search Categories</span>
                        </h5>
                    </div>
                </div>
                <div className="d-inline" style={{position: 'relative'}}>
                    <Button label={'Edit Category'} type={'link'} action={openEditModal} />
                </div>
                <div className="d-inline" style={{position: 'relative'}}>
                    {confirmDelete && <Popover popWidth={200} closePop={setConfirmDelete} confirmPop={deleteItem} message={"Are you sure you want to delete " + props.data.category_name} />}
                    <Button label={'Delete Category'} type={'link error'} action={(e)=>setConfirmDelete(true)} />
                </div>
            </Flex>
            <div className="childcatlist">
                {childCats.map(item=><CategoryItem key={item.category_id} filter={false} update={props.update} setUpdate={props.setUpdate} data={item} setDeleteItem={props.setDeleteItem} parentCats={[]} />)}
            </div>
        </div>
    )
}

export default ParentCategoryItem