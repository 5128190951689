import React, {Component, useState, ChangeEvent, useRef, FC, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import DataService from "../../services/data.service";
import DataServiceCategory from '../../services/DataServiceCategory';
import CategoryModel from '../../interfaces/CategoryModel'
import { NTAModel } from '../../interfaces/ProductModel';
import WhatChangedModel from '../../interfaces/WhatChangedModel';

interface ParentCatCheckProps {
    selectedCats: number[];
    data: CategoryModel;
    setCat: (a: number)=> void;
}

const ParentCategoryCheck:FC<ParentCatCheckProps> = (props) => {

    const [selected, setSelected] = useState((props.selectedCats.filter(item => item == props.data.category_id).length > 0 ))

    const onSelect = (selected: boolean | ((prevState: boolean) => boolean), e: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(selected)
        props.setCat(parseInt(e.target.value))
    }

    return(
        <div className="col-3 mb-1">
            <div className={'form-check d-flex align-items-center form-switch'}>
            <input 
                    className="form-check-input" 
                    name='child'
                    type='checkbox'
                    role='switch'
                    id={props.data.category_id.toString()} 
                    value={props.data.category_id} 
                    checked={selected}
                    onChange={(e)=>onSelect(!selected, e)}
                />
                <label className="form-check-label d-flex align-items-center" htmlFor={props.data.category_id.toString()}>    
                    <img src={props.data.icon_url} style={{maxHeight:"25px", marginLeft:"5px", marginRight:"5px"}} />
                    <span>{props.data.category_name}</span>
                </label>
            </div>
        </div>
    )
}

interface ParentCatProps {
    setCat: (a: number)=> void
    parentId: number
    parentCatList: CategoryModel[]
    selectedCats: number[]
}

const ParentCats:FC<ParentCatProps> = (props) => {
    if( props.parentCatList.length > 0) {
        return <div className="row mb-3 align-items-center">{props.parentCatList.map((category)=><ParentCategoryCheck setCat={props.setCat} key={category.category_id} data={category} selectedCats={props.selectedCats} />)}</div>
    } else {
        return <p>Nothing found</p>
    }
}

interface SelectProps {
    
    setAllCats: (a: CategoryModel[])=>void;
    categories: number[]
    setCategories: (a: number[])=> void 
    attributes: string[] 
    setAttributes: (a: string[])=> void 
    ntas: NTAModel[]
    setNtas: (a: NTAModel[])=> void
    showattrs: boolean
    setWhatChanged: (a: WhatChangedModel)=>void
}

const SelectParentCategories: FC<SelectProps> = (props) => {
    
    const [catList, setCatList] = useState<CategoryModel[]>([])
    const [parentCatList, setParentCatList] = useState<CategoryModel[]>([])
    const [parentId, setParentId] = useState<number>(0)
    const [isLoading, setIsLoading] = useState(false)
    const [catChange, setCatChange] = useState(0)

    useEffect(function effectFunction() {
        let isMounted = true;       
        async function fetchCats() {
            if (isMounted) setIsLoading(true)
            const response = DataServiceCategory.getCatList()
            try {
                const dataResponse = await response
                if (isMounted) setParentCatList(dataResponse.data.search_categories.filter((item: CategoryModel) => item.parent_category_id == 0))
                if (isMounted) setIsLoading(false)
                
            } catch(e) {
                if (isMounted) setIsLoading(false)
            }
        }
        fetchCats()
        return () => { isMounted = false }

    }, [])

    const addCategory = (catId: number) => {
        let selectedCats = props.categories
        let change = 'ADD'
        setCatChange(catChange+1)
        if( selectedCats.includes(catId) ) {
            selectedCats.splice(selectedCats.indexOf(catId), 1)
            change = 'REMOVE'
        } else {
            selectedCats.push(catId)
        }
        props.setWhatChanged({
            change: change,
            id: catId
        })
        props.setCategories(selectedCats)
    } 


    if( isLoading ) return <LoadingSpinner />
    return (
        <div className="row mb-3 align-items-center">
            <h3>Select Parent Categories</h3>
            <ParentCats setCat={addCategory} parentId={parentId} parentCatList={parentCatList} selectedCats={props.categories} />
        </div>
    )
}

export default SelectParentCategories