import React, {useState, useRef, useImperativeHandle} from 'react';

const ScheduleItem = (props) => {

    const dayOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const [unavailable, setUnavailable] = useState((props.day.open == props.day.close))

    const openElement = useRef();
    const closeElement = useRef();

    const onUnavailableChange = (unavailable) => {
        setUnavailable(unavailable)
        props.onUnavailableChange(props.day.day, unavailable)
        openElement.current.value = '00:00'
        closeElement.current.value = '00:00'
    }

    return(
        <div className="organisation-schedule-item">
            <h5 className="card-subtitle mb-2 text-muted">{dayOfWeek[props.day.day]}</h5>
            <div className="mb-3">
                <label className="form-label">Open</label>
                <input className="form-control" ref={openElement} readOnly={unavailable} type="time" defaultValue={props.day.open} onChange={(e) => props.onTimeChange(0, props.day.day, e)} />
            </div>
            <div className="mb-3">
                <label className="form-label">Close</label>
                <input className="form-control" ref={closeElement} readOnly={unavailable} type="time" defaultValue={props.day.close} onChange={(e) => props.onTimeChange(1, props.day.day, e)} />
            </div>
            <div className="mb-3">
                <div className="form-check">
                    <label className="form-check-label" htmlFor={'unavailable' + props.index}>{props.unavailableLabel}</label>
                    <input 
                        className="form-check-input"
                        id={'unavailable' + props.index}
                        type="checkbox" 
                        value="unavailable"
                        checked={unavailable}
                        onChange={()=>onUnavailableChange(!unavailable)} />
                </div>
            </div>
        </div>
    )
}

export default ScheduleItem