import React, {FC, MouseEvent, useState, useContext, useRef, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import DataServiceOrganisation from '../../services/DataServiceOrganisation';
import Resources from './Resources';
import LoadingSpinner from '../global/LoadingSpinner';
import Modal from '../Modal';
import EditOrganisation from './EditOrganisation';
import Products from './Products';
import LinkProductsResources from './LinkProductsResources'
import Button from '../elements/Button';
import { ModalContext } from "../../contexts/modalContext";
import OrganisationModel, { MessageData } from '../../interfaces/OrganisationModel';
import ButtonUi from '../elements/Button';

import { Alert, AlertTitle, Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import ProblemEventModel from '../../interfaces/ProblemEventModel';
// import ProblemEvents from './ProblemEvents';

import DetailsPanel from './DetailsPanel';
import { OrganisationContext, OrganisationProvider } from '../../contexts/OrganisationContext';
import OrganisationSidebar from './OrganisationSidebar';
import OrganisationComponents from './OrganisationComponents';
import ProblemEvents from './ProblemEvents';
import Pusher, { Channel } from 'pusher-js';
import Chat, { INITIATE_CHAT_EVENT } from './Chat';

function getID() {
    const path = window.location.pathname
    return path.split("/")[3]
}

interface OrganistionProps {
    children: React.ReactNode
}

const OrganisationDetails:FC<OrganistionProps> = (props) => {
    const isMounted = useRef(false)

    const { getAccessTokenSilently } = useAuth0()

    const params = useParams()
    const [id, setId] = useState(params.id)
    const [orgData, setOrgData] = useContext(OrganisationContext)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [reloadProblemEvents, setReloadProblemEvents] = useState(0)
    const [problemEvents, setProblemEvents] = useState<ProblemEventModel[]>([])
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [channelName, setChannelName] = useState('');
    const [isConnecting, setIsConnecting] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    var pusher = new Pusher('820a2d6b69737cf34b3d', {
        cluster: 'eu'
    });

    useEffect( () => {
        
        // Enable pusher logging - don't include this in production
        Pusher.logToConsole = true;
        
        
        var channel = pusher.subscribe(getID());
        channel.bind(INITIATE_CHAT_EVENT, function(data: MessageData) {
        
            setUsername(data.username);
            setIsConnecting(true);
            
        });

        
        
    }, [])



    useEffect( () => {
        
        async function deleteOrg() {
            setIsLoading(true)

            const token = await getAccessTokenSilently()
            const response = DataServiceOrganisation.deleteOrg(deleteId, token)
            try {
                const orgsResponse = await response
                setDeleteId('')
                window.location.href = window.location.origin + '/brand'

            } catch(e) {
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            deleteOrg()
        } else {
            isMounted.current = true;
        }

    }, [deleteId])

    useEffect( () => {
        async function fetchProblemEvents() {
            setIsLoading(true)

            const token = await getAccessTokenSilently()
            const response = DataServiceOrganisation.getProblemEvents(token, id)
            try {
                const orgsResponse = await response
                setProblemEvents(orgsResponse.data)

            } catch(e) {
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            fetchProblemEvents()
        } else {
            isMounted.current = true;
        }
        
    }, [reloadProblemEvents])

    const backToBrand = () => {
        window.location.href = "/brand"
    }

    const findComponent = () => {
        const object = OrganisationComponents.find(comp => comp.id === params.page)
        if( object !== undefined && object.component !== undefined ) {
            return object.component
        }
        return DetailsPanel
    }
    
    const PageComponent = findComponent()
    
    type Anchor = 'top' | 'left' | 'bottom' | 'right';

    const [problemEventsOpen, setProblemEventsOpen] = useState(false)
    const [chatOpen, setChatOpen] = useState(false)

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setProblemEventsOpen(false);
            };

        const toggleChatDrawer =
            (anchor: Anchor, open: boolean) =>
                (event: React.KeyboardEvent | React.MouseEvent) => {
                    if (
                        event.type === 'keydown' &&
                        ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                    ) {
                        return;
                    }
    
                    setChatOpen(false);
                };

    return (
        <div>
            {/* {isLoading && <LoadingSpinner />} */}
            <OrganisationProvider>
                <OrganisationSidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3, marginLeft: '260px' }}>
                    <Toolbar />



                    {problemEvents.length > 0 &&
                        <Alert severity="error" sx={{ marginBottom: '20px'}}>
                            <AlertTitle>You have {problemEvents.length} problem events</AlertTitle>
                            Resolve them <button onClick={()=>setProblemEventsOpen(true)}><strong>here</strong></button>

                            <Drawer
                                anchor={'right'}
                                open={problemEventsOpen}
                                onClose={toggleDrawer('right', problemEventsOpen)}
                                sx={{padding: '20px'}}
                                >
                                    <Toolbar />
                                    <ProblemEvents setReloadProblemEvents={setReloadProblemEvents} problemEvents={problemEvents} />
                            </Drawer>
                        </Alert>
                    }

                    {username !== '' &&
                        <Alert severity="info" sx={{ marginBottom: '20px'}}>
                            <AlertTitle>New message from {username}!</AlertTitle>
                            <button onClick={()=>{setChatOpen(true)}}><strong>Accept</strong></button>

                            <Drawer
                                anchor={'right'}
                                open={chatOpen}
                                onClose={toggleChatDrawer('right', chatOpen)}
                                sx={{padding: '20px'}}
                                >
                                    <Toolbar />
                                    <Chat pusher={pusher} setChannelName={setChannelName} channelName={channelName} isConnecting={isConnecting} setIsConnecting={setIsConnecting} isSubscribed={isSubscribed} setIsSubscribed={setIsSubscribed} username={username} />

                            </Drawer>
                        </Alert>
                    }

                    {props.children}

                    <PageComponent/>


                </Box>

            </OrganisationProvider>
        </div>
    )
}

export default withAuthenticationRequired(OrganisationDetails, {
    onRedirecting: () => <LoadingSpinner />,
});