import axios from 'axios';
import { getConfig } from "../config";

const { apiOrigin = "https://suite.develop.nonprodsvc.com/v1", audience } = getConfig();

class DataServiceClient {

    createEvent(data: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/client/create-event`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }, 
            data: data
        });
    }

    deleteEvent(id: string) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/client/delete-event?event_id=${id}`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }, 
        });
    }
}
export default new DataServiceClient();