import React, {FC, useState, useEffect} from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Pagination } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface PaginationProps {
    setItems: (a: []) => void
    originalItems: any[]
    filteredItems: any[]
}

const UiPagination: FC<PaginationProps> = (props) => {
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([1])
    const [displayCount, setDisplayCount] = useState('20')

    const setPageItems = () => {
        const count = parseInt(displayCount)
        const from = ((count*page)-count)
        const to = (count*page)
        const orgs = (props.filteredItems.length > 0)?props.filteredItems:props.originalItems
        props.setItems( orgs.slice(from, to) as never )
    }

    const changeDisplayCount = (event:  React.ChangeEvent<{ value: string }> | SelectChangeEvent) => {
        setDisplayCount(event.target.value)
    }

    useEffect(()=> {
        let isMounted = true;  

        if(isMounted) { 
            setPageItems()
            const orgsLength = (props.filteredItems.length > 0)?props.filteredItems.length:props.originalItems.length
            const pageCount = Math.ceil(orgsLength/parseInt(displayCount))
            const pages = []
            for (let i = 0; i < pageCount; i++) {
                pages.push(i+1)
            } 
            setPages(pages)
            setPage(1)
        }
        
        return () => { isMounted = false }
    },[props.originalItems, props.filteredItems, displayCount])

    useEffect(()=> {
        let isMounted = true;  
        if(isMounted) setPageItems()
        return () => { isMounted = false }
    },[page])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    return(
        <nav aria-label="Page navigation" className="my-4 d-flex justify-content-between align-items-center">
            <Pagination count={pages.length} color="primary" onChange={handleChange} />

            <FormControl sx={{ m: 1, width: 150, flexShrink: 0 }}>
                <Select
                    labelId="demo-simple-select-disabled-label"
                    id="demo-simple-select-disabled"
                    value={displayCount}
                    label="Results Per Page"
                    onChange={(e)=>changeDisplayCount(e)}
                    >
                    <MenuItem value={'20'}>20</MenuItem>
                    <MenuItem value={'30'}>50</MenuItem>
                    <MenuItem value={'100'}>100</MenuItem>
                </Select>
                <FormHelperText>Results Per Page</FormHelperText>
            </FormControl>

        </nav>
    )
}

export default UiPagination