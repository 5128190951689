import React, {useState, FC, ChangeEvent, useEffect} from 'react';
import TextInput from '../formfields/TextInput';

interface PopoverProps {
    popWidth: number
    message: string
    confirmPop: (a?: string)=>void
    closePop: (a: boolean)=>void
    showInput?: boolean
}

const Popover:FC<PopoverProps> = (props) => {
    const halfWidth = (props.popWidth/2)+'px'
    const style = {
        width: props.popWidth, 
        left: '50%', 
        bottom: '100%', 
        top: 'auto',
        transform: `translate(-${halfWidth})`
    }

    const [inputVal, setInputVal] = useState('')

    const inputOnChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        setInputVal(event.target.value)
    }

    return (
        <div className="popover bs-popover-top" role="tooltip" style={style}>
            <div className="popover-arrow" style={{position: 'absolute', left: '50%', transform: 'translate(-8px)'}}></div>
            <div className="popover-body">
                <p>{props.message}</p>
                
                {props.showInput && <p><TextInput type="text" defaultValue={inputVal} field={''} onFieldChange={inputOnChange} inputGroup={false} buttonAction={()=>null} /></p>}
                <button className="btn btn-primary btn-small me-2" onClick={(e)=>props.confirmPop(inputVal)}>Confirm</button>
                <button className="btn btn-secondary btn-small" onClick={(e)=>props.closePop(false)}>Cancel</button>
            </div>
        </div>

    )
}

export default Popover