import axios from 'axios';
import { getConfig } from "../config";

const { apiOrigin = "https://suite.develop.nonprodsvc.com/v1", audience } = getConfig();

class DataServiceBrand {
    createBrand(body: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/admin/brand-setup`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
            data: body
        });
    }

    uploadBrandIcon(body: any, headers: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/admin/brand-icon`,
            headers: headers, 
            data: body
        });
    }

    getUserBrands(userId: any, token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/admin/user-brands?user_id=${userId}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' },
        })
    }

    deleteBrand(id: any, token: any) {
        return axios({
            method: 'delete',
            url: `${apiOrigin}/admin/delete-brand?brand_name=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' },
        })
    }

    updateBrand(body: any, token: any) {
        return axios({
            method: 'put',
            url: `${apiOrigin}/admin/update-brand`,
            headers: {'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' },
            data: body
        });
    }
}
export default new DataServiceBrand();