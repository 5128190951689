import axios from 'axios';
import { getConfig } from "../config";

const { apiOrigin = "https://suite.develop.nonprodsvc.com/v1", audience } = getConfig();

class DataServiceProduct {
    getProducts(orgId: any, token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/product/organisation-products?org_id=${orgId}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }

    getProductDetail(id: any, token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/product/product-detail?product_id=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }

    addProduct(data: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/product/add-product`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
            data: data
        });
    }

    editProduct(data: any, token: any) {
        return axios({
            method: 'put',
            url: `${apiOrigin}/product/update_product`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
            data: data
        });
    }

    deleteProduct(id: any, token: any) {
        return axios({
            method: 'delete',
            url: `${apiOrigin}/product/delete-product?product_id=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }
}
export default new DataServiceProduct();