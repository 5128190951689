import React, {Component, useState, useRef, useEffect} from 'react';
import DataService from "../../services/data.service";

import ScheduleItem from "./ScheduleItem";
import LoadingSpinner from '../global/LoadingSpinner';
import InlineError from '../elements/InlineError';

const ResourceSchedule = (props) => {

    const [defaults, setDefaults] = useState({})
    const [schedule, setSchedule] = useState([])
    const [update, setUpdate] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [timesSame, setTimesSame] = useState(false)

    let sendUpdate = () => {
        setUpdate(update+1)
    }

    let onTimeChange = (openClose, day, e) => {

        let storedSchedule = schedule
        const editIndex = schedule.findIndex(item => item.day == day)

        if( openClose == 0) storedSchedule[editIndex].open = e.target.value
        if( openClose == 1) storedSchedule[editIndex].close = e.target.value

        if( storedSchedule[editIndex].open == storedSchedule[editIndex].close ) {
            setTimesSame(true) 
        } else {
            setTimesSame(false)
        }

        defaults.schedule = storedSchedule

        setSchedule(storedSchedule)
        setDefaults(defaults)
    }

    let onUnavailableChange = (day, value) => {
        console.log(day, value)
        let storedSchedule = schedule
        const editIndex = schedule.findIndex(item => item.day == day)

        if( value ) {
            storedSchedule[editIndex].open = '00:00'
            storedSchedule[editIndex].close = '00:00'
            setTimesSame(false)
        } else {
            if( storedSchedule[editIndex].open == storedSchedule[editIndex].close ) setTimesSame(true)
        }

        defaults.schedule = storedSchedule

        setSchedule(storedSchedule)
        setDefaults(defaults)
    }

    const resetError = () => setErrorMessage('')

    const isMounted = useRef(false);

    useEffect(function effectFunction() {

        async function sendSchedule() {
            setIsLoading(true)
            const response = DataService.sendResSchedule(defaults)
            resetError()

            try {
                const defaultsResponse = await response
                console.log(defaultsResponse)
                props.makeScheduleUpdate()
                props.setUpdateClashing(props.updateClashing+1)
                setIsLoading(false)
            } catch (e) {
                setIsLoading(false)
                setErrorMessage(e.response.data.errors[0])
            }
        }

        if( isMounted.current ) {
            sendSchedule()
        } else {
            isMounted.current = true;
        }

    }, [update])

    useEffect(function effectFunction() {

        async function fetchDefaults() {
            setIsLoading(true)
            resetError()
            const response = DataService.getResSchedule(props.orgId, props.resId)
            try {
                const defaultsResponse = await response

                setDefaults(defaultsResponse.data)
                setSchedule(defaultsResponse.data.schedule.reverse())
                setIsLoading(false)
            } catch(e) {
                setIsLoading(false)
            }
        }
        fetchDefaults();

    }, [])

    return (
        <div className="organisation-schedule">
            {isLoading && <LoadingSpinner/>}
            {errorMessage != '' &&
                <InlineError errorMessage={errorMessage} />
            }
            {timesSame &&
                <InlineError errorMessage="Times should not be the same" />
            }
            <div className="organisation-schedule-items">
                {schedule.map((defaultItem, index)=><ScheduleItem index={index} unavailableLabel="Unavailable" day={defaultItem} key={defaultItem.day} onTimeChange={onTimeChange} onUnavailableChange={onUnavailableChange} />)}
            </div>
            <button disabled={timesSame} className="btn btn-primary mt-3" onClick={(e) => sendUpdate()}>Update Schedule</button>
        </div>
    )
}

export default ResourceSchedule