const ProductsList = (props) => {
    const onClick = (e, product) => {
        props.setSelectedProduct(product.product_id)
        props.setSelectedProductDuration(product.duration)
        props.setNtaList(product.NTA)
    }
    return props.products.map(product =>
        <button 
            className={props.selectedProduct == product.product_id?'list-group-item active':'list-group-item'} 
            key={product.product_id} 
            onClick={(e) => onClick(e, product)}>
                {product.item}
        </button>)
}

export default ProductsList