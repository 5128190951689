import React, {useState, ChangeEvent, useContext, useEffect, FC, ReactNode} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ModalContext } from "../../contexts/modalContext";
import DataServiceAttribute from '../../services/DataServiceAttribute';
import Button from '../elements/Button';
import InlineError from '../elements/InlineError';
import TextInputRow from '../formfields/TextInputRow';
import LoadingSpinner from '../global/LoadingSpinner';
import { AttributeMain } from '../../interfaces/AttributeModel';
import SelectCategories from '../organisationDetails/SelectCategories';
import PreviousMap from 'postcss/lib/previous-map';
import CategoryModel from '../../interfaces/CategoryModel';
import { ProvidePlugin } from 'webpack';
import WhatChangedModel from '../../interfaces/WhatChangedModel';

interface AddAttrProps {
    update: number
    setUpdate: (a: number) => void
    data: AttributeMain
    catList: CategoryModel[]
}

const EditAttribute:FC<AddAttrProps> = (props) => {

    const [attrName, setAttrName] = useState(props.data.display_name)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const { getAccessTokenSilently } = useAuth0();
    let { handleModal } = useContext<any>(ModalContext)
    const [allCats, setAllCats] = useState<CategoryModel[]>([])
    const [selectedCats, setSelectedCats] = useState<number[]>(props.data.categories)

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setIsLoading(true)
        const token = await getAccessTokenSilently();
        const response = DataServiceAttribute.updateAttribute(attrName, props.data.shortcode, token)
        try {
            const brandResponse = await response
            props.setUpdate(props.update + 1)
            handleModal()
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    }

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        let isMounted = true
        const value = event.target.value;
        if(isMounted) setAttrName(value)
        return () => { isMounted = false }
    }

    const getSelectedCats = (newCats: number[]) => {
        
        // // link unlink attributes

        // // check length to see if its link or unlink
        // const LINK = 'link'
        // const UNLINK = 'unlink'
        // let catID = -1
        // const oldCats = props.data.categories
        
        // const operation = ( newCats.length > oldCats.length )? LINK : UNLINK

        // if( operation == LINK ) {
        //     // find out the extra cat id
        //     // let difference = newCats.filter(x => oldCats.categoryId.indexOf(x) === -1);
        //     console.log(LINK)
        // }

        // if( operation == UNLINK ) {
        //     console.log(UNLINK)
        //     // find out the missing cat id
        // }

        // // compare categories to props.data.categories to find out what changed and get ID
        // const data = {
        //     attribute: props.data.attribute,
        //     category_id: catID,
        //     operation: operation
        // }

        // // if( catID !== -1) {
        // //     const token = await getAccessTokenSilently();
        // //     DataServiceAttribute.linkCategoryAttribute( data, token )
        // // }

    }

    const whatChanged = async (whatChanged: WhatChangedModel) => {
        const operation = (whatChanged.change == 'ADD')?'link':'unlink'

        const data = {
            attribute: props.data.attribute,
            category_id: whatChanged.id,
            operation: operation
        }

        const token = await getAccessTokenSilently();
        DataServiceAttribute.linkCategoryAttribute( data, token )

    }

    return (
        <div className="position-relative">
            {isLoading && <LoadingSpinner />}
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            <TextInputRow label="Attribute name" defaultValue={attrName} onFieldChange={onFieldChange} field="attr_name" inputGroup={false} buttonAction={()=>null} />
            <SelectCategories 
                setAllCats={setAllCats} 
                categories={props.data.categories} 
                setCategories={getSelectedCats} 
                attributes={[]} 
                setAttributes={() => null} 
                ntas={[]} 
                setNtas={() => null} 
                showattrs={false} 
                setWhatChanged={whatChanged} />
            <Button label={'Save Changes'} type={'primary'} action={handleSubmit} />
        </div>
    )
}
export default EditAttribute