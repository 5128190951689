import OrgCalendar from "./Calendar";
import Chat from "./Chat";
import DetailsPanel from "./DetailsPanel";
import LinkProductsResources from "./LinkProductsResources";
import OrgSchedule from "./OrgSchedule";
import Products from "./Products";
import Resources from "./Resources";
import SpecialEvents from "./SpecialEvents";


const OrganisationComponents = [{
    id: 'details',
    component: DetailsPanel
  },
  {
    id: 'resources',
    component: Resources
  },
  {
    id: 'products',
    component: Products
  },
  {
    id: 'link-products-resources',
    component: LinkProductsResources
  },
  {
    id: 'calendar',
    component: OrgCalendar
  },
  {
    id: 'schedule',
    component: OrgSchedule
  },
  {
    id: 'special-events',
    component: SpecialEvents
  },
]

export default OrganisationComponents;