import { Drawer, Toolbar, Box, Typography, Button, Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"
import { FC, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { OrganisationContext, OrganisationProvider } from "../../contexts/OrganisationContext"
import BusinessIcon from '@mui/icons-material/Business';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CategoryIcon from '@mui/icons-material/Category';
import BallotIcon from '@mui/icons-material/Ballot';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventIcon from '@mui/icons-material/Event';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DataServiceOrganisation from "../../services/DataServiceOrganisation";
import getID from "../../helpers/getID";




const OrganisationSidebar:FC = () => {
    const [orgData, setOrgData] = useContext(OrganisationContext);
    const [isLoading, setIsLoading] = useState(false)
    const { getAccessTokenSilently } = useAuth0()
    const navigate = useNavigate();

    const backToBrand = () => {
        window.location.href = "/brand"
    }

    const [orgId, setOrgId] = useState(getID())

    useEffect( () => {

        async function orgDetails() {
            setIsLoading(true)
            
            const token = await getAccessTokenSilently()
            const response = DataServiceOrganisation.getOrg(orgId, token)
            try {
                const orgsResponse = await response
                setOrgData(orgsResponse.data)
                setIsLoading(false)
                
            } catch(e) {
                setIsLoading(false)
            }
        }
        orgDetails()

    }, [])

    const clickEvent = (page: string) => {
        navigate(`/organisation/details/${getID()}/${page}`, { replace: true })
    }

    const styles = {
        width: 260,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { 
            width: 260, 
            boxSizing: 'border-box' 
        },
        [`& .MuiButtonBase-root`]: {
            width: '100%'
        }
    }

    return(
        

            <Drawer variant="permanent"
                sx={styles}>
                <Toolbar />
                {Object.keys(orgData).length != 0 &&
                    <Box component="div" sx={{ p: 3, textAlign: "center" }}>
                        {orgData.icon_url !== '' && <img src={orgData.icon_url} style={{width:'40px', marginRight:'20px'}} /> }
                        <div>
                            <Typography variant="h2" sx={{fontWeight: 900, fontSize: 26}}>{orgData.name}</Typography>
                            <Typography paragraph={true} sx={{fontWeight: 200, fontSize: 14}}>ID: {orgData.org_id}</Typography>
                        </div>
                        <Button variant="text" href={'/brand'}>Back to Brand Overview</Button>

                    </Box>

                }
                <Divider sx={{ my: 1 }} />

                <List component="nav">
                    <ListItemButton component="button" onClick={ ()=>clickEvent('details')}>
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary="Organisation Details" />
                    </ListItemButton>
                    
                    <ListItemButton component="button" onClick={ ()=>clickEvent('resources')}>
                        <ListItemIcon>
                            <BallotIcon />
                        </ListItemIcon>
                        <ListItemText primary="Manage Resources" />
                    </ListItemButton>

                    <ListItemButton component="button" onClick={ ()=>clickEvent('products')}>
                        <ListItemIcon>
                            <CategoryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Manage Products" />
                    </ListItemButton>

                    <ListItemButton component="button" onClick={ ()=>clickEvent('link-products-resources')}>
                        <ListItemIcon>
                            <AccountTreeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Link Products &amp; Resources" />
                    </ListItemButton>

                    <ListItemButton component="button" onClick={ ()=>clickEvent('calendar')}>
                        <ListItemIcon>
                            <CalendarMonthIcon />
                        </ListItemIcon>
                        <ListItemText primary="Calendar" />
                    </ListItemButton>

                    <ListItemButton component="button" onClick={ ()=>clickEvent('schedule')}>
                        <ListItemIcon>
                            <EventNoteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Schedule" />
                    </ListItemButton>

                    <ListItemButton component="button" onClick={ ()=>clickEvent('special-events')}>
                        <ListItemIcon>
                            <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary="Special Events" />
                    </ListItemButton>
                    {/* {secondaryListItems} */}
                </List>
            </Drawer>
    )
}

export default OrganisationSidebar