import React, {useState, useRef, useEffect} from 'react';
import DataService from "../../services/data.service";
import LoadingSpinner from '../global/LoadingSpinner';
import InlineError from '../elements/InlineError';

const AddSpecialEvent = (props) => {

    const isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const [eventObject, setEventObject] = useState({
        "resource_set": [],
        "org_id": props.orgId,
        "type": "default",
        "date": "",
        "start_time": "",
        "end_time": "",
        "recurring": "none",
        "recurring_end": "never",
        "recurring_description": ""
    })
    const [update, setUpdate] = useState(0)
    const [resourcesCheckboxes, setResourcesCheckboxes] = useState([])
    const [isRecurring, setIsRecurring] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(function effectFunction() {
        const checkboxes = []
        for (let i = 0; i < props.resources.length; i++) {
            const item = props.resources[i]

            checkboxes.push({
                'label': item.res_name,
                'value': item.name,
                'checked': false
            })
        } 
        setResourcesCheckboxes(checkboxes)
    }, [])

    useEffect(function effectFunction() {
        
        async function addSpecialEvent() {
            setIsLoading(true)
            const response = DataService.createSpecialEvent(eventObject)
            try {
                const eventsResponse = await response
                props.makeAddSpecialEvent()
                props.setUpdateClashing(props.updateClashing+1)
                setIsLoading(false)
            } catch (e) {
                setIsLoading(false)
                if( e.response ) {
                    setErrorMessage(e.response.data.errors[0])
                } else {
                    setErrorMessage(e.message)
                }
            }
        }

        if( isMounted.current ) {
            addSpecialEvent()
        } else {
            isMounted.current = true;
        }
        

    }, [update])

    const resetError = () => setErrorMessage('')

    const onFieldChange = (event, field) => {
        eventObject[field] = event.target.value
        setEventObject(eventObject)
        checkIsRecurring()
        resetError()
    }

    const onResourceChange = (index, isChecked) => {

        const updatedListOfItems = [...resourcesCheckboxes]
        updatedListOfItems[index].checked = isChecked
        setResourcesCheckboxes(updatedListOfItems);

        const selectedResources = resourcesCheckboxes.reduce(function(result, item) {
            if (item.checked) {
              result.push(item.value);
            }
            return result;
          }, []);

        const oldObject = eventObject

        oldObject['resource_set'] = selectedResources
        setEventObject(oldObject)
    }

    const onSubmit = (e) => {
        setUpdate(update+1)
    }

    const checkIsRecurring = () => {
        if( eventObject.recurring == 'none' || eventObject['recurring_end'] == '') {
            setIsRecurring(false)
            eventObject['recurring_end'] = 'never'
            setEventObject(eventObject)
        } else {
            setIsRecurring(true)
        }
    }
    
    const recurring = ['none', 'daily','weekly','monthly', 'annual']

    return (
        <div>
            {isLoading && <LoadingSpinner />}
            {eventObject != {} && 
                <div>
                    {errorMessage != '' &&
                        <InlineError errorMessage={errorMessage} />
                    }
                    <div className="mb-3">
                        <label className="form-label">Recurring Description:</label>
                        <input type="text" className="form-control" defaultValue={eventObject.recurring_description} onChange={(e) => onFieldChange(e, 'recurring_description')} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Date:</label>
                        <input type="date" className="form-control" defaultValue={eventObject.date} onChange={(e) => onFieldChange(e, 'date')} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Start Time:</label>
                        <input type="time" className="form-control" defaultValue={eventObject.start_time} onChange={(e) => onFieldChange(e, 'start_time')} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">End Time:</label>
                        <input type="time" className="form-control" defaultValue={eventObject.end_time} onChange={(e) => onFieldChange(e, 'end_time')} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Recurring:</label>
                        <select className="form-control" onChange={(e) => onFieldChange(e, 'recurring')}>
                            {recurring.map((item)=><option value={item} key={item} defaultValue={(item == eventObject.recurring)}>{item}</option>)}
                        </select>
                    </div>
                    
                        <div className="mb-3">
                            <label className="form-label">Recurring End:</label>
                            {isRecurring &&
                                <input type="date" className="form-control" defaultValue={eventObject.recurring_end} onChange={(e) => onFieldChange(e, 'recurring_end')} />
                            }
                            {!isRecurring &&
                                <input type="text" readOnly={true} className="form-control" defaultValue="never" />
                            }
                        </div>
                    <div className="mb-3">
                        <label className="form-label">Resource Set:</label>
                        {
                            resourcesCheckboxes.map((item, index)=>
                                <div className="form-check" key={index}>
                                    <label className="form-check-label" htmlFor={'id' + item.value}>{item.label}</label>
                                    <input 
                                        className="form-check-input"
                                        id={'id' + item.value} 
                                        type="checkbox" 
                                        value={item.value} 
                                        checked={item.checked}
                                        onChange={()=>onResourceChange(index, !item.checked)} />
                                </div>
                            )
                        }
                    </div>
                    <button className="btn btn-primary" onClick={(e) => onSubmit(e)}>Add Special Event</button>
                </div>
            }
        </div>
    )
}

export default AddSpecialEvent



