import React, {useState, ChangeEvent, useContext, useEffect, FC, ReactNode} from 'react';
import DataService from "../../services/data.service";
import DataServiceCategory from '../../services/DataServiceCategory';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import Modal from '../Modal';
import Pagination from '../Pagination';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import Popover from '../global/Popover';
import { ModalContextType } from '../../interfaces/ModalContextType'
import { ModalContext } from "../../contexts/modalContext";
import Button from '../elements/Button';
import CategoryModel from '../../interfaces/CategoryModel';
import CategoryItem from './CategoryItem'
import ParentCategoryItem from './ParentCategoryItem'
import ListGroup from '../elements/ListGroup';
import Flex from '../elements/Flex';
import InlineError from '../elements/InlineError';
import { Grid, Toolbar } from '@mui/material';

interface ManageCatsProps {
}

const ManageCategories:FC<ManageCatsProps> = () => {
    let { handleModal } = useContext<any>(ModalContext)
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false)
    const [loadedCats, setLoadedCats] = useState<CategoryModel[]>([])
    const [cats, setCats] = useState<CategoryModel[]>([])
    const [parentCats, setParentCats] = useState<CategoryModel[]>([])
    const [filteredCats, setFilteredCats] = useState<CategoryModel[]>([])
    const [update, setUpdate] = useState(0)
    const [deleteItem, setDeleteItem] = useState<CategoryModel | undefined>()
    const [filterState, setFilterState] = useState('ALL')
    const [error, setError] = useState('')

    useEffect(()=> {
        let isMounted = true;  
        if(isMounted) {
            async function fetchCats() {
                setIsLoading(true)
                const response = DataServiceCategory.getCatList()
                try {
                    const orgsResponse = await response
                    orgsResponse.data.search_categories.sort((a: { category_name: string; }, b: { category_name: string; }) => {
                        let fa = a.category_name.toLowerCase(),
                            fb = b.category_name.toLowerCase();
                    
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    })
                    setLoadedCats(orgsResponse.data.search_categories.filter( (item: { parent_category_id: number; }) => item.parent_category_id !== 0 )) 
                    setParentCats(orgsResponse.data.search_categories.filter( (item: { parent_category_id: number; }) => item.parent_category_id == 0 ))
                    setCats(orgsResponse.data.search_categories)
                    setIsLoading(false)
                    setError('')
                    
                } catch(e) {
                    setIsLoading(false)
                }
            }
            fetchCats()
        }
        return () => { isMounted = false }

    }, [update])

    useEffect(()=> {

        if( deleteItem !== undefined) {
            async function deleteCat() {
                setIsLoading(true)
                const token = await getAccessTokenSilently();
                const response = (deleteItem!.parent_category_id == 0)? 
                    DataServiceCategory.deletePrimeCat(deleteItem!.category_id, token): 
                    DataServiceCategory.deleteSearchCat(deleteItem!.category_id, token)
                try {
                    const orgsResponse = await response
                    setUpdate(update + Math.floor((Math.random() * 10) + 1))
                    setIsLoading(false)
                    
                } catch(e:any ) {
                    setError(e.response.data.errors[0])
                    setIsLoading(false)
                }
            }
            deleteCat()
        }
    }, [deleteItem])

    const filterCats = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase()
        if( value === '') {
            setFilterState('ALL')
        } else {
            setFilterState('FILTER')
        }
        setFilteredCats(loadedCats.filter(item => item.category_name.toLowerCase().includes(value)))
    }
    const openEditforAttrs = (data: CategoryModel) => {
        handleModal(
            <EditCategory update={update} data={data} setUpdate={setUpdate} attributeOnly={true} />
            , 
            `Add Attributes to ${data.category_name}`
        )
    }

    return(
        <Grid sx={{padding: '0 20px'}}>
            <Toolbar />
            <Flex justify='justify-content-between' align='align-items-center'>
                <h1>Manage Categories</h1>
                <Button label={'Add Category'} type={'primary'} action={
                    ()=>handleModal(
                        <AddCategory update={update} setUpdate={setUpdate} parentCats={parentCats} openEdit={openEditforAttrs} />
                        , 
                        'Add Category'
                    )} />
                
            </Flex>
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            {isLoading && <LoadingSpinner/>}
            <Flex justify='' align=''>
                <div className="form-floating mb-3 w-80 flex-fill">
                    <input type="text" className="form-control" id="filter" onChange={(e)=>filterCats(e)} />
                    <label htmlFor="filter">Search for Category</label>
                </div>
            </Flex>
            <ListGroup>
                {filterState === 'ALL' &&
                    parentCats.map((cat)=><ParentCategoryItem data={cat} setDeleteItem={setDeleteItem} key={cat.category_id} update={update} setUpdate={setUpdate} childCats={loadedCats.filter(item => item.parent_category_id !== 0)} />)
                }
                {filterState === 'FILTER' &&
                    cats.map((cat)=><CategoryItem filter={true} data={cat} setDeleteItem={setDeleteItem} key={cat.category_id} update={update} setUpdate={setUpdate} parentCats={loadedCats.filter(item => item.parent_category_id == 0)} />)
                }
            </ListGroup>
            {filterState === 'FILTER' &&
                <Pagination setItems={setCats} originalItems={loadedCats} filteredItems={filteredCats} />
            }
        </Grid>
    )
}

export default withAuthenticationRequired(ManageCategories, {
    onRedirecting: () => <LoadingSpinner />,
});