import { useAuth0 } from "@auth0/auth0-react"
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { ChangeEvent, FC, useContext, useEffect, useState } from "react"
import { DDCFlowContext } from "../../contexts/DdcFlowContext"
import DataServiceDdc from "../../services/DataServiceDdc"
import TextInputRow from "../formfields/TextInputRow"
import LoadingSpinner from "../global/LoadingSpinner"

const AddQuestion:FC<{data: any, closeDrawer: (a: boolean)=>void}> = ({data, closeDrawer}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [ errorMessage, setErrorMessage] = useState('')
    const {
        getAccessTokenSilently
    } = useAuth0();
    
    interface IQuestionInput {
        brand_id: string,
        action_type: string,
        question_id: string,
        question_text: string,
        question_text_2: string,
        dialogue_type: string,
        question_type: string,
        template_line_1: string,
        flow_id: string,
    }

    interface IOptionInput {
        brand_id: string,
        question_id: string,
        endpoint_type: string,
        endpoint: string,
        response: string,
        display_text: string
    }
    
    const questionInput = {
        "brand_id": data.questionData.question.brand_id,
        "action_type": "question",
        "question_id": "",
        "question_text": "this is question 11",
        "question_text_2": "it is a new one",
        "dialogue_type": "",
        "question_type": "",
        "template_line_1": "question text here",
        "flow_id": data.questionData.question.flow_id
      }

    const optionInput = {
        "brand_id": data.questionData.question.brand_id,
        "question_id": data.questionData.question.question_id,
        "endpoint_type": "question",
        "endpoint": "",
        "response": "Yes",
        "display_text": "YES"
      }

    let { incrementRefreshDDC } = useContext<any>(DDCFlowContext)

    const [questionTypes, setQuestionTypes] = useState<string[]>([])
    const [dialogueTypes, setDialogueTypes] = useState<string[]>([])
    const [actionTypes, setActionTypes] = useState<string[]>([])

    useEffect(() =>{
        let isMounted = true
        if(isMounted) {
            async function fetchQuestionTypes() {
                const response = DataServiceDdc.getQuestionTypes();
                try {
                    const optionResponse = await response
                    setQuestionTypes(optionResponse.data)
                    setIsLoading(false)

                } catch (e: any) {
                    setIsLoading(false)
                    if( e.response ) {
                        setErrorMessage(e.response.errors)
                    } else {
                        setErrorMessage(e.message)
                    }
                }
            }
            fetchQuestionTypes()
            
            async function fetchDialogueTypes() {
                const response = DataServiceDdc.getDialogueTypes(data.questionData.question.brand_id);
                try {
                    const optionResponse = await response
                    setDialogueTypes(optionResponse.data)
                    setIsLoading(false)

                } catch (e: any) {
                    setIsLoading(false)
                    if( e.response ) {
                        setErrorMessage(e.response.errors)
                    } else {
                        setErrorMessage(e.message)
                    }
                }
            }
            fetchDialogueTypes()

            async function fetchActionTypes() {
                const response = DataServiceDdc.getActionTypes();
                try {
                    const optionResponse = await response
                    setActionTypes(optionResponse.data)
                    setIsLoading(false)

                } catch (e: any) {
                    setIsLoading(false)
                    if( e.response ) {
                        setErrorMessage(e.response.errors)
                    } else {
                        setErrorMessage(e.message)
                    }
                }
            }
            fetchActionTypes()
        }
        return () => { isMounted = false }
    },[])
    

    const [questionData, setQuestionData] = useState<IQuestionInput>(questionInput)
    const [optionData, setOptionData] = useState<IOptionInput>(optionInput)

    const [dialogueType, setDialogueType] = useState('')
    const [questionType, setQuestionType] = useState('')
    const [actionType, setActionType] = useState('')


    const onFieldChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        let isMounted = true
        const value = event.target.value;
        if(isMounted) {
            setQuestionData(values => ({...values, [field]: value}))
            if( field === 'question_id') {
                setOptionData(values => ({...values, ['endpoint']: value}))
            }
        }
        return () => { isMounted = false }
    }

    const onFieldChange2 = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        let isMounted = true
        const value = event.target.value;
        if(isMounted) {
            setOptionData(values => ({...values, [field]: value}))
        }
        return () => { isMounted = false }
    }

    const onSelectChange = (event: SelectChangeEvent) => {
        setQuestionData(values => ({...values, [event.target.name]: event.target.value as string}))
        if( event.target.name == "dialogue_type" ) {
            setDialogueType(event.target.value)
        }
        if( event.target.name == "question_type") {
            setQuestionType(event.target.value)
        }
        if( event.target.name == "action_type") {
            setActionType(event.target.value)
        }
    };


    async function onSubmit(e: any) {
        setIsLoading(true)
        // const token = await getAccessTokenSilently()
        const response = DataServiceDdc.addQuestion(questionData);
        try {
            const ddcResponse = await response
            //refresh
            // set probelm events number
            optionData.endpoint = ddcResponse.data.question_id;
            // setIsLoading(false)
            const response2 = DataServiceDdc.addOption(optionData);
            try {
                const optionResponse = await response2
                // set probelm events number
                setIsLoading(false)

                closeDrawer(false)
                incrementRefreshDDC()

            } catch (e: any) {
                setIsLoading(false)
                if( e.response ) {
                    setErrorMessage(e.response.errors)
                } else {
                    setErrorMessage(e.message)
                }
            }

        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setErrorMessage(e.response.errors)
            } else {
                setErrorMessage(e.message)
            }
        }
    }

    const selectStyle = {
        width: '100%', 
        padding: 0,
        marginBottom: '1rem'
    }
    
    return (
          
        <Box sx={{padding: '20px'}}>
        
            <Typography variant="h4" sx={{fontSize: '1.4rem', fontWeight: '800', marginBottom: '1rem'}}>Add Option &amp; Item</Typography>
            {isLoading && <LoadingSpinner/>}

            {errorMessage !== '' && errorMessage}

            <Box>
                <FormControl sx={selectStyle} size="small">
                    <InputLabel id="action_type_label">Action Type</InputLabel>
                    <Select
                        labelId="action_type_label"
                        id="action_type"
                        value={actionType}
                        label="Action Type"
                        onChange={onSelectChange}
                        name="action_type"
                        sx={{width: '100%'}}
                    >
                        {actionTypes.map((item, i)=><MenuItem key={i} value={item}>{item}</MenuItem>)}

                    </Select>
                </FormControl>
            </Box>

            <TextInputRow label="Question ID" type="text" defaultValue={questionData.question_id} onFieldChange={onFieldChange} field="question_id" inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label="Question Text" type="text" defaultValue={questionData.question_text} onFieldChange={onFieldChange} field="question_text" inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label="Question Text 2" type="text" defaultValue={questionData.question_text_2} onFieldChange={onFieldChange} field="question_text_2" inputGroup={false} buttonAction={()=>null} />
            <Box>
                <FormControl sx={selectStyle} size="small">
                    <InputLabel id="dialogue_type_label">Dialogue Type</InputLabel>
                    <Select
                        labelId="dialogue_type_label"
                        id="dialogue_type"
                        value={dialogueType}
                        label="Dialogue Type"
                        onChange={onSelectChange}
                        name="dialogue_type"
                        sx={{width: '100%'}}
                    >
                        {dialogueTypes.map((item, i)=><MenuItem key={i} value={item}>{item}</MenuItem>)}

                    </Select>
                </FormControl>
            </Box>
            <Box>
                <FormControl sx={selectStyle} size="small">
                    <InputLabel id="question_type_label">Question Type</InputLabel>
                    <Select
                        labelId="question_type_label"
                        id="question_type"
                        value={questionType}
                        label="question Type"
                        onChange={onSelectChange}
                        name="question_type"
                        sx={{width: '100%'}}
                    >
                        {questionTypes.map((item, i)=><MenuItem key={i} value={item}>{item}</MenuItem>)}

                    </Select>
                </FormControl>
            </Box>

            {actionType == 'task' &&
                <TextInputRow label="Template Line 1" type="text" defaultValue={questionData.template_line_1} onFieldChange={onFieldChange} field="template_line_1" inputGroup={false} buttonAction={()=>null} />
            }

            <TextInputRow label="Option Response" type="text" defaultValue={optionInput.response} onFieldChange={onFieldChange2} field="response" inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label="Option Display Text" type="text" defaultValue={optionInput.display_text} onFieldChange={onFieldChange2} field="display_text" inputGroup={false} buttonAction={()=>null} />
        
            <Button onClick={(e)=>onSubmit(e)}>Add Option</Button>

        </Box>
        )
}

export default AddQuestion