import React, {Component} from 'react';
import BookingCalendar from "./bookingCalendar";
import BookingResources from "./bookingResources";
import BookingOrganisations from "./bookingOrganisations";
import BookingProducts from "./bookingProducts";
import Bookings from "./bookingBookingsList";
import Modal from "./modal";
import 'bootstrap/dist/css/bootstrap.min.css';

import DataService from "../../services/data.service";

function buildBookings(apiBookings) {
    const bookingsList = []

    if( apiBookings.length > 0) {
        apiBookings.forEach(element => {
            const startDate = element.start.replace("T", " ")
            const endDate = element.end.replace("T", " ")
            bookingsList.push(
                {
                    startDate: new Date(startDate), 
                    endDate: new Date(endDate)
                }
            )
        });
    }

    return bookingsList
}

function pad(num) {
    if(num < 10) {
        return '0' + num
    } else {
        return num
    }
}

class AppBooking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerId: "c9a5fcb0-f9e0-565f-999b-9e4abbb1cd36",
            showBookings: false,
            events: [],
            orgs: [],
            bookings: [],
            resources: [],
            products: [],
            resourceId: 0,
            resourceName: '',
            selectedDate: '',
            isLoading: false,
            bookingStatus: 0,
            fromDate: new Date().toISOString().split("T")[0],
            orgId: 0,
            orgName: '',
            bookingStatusText: '',
            bookingStatusDetail: '',
            productName: '',
            productDuration: 0
        }
    }

    setResourceId = (name, resName) => {
        this.setState(
            {
                resourceId: name,
                resourceName: resName
            }
        );
        
    }

    setOrgId = (id, name) => {
        this.setState(
            {
                orgId: id,
                orgName: name,
                isLoading: true
            }
        );
        this.requestProducts()
        this.fetchOrgDefaults(id)
    }

    fetchOrgDefaults = (id) => {
        DataService.getOrgDefaults(id).then(
            response => {
                this.setState({
                    minSlot: parseInt(response.data.min_slot),
                    isLoading: false
                });
            },
            error => {
            }
        );
    }

    setProduct = (name, duration) => {
        this.setState(
            {
                productName: name,
                productDuration: duration
            }
        );
        this.requestResources(this.state.orgId)
    }

    setSelectedDate = (fromDate) => {
        const date = fromDate.start.getFullYear() + '-' + pad(fromDate.start.getMonth()+1) + '-' + pad(fromDate.start.getDate())
        this.setState(
            {
                fromDate: date,
            }
        );

        this.requestBookings( date )
    }


    requestBookings = (fromDate) => {
        
        this.setState({isLoading: true});
        DataService.getBookings(this.state.orgId, this.state.resourceId, fromDate).then(
            response => {
                this.setState({
                    bookings: buildBookings(response.data),
                    isLoading: false
                });
            },
            error => {
                this.setState({
                    bookings: []
                });
            }
        );
    }

    createEvent = (data) => {

        data.resource = this.state.resourceId
        data.customer = "customer_b"
        data.cust_uuid = this.state.customerId
        data.org_id = this.state.orgId
        data.product = this.state.productName

        this.setState({isLoading: true});

        DataService.createBooking(data).then(
            response => {
                console.log('booking request complete:', response.data.start)

                if( response.data == 'unavailable') {
                    this.setState({
                        isLoading: false,
                        bookingStatus: -1,
                        bookingStatusText: 'Error',
                        bookingStatusDetail: 'Really sorry, someone has already booked that spot! Please try selecting another.'
                    });
                } else {
                    const data = <span>
                                    <span className="p d-block">You have booked:</span>
                                    <span className="h2 d-block">{response.data.product}</span>
                                    <span className="p d-block"><em>at</em> {this.state.orgName} <em>with</em> {this.state.resourceName}</span>
                                    <span className="p d-block">@ {response.data.start} on {new Date(response.data.date).toDateString()}</span>
                                </span>

                    this.setState({
                        isLoading: false,
                        bookingStatus: 1,
                        bookingStatusText: 'Booking Complete',
                        bookingStatusDetail: data
                    });
                }
            },
            error => {
                this.setState({
                    isLoading: false,
                    bookingStatus: -1,
                    bookingStatusText: 'Error',
                    bookingStatusDetail: 'There has been a problem, please try again.'
                });
            }
        )
    }

    requestResources = (id) => {
        DataService.getResources(id).then(
            response => {
                this.setState({
                    resources: response.data,
                    isLoading: false
                });
            },
            error => {
                this.setState({
                    isLoading: false,
                    bookingStatus: -1,
                    bookingStatusText: 'Error',
                    bookingStatusDetail: 'There has been a problem loading the resources. Please refresh the page and try again.'
                })
            }
        );
    }

    requestOrgs = () => {
        DataService.getAllOrgs().then(
            response => {
                this.setState({
                    orgs: response.data,
                    isLoading: false
                });
            },
            error => {
                this.setState({
                    isLoading: false,
                    bookingStatus: -1,
                    bookingStatusText: 'Error',
                    bookingStatusDetail: 'There has been a problem loading the organisations. Please refresh the page and try again.'
                })
            }
        );
    }

    requestProducts = () => {
        DataService.getSampleProducts().then(
            response => {
                this.setState({
                    products: response.data,
                    isLoading: false
                });
            },
            error => {
                this.setState({
                    isLoading: false,
                    bookingStatus: -1,
                    bookingStatusText: 'Error',
                    bookingStatusDetail: 'There has been a problem loading the products. Please refresh the page and try again.'
                })
            }
        );
    }

    requestEvents = () => {
        DataService.getEvents(this.state.customerId).then(
            response => {
                this.setState({
                    events: response.data,
                    isLoading: false,
                    showBookings: true
                });
            },
            error => {
                this.setState({
                    isLoading: false,
                    bookingStatus: -1,
                    bookingStatusText: 'Error',
                    bookingStatusDetail: 'There has been a problem loading the events. Please refresh the page and try again.'
                })
            }
        );
    }

    cancelBooking = (id) => {
        this.setState({isLoading: true})

        DataService.deleteEvent(id).then(
            response => {
                this.requestEvents()
            },
            error => {
                this.setState({
                    isLoading: false,
                    bookingStatus: -1,
                    bookingStatusText: 'Error',
                    bookingStatusDetail: 'There has been a problem deleting the event.'
                })
            }
        );
    }

    toggleBookings = () => {
        if( this.state.showBookings ) {
            this.setState({
                showBookings: false
            })
        } else {
            this.setState({
                isLoading: true,
                showBookings: true
            });
            this.requestEvents()
        }
    }

    resetResourceId = () => {
        this.setState({resourceId: 0});
    }

    resetBookingStatus = () => {
        this.setState({bookingStatus: 0});
    }

    backToStart = () => { 
        this.setState({
            orgs: [],
            bookings: [],
            resources: [],
            products: [],
            resourceId: 0,
            resourceName: '',
            selectedDate: '',
            isLoading: false,
            bookingStatus: 0,
            fromDate: new Date().toISOString().split("T")[0],
            orgId: 0,
            bookingStatusText: '',
            bookingStatusDetail: '',
            productName: '',
            productDuration: 0,
            minSlot: 0
        });

        this.requestOrgs()
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.requestOrgs();
        // this.requestResources();
    }

    showResources() {
        if( this.state.resourceId == 0 && this.state.productDuration != 0 ) {
            return true;
        } else {
            return false;
        }
    }

    showProducts() {
        if( this.state.productDuration == 0 && this.state.orgId != 0 ) {
            return true;
        } else {
            return false;
        }
    }

    render() {

        let backButton;

        if( this.state.resourceId != 0 ) {
            backButton = <button className="btn btn-outline-dark mb-3" onClick={this.resetResourceId}>Back to resources</button>
        }
        else if( this.state.orgId != 0 ) {
            backButton = <button className="btn btn-outline-dark mb-3" onClick={this.backToStart}>Back to start</button>
        }

        return (
            <div>
                <div>
                    {this.state.bookingStatus == -1 && <Modal title={this.state.bookingStatusText} text={this.state.bookingStatusDetail} modalAction={this.resetBookingStatus} />}
                    {this.state.bookingStatus == 1 && <Modal title={this.state.bookingStatusText} text={this.state.bookingStatusDetail} modalAction={this.backToStart} />}
                    {this.state.isLoading && <div className="loading-indicator"><h2>Loading...</h2></div>}
                    <div className="d-flex">
                        {backButton}
                        <button className="btn btn-outline-dark mb-3 ms-auto" onClick={this.toggleBookings}>{this.state.showBookings ? 'Hide Bookings' : 'Show Bookings'}</button>
                    </div>
                    {this.state.showBookings && <Bookings cancel={this.cancelBooking} bookings={this.state.events} />} 
                    {this.showProducts() && <BookingProducts setProduct={this.setProduct} products={this.state.products} /> }
                    {this.state.orgId == 0 && <BookingOrganisations setOrgId={this.setOrgId} orgs={this.state.orgs} />}
                    {this.showResources() && <BookingResources setResourceId={this.setResourceId} resources={this.state.resources} />}
                    {this.state.resourceId != 0 && <BookingCalendar bookings={this.state.bookings} setBooking={this.createEvent} setDate={this.setSelectedDate} duration={this.state.productDuration} step={this.state.minSlot} />}
                </div>
            </div>
        )
    }
}


export default AppBooking;