import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class Org extends Component {
    constructor(props) {
        super(props);
        this.handleClick.bind(this);
    }

    handleClick = () => {
        // Simply call the setStateOfParent function from 
        // prop and pass required argument
        this.props.setOrgId(this.props.data.organisation_id, this.props.data.organisation_name );
    }
    render() {
        return (
            <button className="list-group-item" onClick={this.handleClick} key={this.props.data.organisation_id}>{this.props.data.organisation_name}</button>
        )
    }
}

function OrgList(props){
    if( props.orgs.length > 0) {
        return props.orgs.map((organisation)=><Org setOrgId={props.setOrgId} data={organisation} key={organisation.organisation_id} />)
    } else {
        return <p>Nothing found</p>
    }
}

const BookingOrganisations = (props) => {
    return (
        <div>
            <h2>Organisations</h2>
            <ul className="list-group">
                <OrgList setOrgId={props.setOrgId} orgs={props.orgs} />
            </ul>
        </div>
    );
}

export default BookingOrganisations;