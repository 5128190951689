import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./App.css";

import { history } from './helpers/history';

import Organisations from "./components/ListOrganisations";
import Brands from "./components/Brands";
import ViewOrganisation from "./components/organisation/ViewOrganisation";
import AppBooking from "./components/appviews/appBooking";
import HarnessCalendar from "./components/appviews/harnessCalendar";
import LoadingSpinner from "./components/global/LoadingSpinner";
import OrganisationDetails from "./components/organisationDetails/OrganisationDetails";
import Admin from "./components/Admin";
import ManageCategories from "./components/administration/ManageCategories";
import ManageAttributes from "./components/administration/ManageAttributes"
import ManageNtas from "./components/administration/ManageNtas"
import ManageDdc from "./components/administration/ManageDdc"

import { ModalProvider } from "./contexts/modalContext";
import { DDCFlowProvider } from './contexts/DdcFlowContext';
import ResponsiveAppBar from "./components/global/ResponsiveAppBar";
import DetailsPanel from "./components/organisationDetails/DetailsPanel";
import Resources from "./components/organisationDetails/Resources";

function getPath() {
    const path = window.location.pathname
    return path
}

const App = () => {

    const { isLoading, error } = useAuth0();

    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
      } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    const isApp = () => {
        if( getPath().includes('app/')) {
            return true
        } else {
            return false
        }
    }

    if (error) {
      return <div>Oops... {error.message}</div>
    }
  
    if (isLoading) {
      return <LoadingSpinner />
    }

    

    const showAdmin = () => { return isAuthenticated }
    const showBrands = () => { return isAuthenticated }

    return (
        <ModalProvider>
            <DDCFlowProvider>
            <BrowserRouter history={history}>
                <div>
                    {!isApp() && (
                        <ResponsiveAppBar />
                    )}

                    
                    
                        <Routes>
                            <Route exact path="/" element={<Brands/>} />
                            <Route path="/organisations" element={<Organisations/>} />
                            <Route path="/brand" element={<Brands/>} />
                            <Route path="/organisation/:id" element={<ViewOrganisation/>} />
                            <Route path="/organisation/details/:id" element={<OrganisationDetails />} />
                            <Route path="/organisation/details/:id/:page" element={<OrganisationDetails />} />
                            <Route path="/app/calendar" element={<AppBooking/>} />
                            <Route path="/app/bookingcalendar" element={<HarnessCalendar/>} />
                            <Route path="/admin" element={<Admin/>} />
                            <Route path="/admin/manage-categories" element={<ManageCategories/>} />
                            <Route path="/admin/manage-attributes" element={<ManageAttributes/>} />
                            <Route path="/admin/manage-ntas" element={<ManageNtas/>} />
                            
                            <Route path="/admin/manage-ddc" element={<ManageDdc/>} />
                        </Routes>
                    
                </div>
            </BrowserRouter>
            
            </DDCFlowProvider>
        </ModalProvider>
    );
    
}

export default App;