import React, {useState, useContext, useEffect, FC, ChangeEvent} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import DataServiceCategory from '../../services/DataServiceCategory';
import DataServiceAttribute from '../../services/DataServiceAttribute';
import LoadingSpinner from '../global/LoadingSpinner';
import TextInputRow from "../formfields/TextInputRow"
import UploadIcon from '../formfields/UploadIcon';
import InlineError from '../elements/InlineError';
import FormLabel from '../formfields/FormLabel'
import FormRow from '../formfields/FormRow'
import ListGroup from '../elements/ListGroup'
import Button from '../elements/Button'
import { ModalContext } from "../../contexts/modalContext";
import Attribute from '../../interfaces/AttributeModel'
import CategoryModel from '../../interfaces/CategoryModel';

interface EditCategoryProps {
    data: CategoryModel
    update: number
    setUpdate: (a: number)=>void
    attributeOnly?: boolean
}



const EditCategory:FC<EditCategoryProps> = (props) => {
    let { handleModal } = useContext<any>(ModalContext)

    const { getAccessTokenSilently } = useAuth0();

    const [iconUrl, setIconUrl] = useState(props.data.icon_url)

    const [fields, setFields] = useState({
        category_id: props.data.category_id,
        category_name: props.data.category_name,
        parent_category_id: props.data.parent_category_id,
        icon_url: iconUrl
    })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [attributes, setAttributes] = useState<Attribute[]>()
    const [updateAttributes, setUpdateAttributes] = useState(0)
    const [allAttributes, setAllAttributes] = useState<Attribute[]>([])
    const [attrFilter, setAttrFilter] = useState<Attribute[]>([])
    const [filterWord, setFilterWord] = useState('')

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true)
        const token = await getAccessTokenSilently();
        const response = DataServiceCategory.updateCategory(fields, token)
        try {
            const brandResponse = await response
            console.log(brandResponse)
            props.setUpdate(props.update + 1)
            handleModal()
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    }

    useEffect(()=> {
        let isMounted = true;  
        if(isMounted) {
            async function fetchAttributes() {
                setIsLoading(true)
                const token = await getAccessTokenSilently()
                const response = DataServiceAttribute.getCategoryAttributes(props.data.category_id, token)
                try {
                    const orgsResponse = await response
                    setAttributes(orgsResponse.data)
                    setIsLoading(false)
                    
                } catch(e) {
                    setIsLoading(false)
                }
            }
            fetchAttributes()

            async function fetchAllAttributes() {
                setIsLoading(true)
                const token = await getAccessTokenSilently()
                const response = DataServiceAttribute.getAllAttributes(token)
                try {
                    const orgsResponse = await response
                    setAllAttributes(orgsResponse.data)
                    setIsLoading(false)
                    
                } catch(e) {
                    setIsLoading(false)
                }
            }
            fetchAllAttributes()
        }
        return () => { isMounted = false }
    },[updateAttributes])

    useEffect(()=> {
        setFields(values => ({...values, 'icon_url': iconUrl}))
    }, [iconUrl])

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        const value = event.target.value;
        setFields(values => ({...values, [field]: value}))
    }

    const linkUnlinkAttribute = async (attribute: Attribute, state: string) => {
        setIsLoading(true)
        const fields = {
            attribute: attribute.attribute,
            category_id: props.data.category_id,
            operation: state
        }
        const token = await getAccessTokenSilently();
        const response = DataServiceAttribute.linkCategoryAttribute(fields, token)
        try {
            const brandResponse = await response
            console.log(brandResponse)
            setUpdateAttributes(updateAttributes + 1)
            setAttrFilter([])
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    }

    const addAttribute = async () => {
        setIsLoading(true)
        const token = await getAccessTokenSilently();
        const response = DataServiceAttribute.addAttribute(filterWord, token)
        try {
            const brandResponse = await response
            setUpdateAttributes(updateAttributes + 2)
            linkUnlinkAttribute(brandResponse.data[0].s, 'link')
            setAttrFilter([])
            setIsLoading(false)
        } catch (e:any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    }

    const existingFilter = (e: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>) => {
        setFilterWord(e.target.value)
        const filter = allAttributes.filter(item=>item.display_name.toLowerCase().includes( e.target.value.toLowerCase() )) as Attribute[]
        if(e.target.value === '') {
            setAttrFilter([])
        } else {
            setAttrFilter(filter.slice(0, 5))
        }
    }

    const showAttrs = ():boolean => {
        let length = attributes?.length ?? 0
        if( length > 0) return true
        return false
    }

    const showAddAttrs = ():boolean => {
        if (props.data.parent_category_id === 0) return false
        return true
    }

    return(
        <div className="position-relative">
            {isLoading && <LoadingSpinner />}
            {error !== '' &&
                <InlineError errorMessage={error} />
            }
            {!props.attributeOnly &&
                <div>
                    <UploadIcon iconUrl={iconUrl} setIconUrl={setIconUrl} />
                    <TextInputRow label="category name" defaultValue={fields.category_name} onFieldChange={onFieldChange} field="category_name" inputGroup={false} buttonAction={()=>null}/>
                </div>
            }
            <div>
                {showAttrs() && 
                    <FormRow>
                        <FormLabel label="Attributes" />
                        <div>
                            {attributes?.map(item =>(
                                <span key={item.attribute} className="badge bg-warning text-dark me-2 mb-2">
                                    {item.display_name} 
                                    <button className="btn btn-sm py-0" onClick={(e)=>linkUnlinkAttribute(item, 'unlink')}>&times;</button>
                                </span>
                            ))}
                        </div>
                    </FormRow>
                }
                {showAddAttrs() &&
                    <div>
                        <TextInputRow 
                            label="Add Attributes" 
                            defaultValue={''} 
                            onFieldChange={existingFilter} 
                            field="" 
                            inputGroup={true} 
                            buttonAction={addAttribute} />
                        {attrFilter.length > 0 &&
                            <ListGroup>
                                {attrFilter.map((attr)=><button key={attr.attribute} onClick={(e)=>linkUnlinkAttribute(attr, 'link')} className="list-group-item list-group-item-action" aria-current="true">{attr.display_name}</button>)}
                            </ListGroup>
                        }
                    </div>
                }
            </div>
            <Button label={(props.attributeOnly)?"Done":"Save Changes"} type="primary" action={handleSubmit} />
        </div>
    )
}

export default EditCategory