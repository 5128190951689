import React, {useContext} from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "../../contexts/modalContext";
import { ModalContextType } from '../../interfaces/ModalContextType'

const Modal = () => {
    let { modalContent, modalTitle, handleModal, modal, wide } = useContext<any>(ModalContext);
    if (modal) {
        return ReactDOM.createPortal(
        <div className={`modal ${wide? 'modal-wide':''}`} tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalTitle}</h5>
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={() => handleModal()}></button>
                        </div>
                        <div className="modal-body">
                            {modalContent}
                        </div>
                    </div>
                </div>
            </div>,
            document.querySelector("#modal-root")!
        );
    } else return null;
};

export default Modal;