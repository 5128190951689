import React, {FC, useState, useRef, useEffect, ChangeEvent} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import DataService from "../../services/data.service";
import DataServiceAttribute from '../../services/DataServiceAttribute';
import CategoryModel from '../../interfaces/CategoryModel'

interface AttrCheckProps {
    data: any;
    setAttribute: (value: string)=>void;
    selectedAttrs: string[]
}

const AttrCheck:FC<AttrCheckProps> = (props) => {
    const selectedAttr = (props.selectedAttrs.length > 0 ? (props.selectedAttrs.filter((item:string) => item == props.data.attribute).length > 0 ) : false )
    const [selected, setSelected] = useState(selectedAttr)

    const onSelect = (selected: boolean, e:ChangeEvent<HTMLInputElement>) => {
        setSelected(selected)
        props.setAttribute(e.target.value)
    }

    return(
        <div className="col-3 mb-1">
            <div className="form-check form-switch">
                <input 
                    className="form-check-input" 
                    name='child'
                    type='checkbox'
                    role='switch'
                    id={props.data.attribute} 
                    value={props.data.attribute} 
                    checked={selected}
                    onChange={(e)=>onSelect(!selected, e)}
                />
                <label className="form-check-label" htmlFor={props.data.attribute}>    
                    {props.data.display_name}
                </label>
            </div>
        </div>
    )
}

interface AttrListProps {
    catId: number
    catChange: number
    selectedAttrs: string[]
    setAttribute: (a:string)=>void
}

const AttrList:FC<AttrListProps> = (props) => {
    const [attributeList, setAttributeList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const {
        getAccessTokenSilently
    } = useAuth0();
    
    useEffect(function effectFunction() {
        let isMounted = true;       
        async function fetchAttributes() {
            setIsLoading(true)
            const token = await getAccessTokenSilently()
            const response = DataServiceAttribute.getCategoryAttributes(props.catId, token)
            try {
                const dataResponse = await response
                if (isMounted) setAttributeList(dataResponse.data)
                
                if (isMounted) setIsLoading(false)
                
            } catch(e) {
                if (isMounted) setIsLoading(false)
            }
        }
        fetchAttributes()
        return () => { isMounted = false }
    }, [props.catChange])

        
    if( attributeList.length > 0) {
        return <div className="row">{attributeList.map((attribute, index)=><AttrCheck key={index} data={attribute} selectedAttrs={props.selectedAttrs} setAttribute={props.setAttribute} />)}</div>
    } else {
        return <span></span>
    }
}

interface SelectProps {
    allCats: CategoryModel[]
    selectedCats:  number[]
    catChange: number
    setAttribute: (a:string)=>void
    selectedAttrs: string[]
}

const SelectAttributes:FC<SelectProps> = (props) => {

    const getCatName = (id: number) => {
        const filter = props.allCats.filter(item => item.category_id === id)
        if( filter.length > 0 ) return filter[0].category_name
        return ''
    }

    return(
        <div className="mb-3 align-items-center">
            {props.selectedCats.length > 0 &&
                props.selectedCats.map((cat, index)=>
                    <div key={cat} className="row mx-1 mb-2 p-3 bg-light align-items-center">
                        <h6>Attributes for <strong>{getCatName(cat)}</strong></h6>
                        <AttrList 
                            catId={cat} 
                            catChange={props.catChange} 
                            setAttribute={props.setAttribute}
                            selectedAttrs={props.selectedAttrs} />
                    </div>)
            }
            {props.selectedCats.length == 0 &&
                <p>Select a category</p>
            }
        </div>
    )
}

export default SelectAttributes