import React, {useState, ChangeEvent, useContext, useEffect, FC} from 'react';
import DataService from "../../services/data.service";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from '../global/LoadingSpinner';
import Modal from '../Modal';
import Pagination from '../Pagination';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import Popover from '../global/Popover';
import { ModalContextType } from '../../interfaces/ModalContextType'
import { ModalContext } from "../../contexts/modalContext";
import Button from '../elements/Button';
import CategoryModel from '../../interfaces/CategoryModel';
import Flex from '../elements/Flex';
import { AttributeMain } from '../../interfaces/AttributeModel';
import EditAttribute from './EditAttribute';

interface AttributeProps {
    update: number
    setUpdate: (a:number)=>void
    data: AttributeMain
    setDeleteItem: (a: AttributeMain)=>void
    catList: CategoryModel[]
}

const AttributeItem:FC<AttributeProps> = (props) => {
    let { handleModal } = React.useContext<any>(ModalContext)

    const [confirmDelete, setConfirmDelete] = useState(false)
    const deleteItem = () =>{
        setConfirmDelete(false)
        props.setDeleteItem(props.data)
    }

    const catList = () => {
        return props.data.categories
    }

    const openEditModal = () => {
        handleModal(
            <EditAttribute update={props.update} data={props.data} catList={getCats()} setUpdate={props.setUpdate} />
            , 
            `Edit Attribute`,
            true
        )
    }

    const getCats = ():CategoryModel[] => {
        const catIds = props.data.categories 
        return props.catList.filter(item=>catIds.indexOf(item.category_id) !== -1)
    }

    return (
            <Flex justify={`justify-content-between list-group-item`} align={'align-items-center'}>
                <div className={`mb-0 me-auto align-self-center`}>
                    <h5 style={{width: '100%'}}>
                        {props.data.display_name }
                    </h5>
                    {getCats().map(item=>(<span key={item.category_id} className="badge bg-primary me-1">{item.category_name}</span> )) }
                </div>
                <div className="d-inline" style={{position: 'relative'}}>
                    <Button label={'Edit Attribute'} type={'link'} action={openEditModal} />
                </div>
                <div className="d-inline" style={{position: 'relative'}}>
                    {confirmDelete && <Popover popWidth={200} closePop={setConfirmDelete} confirmPop={deleteItem} message={"Are you sure you want to delete " + props.data.display_name} />}
                    <Button label={'Delete Attribute'} type={'link'} action={(e)=>setConfirmDelete(true)} />
                </div>
            </Flex>
    )
}

export default AttributeItem