import React, {useState, useContext, FC, ChangeEvent, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import DataServiceCategory from '../../services/DataServiceCategory';
import DataServiceAttribute from '../../services/DataServiceAttribute';
import LoadingSpinner from '../global/LoadingSpinner';
import TextInputRow from "../formfields/TextInputRow"
import UploadIcon from '../formfields/UploadIcon';
import InlineError from '../elements/InlineError';
import SelectCategory from './SelectCategory';
import EditCategory from './EditCategory';
import Button from '../elements/Button';
import { ModalContext } from "../../contexts/modalContext";

import CategoryModel from '../../interfaces/CategoryModel';

interface Props {
    update: number,
    setUpdate: (a:number)=>void
    parentCats: CategoryModel[]
    openEdit: (a:CategoryModel)=>void
}

const AddCategory:FC<Props> = (props) => {

    let { handleModal } = useContext<any>(ModalContext)
    const { getAccessTokenSilently } = useAuth0();

    const [iconUrl, setIconUrl] = useState('')

    const [fields, setFields] = useState({
        category_name: '',
        parent_category_id: 0,
        icon_url: iconUrl
    })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if( fields.icon_url === '' || fields.category_name === '') {
            setError('Please add a category name and icon')
            return
        }
        setIsLoading(true)
        const token = await getAccessTokenSilently();
        const response = DataServiceCategory.addCategory(fields, token)
        try {
            const brandResponse = await response
            props.setUpdate(props.update + 1)
            if( brandResponse.data.parent_category_id !== 0 ) {
                props.openEdit(brandResponse.data)
            } else {
                handleModal()
            }
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    }

    useEffect(function effectFunction() {
        let isMounted = true
        if(isMounted) setFields(values => ({...values, ['icon_url']: iconUrl}))
        return () => { isMounted = false }
    }, [iconUrl])

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        let isMounted = true
        const value = event.target.value;
        if(isMounted) setFields(values => ({...values, [field]: value}))
        return () => { isMounted = false }
    }

   

    return(
        <div className="position-relative">
            {isLoading && <LoadingSpinner />}
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            <UploadIcon iconUrl={iconUrl} setIconUrl={setIconUrl} />
            <TextInputRow label="Category name" defaultValue="" onFieldChange={onFieldChange} field="category_name" inputGroup={false} buttonAction={()=>null} />
            <SelectCategory parentCats={props.parentCats} onFieldChange={onFieldChange} selected={-1} />
            <Button label={(fields.parent_category_id === 0)?'Add Category':'Add Category & select attributes'} type={'primary'} action={handleSubmit} />
        </div>
    )
}

export default AddCategory