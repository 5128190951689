import React, { Component, useState, useRef, useEffect } from "react";

import DataService from "../../services/data.service";
import ResourcesList from "./ResourcesList";
import ProductsList from "./ProductsList";
import ConsoleBookingCalendar from "./ConsoleBookingCalendar";
import LoadingSpinner from "../global/LoadingSpinner";
import DataServiceProduct from "../../services/DataServiceProduct";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import TextInputRow from "../formfields/TextInputRow";
import DataServiceClient from "../../services/DataServiceClient";

const NtaList = (props) => {
    const onChange = (e, field) => {
    }
    return props.ntaList.map(nta =>
        <TextInputRow 
            label={nta.display_name} 
            defaultValue={nta.value} 
            field={'text'} 
            onFieldChange={onChange} 
            inputGroup={false} 
            buttonAction={()=>null} />
    )
}

const AddEvent = (props) => {
    const isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedResource, setSelectedResource] = useState('')
    const [selectedProduct, setSelectedProduct] = useState('')
    const [selectedProductDuration, setSelectedProductDuration] = useState(15)
    const [slotStep, setSlotStep] = useState(900)
    const [bookingData, setBookingData] = useState({})
    const [productList, setProductList] = useState([])
    const [name, setName] = useState('')
    const { getAccessTokenSilently } = useAuth0()
    const [ ntaList, setNtaList ] = useState('')

   
    const createEvent = (object) => {
        
        console.log('create',object)
        setBookingData({
            brand: props.orgId.split('_')[0],
            resource: selectedResource,
            customer: name,
            client_uuid: 'c9a5fcb0-f9e0-565f-999b-9e4abbb1cd36',
            org_id: props.orgId,
            product_id: selectedProduct,


            search_params: {
                date: object.date,
                end_time: object.end_time,
                time: object.start_time,
                NTA: ntaList
            }

        })
    }

    useEffect(function effectFunction() {

        async function getProducts() {
            setIsLoading(true)

            const token = await getAccessTokenSilently()
            const response = DataServiceProduct.getProducts(props.orgId, token)
            try {
                const bookingResponse = await response
                setIsLoading(false)
                setProductList(bookingResponse.data)
            } catch(e) {
                setIsLoading(false)
            }
        }
        getProducts()

        async function getDefaults() {
            setIsLoading(true)
            const response = DataService.getOrgDefaults(props.orgId)
            try {
                const bookingResponse = await response
                setSlotStep(bookingResponse.data.min_slot)
                setIsLoading(false)
            } catch(e) {
                setIsLoading(false)
            }
        }
        getDefaults()

    },[])

    useEffect(function effectFunction() {

        async function makeBooking() {
            setIsLoading(true)

            const response = DataServiceClient.createEvent(bookingData)
            try {
                const bookingResponse = await response
                setIsLoading(false)
                setSelectedResource('')
                setSelectedProduct('')
                props.closeModal()
                props.makeScheduleUpdate()
            } catch(e) {
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            makeBooking()
        } else {
            isMounted.current = true;
        }
        

    },[bookingData])

    const onNameChange = (event, field) => {
        setName( event.target.value )
    }

    const checkShouldShowResources = () => {
        if( props.resources.length > 0 && selectedProduct !== '' ) return true
        return false
    }

    return(
        <div className="row">
            {isLoading && <LoadingSpinner/>}
            <TextInputRow label="Customer Name" defaultValue={name} onFieldChange={onNameChange} field="customer_name" inputGroup={false} buttonAction={()=>null} />
            <div className="col-3">
                <div className="list-group">
                    <ProductsList setNtaList={setNtaList} selectedProduct={selectedProduct} setSelectedProductDuration={setSelectedProductDuration} setSelectedProduct={setSelectedProduct} products={productList}/>
                </div>
            </div>
            <div className="col-3">
                <div className="list-group">
                    {checkShouldShowResources() &&
                        <ResourcesList selectedResource={selectedResource} setSelectedResource={setSelectedResource} resources={props.resources}/>
                    }
                </div>
            </div>
            <div className="col-2">
                <div className="list-group">
                    {checkShouldShowResources() &&
                        <NtaList ntaList={ntaList} />
                    }
                </div>
            </div>
            <div className="col-4">
                {selectedResource != '' &&
                    <ConsoleBookingCalendar setBooking={createEvent} resourceId={selectedResource} orgId={props.orgId} duration={selectedProductDuration} step={slotStep} />
                }
            </div>
        </div>
    )
}

export default AddEvent