import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import dateformat from 'dateformat';

class Booking extends Component {
    constructor(props) {
        super(props);
        this.handleClick.bind(this);
    }

    handleClick = () => {
        // Simply call the setStateOfParent function from 
        // prop and pass required argument
        this.props.cancelBooking(this.props.data.id );
    }

    startDate = () => {
        // return Date(this.props.data.start)
        return this.props.data.start
    }
    endDate = () => {
        // return Date(this.props.data.end)
        return this.props.data.end
    }

    render() {
        return (
            <div className="list-group-item list-group-item-action">
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{this.props.data.product}</h5>
                    <small>{ dateformat(this.startDate(), 'dddd, mmmm dS, yyyy')}</small>
                </div>
                <small className="mb-1"><em>@</em> {this.props.data.org_name} <em>with</em> {this.props.data.resource_name}</small>
                <p className="mb-1">{ dateformat(this.startDate(), 'h:MM TT')  } - { dateformat(this.endDate(), 'h:MM TT') }</p>
                <small><button type="button" className="btn btn-link btn-sm btn-danger" onClick={this.handleClick} href="#">Cancel</button></small>
            </div>
        )
    }
}

function BookingsList(props){
    if( props.bookings.length > 0) {
        return props.bookings.map((booking)=><Booking cancelBooking={props.cancelBooking} data={booking} key={booking.id} />)
    } else {
        return <p>Nothing found</p>
    }
}

const Bookings = (props) => {
    return (
        <div className="mb-5">
            <h2>Bookings</h2>
            <ul className="list-group">
                <BookingsList cancelBooking={props.cancel} bookings={props.bookings} />
            </ul>
        </div>
    );
}

export default Bookings;