import React, {FC, useState, useRef, useEffect, ChangeEvent} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import ScheduleItem from "../organisation/ScheduleItem";
import InlineError from '../elements/InlineError';
import UploadOrgIcon from '../organisation/UploadOrgIcon'
import DataServiceCategory from '../../services/DataServiceCategory';
import DataServiceOrganisation from '../../services/DataServiceOrganisation';
import TextInputRow from '../formfields/TextInputRow';
import SelectCategories from '../organisationDetails/SelectCategories';
import CategoryModel from '../../interfaces/CategoryModel';
import ResourceModel from '../../interfaces/ResourceModel';
import TextInput from '../formfields/TextInput';
import generateGuid from '../../helpers/generateGuid';
import AddressList from './AddressList';
import InlineResources from './InlineResources';
import { Button } from '@mui/material';

interface AddOrgProps {
    brandId: string
    closeModal: ()=>void
    setReloadOrgs: (a: number)=>void
    reloadOrgs: number
}

interface ScheduleModel {
    day: number
    open: string
    close: string
}

interface AddOrgInputsModel {
    user: string
    brand_name: string
    icon_url: string
    item_name: string
    org_id: string
    item_description: string
    item_country: string
    item_territory: string
    item_website: string
    item_street_address: string
    postcode: string
    min_slot: number
    adopt_defaults: string
    parent_category_id: string
    category_id: string
    category_set: number[],
    schedule: ScheduleModel[]
    item_resources: ResourceModel[]
}

const AddOrganisation:FC<AddOrgProps> = (props) => {

    const isMounted = useRef(false)
    const { user, getAccessTokenSilently } = useAuth0()

    const [iconUrl, setIconUrl] = useState('')
    const userVal = user? user.sub? user.sub : '' : ''
    const [inputs, setInputs] = useState<AddOrgInputsModel>({
        "user": userVal,
        "brand_name": props.brandId,
        'icon_url': '',
        "item_name": "",
        "org_id": "",
        "item_description": "",
        "item_country": "Q145",
        "item_territory": "",
        "item_website": "",
        "item_street_address": "",
        "postcode": "",
        "min_slot": 900,
        "adopt_defaults": "no",
        "parent_category_id": '',
        "category_id": '',
        "category_set": [],
        "schedule": [
            {
              "day": 0,
              "open": "09:00",
              "close": "17:30"
            },
            {
              "day": 1,
              "open": "09:00",
              "close": "17:30"
            },
            {
              "day": 2,
              "open": "09:00",
              "close": "17:30"
            },
            {
              "day": 3,
              "open": "09:00",
              "close": "17:30"
            },
            {
              "day": 4,
              "open": "09:00",
              "close": "17:30"
            },
            {
              "day": 5,
              "open": "09:00",
              "close": "17:30"
            },
            {
              "day": 6,
              "open": "00:00",
              "close": "00:00"
            }
        ],
        "item_resources": [],
    })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [timesSame, setTimesSame] = useState(false)
    const [addressString, setAddressString] = useState('')
    const [placesArray, setPlacesArray] = useState([])
    const [minSlotIsValid, setMinSlotIsValid] = useState(true)
    const [parentCats, setParentCats] = useState([])
    const [childCats, setChildCats] = useState<CategoryModel[]>([])
    const [allCats, setAllCats] = useState<CategoryModel[]>([])

    useEffect(function effectFunction() {
        let isMounted = true;       
        async function fetchCats() {
            if (isMounted) setIsLoading(true)
            const response = DataServiceCategory.getCatList()
            try {
                const dataResponse = await response
                if (isMounted) setParentCats(dataResponse.data.search_categories.filter((item: { parent_category_id: number; }) => item.parent_category_id == 0))
                if (isMounted) setAllCats(dataResponse.data.search_categories)

                if (isMounted) setIsLoading(false)
                
            } catch(e) {
                if (isMounted) setIsLoading(false)
            }
        }
        fetchCats()
        return () => { isMounted = false }

    }, [])

    useEffect(function effectFunction() {

        async function checkPlaces() {
            const response = DataService.checkPlaces(addressString)
            try {
                const orgsResponse = await response
                setPlacesArray(orgsResponse.data.predictions)
                
            } catch(e) {
            }
        }

        if( isMounted.current ) {
            checkPlaces()
        } else {
            isMounted.current = true;
        }

    }, [addressString])

    useEffect(function effectFunction() {
        setInputs(values => ({...values, ['icon_url']: iconUrl}))
    }, [iconUrl])

    const handleChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        const name = field;
        const value = event.target.value;
        if(name == 'item_name') {
            // generate guid
            const orgId = generateGuid()
            setInputs(values => ({...values, ['org_id']: orgId}))
        }

        if(name == 'item_street_address') {
            setAddressString(value)
        }

        if(name == 'parent_category_id') {

        }

        setInputs(values => ({...values, [name]: value}))
    }

    useEffect(function effectFunction() {
        let isMounted = true;     
        function selectParent() {
            if (isMounted) setChildCats(allCats.filter(item => item.parent_category_id == parseInt(inputs.parent_category_id)))
        }
        selectParent()
        return () => { isMounted = false }
    }, [inputs.parent_category_id])

    const submitForm = async () => {
        const token = await getAccessTokenSilently()
        const response = DataServiceOrganisation.addOrg(inputs, token)
        try {
            const brandResponse = await response
            props.closeModal()
            props.setReloadOrgs(props.reloadOrgs+1)
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.errors[0])
            } else {
                setError(e.message)
            }
        }
    }

    let onTimeChange = (openClose: number, day: number, e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>) => {

        let storedSchedule = inputs.schedule
        const editIndex = inputs.schedule.findIndex(item => item.day == day)

        if( openClose == 0) storedSchedule[editIndex].open = e.target.value
        if( openClose == 1) storedSchedule[editIndex].close = e.target.value

        if( storedSchedule[editIndex].open == storedSchedule[editIndex].close ) {
            setTimesSame(true) 
        } else {
            setTimesSame(false)
        }

        setInputs(values => ({...values, schedule: storedSchedule}))

    }

    let onUnavailableChange = (day: number, value: boolean) => {
        let storedSchedule = inputs.schedule
        const editIndex = inputs.schedule.findIndex(item => item.day == day)

        if( value ) {
            storedSchedule[editIndex].open = '00:00'
            storedSchedule[editIndex].close = '00:00'
            setTimesSame(false)
        } else {
            if( storedSchedule[editIndex].open == storedSchedule[editIndex].close ) setTimesSame(true)
        }
        
        setInputs(values => ({...values, schedule: storedSchedule}))
    }

    const setAddressText = (text: string, id: string) => {
        setInputs(values => ({...values, ['item_street_address']: text}))
        setPlacesArray([])
        checkPlace(id)
    }

    async function checkPlace(id: string) {
        const response = DataService.checkPlace(id)
        try {
            const orgsResponse = await response
            const postCode = orgsResponse.data.result.address_components.filter((item: { types: string | string[]; }) => item.types.includes( 'postal_code' ) )
            const foundPostcode = ( postCode.length > 0)? postCode[0].long_name : ''
            setInputs(values => ({...values, ['postcode']: foundPostcode}))
        } catch(e) {
        }
    }

    const msToMins = () => {
        return inputs.min_slot / 60
    }

    const updateSlotLength = (e: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>) => {

        const value = parseInt(e.target.value)

        if( value % 5 === 0 ) {
            const minSlot = value * 60
            setInputs(values => ({...values, ['min_slot']: minSlot}))
            setError('')
            setMinSlotIsValid(true)
        } else {
            setError('Slot length must be a multiple of 5')
            setMinSlotIsValid(false)
        }

    }

    const setCategories = (cats: number[]) => {

        setInputs(values => ({...values, ['categories']: cats}))
    }

    const addResource = (resources: ResourceModel[]) => {
        const builtResources: ResourceModel[] = inputs.item_resources;
        const allResources = builtResources.concat(resources)
        
        setInputs(values => ({...values, ['item_resources']: allResources}))
    }

    const removeResource = (id: string) => {
        const builtResources = inputs.item_resources;
        let indexToRemove = -1
        builtResources.find(function(item, i){
            if(item.ID == id){
                indexToRemove = i
            }
        })
        builtResources.splice(indexToRemove, 1)
        setInputs(values => ({...values, ['item_resources']: builtResources}))
    }

    return (
        <div>
            <h3>Organisation Details</h3>
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            <TextInputRow label={'Name'} defaultValue={inputs.item_name} field="item_name" onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label={'Description'} defaultValue={inputs.item_description} field="item_description" onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <UploadOrgIcon iconUrl={iconUrl} setIconUrl={setIconUrl} />

            <div className="row mb-3 align-items-center">
                <div className="col-9 position-relative">
                    <label htmlFor="item_street_address" className="form-label">Street Address</label>
                    <TextInput defaultValue={inputs.item_street_address} field={'item_street_address'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
                    <AddressList data={placesArray} setAddressText={setAddressText} />
                </div>
                <div className="col-3">
                    <label htmlFor="postcode" className="form-label">Postcode</label>
                    <TextInput defaultValue={inputs.postcode} field={'postcode'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
                </div>
            </div>
            <div className="row mb-3 align-items-center">
                <div className="col-2">
                    <div className="mb-3">
                        <label htmlFor="min_slot" className="form-label">Minimum Slot length</label>
                        <input className="form-control" id="min-slot" type="number" step="5" onChange={(e) => updateSlotLength(e)} defaultValue={msToMins()} />
                        <div className="form-text">Minutes</div>
                    </div>
                </div>
                <div className="col-10">
                    <TextInputRow label={'Website'} defaultValue={inputs.item_website} field="item_website" onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
                </div>
            </div>
            <h3>Organisation Schedule</h3>
            <div className="organisation-schedule-items">
                {inputs.schedule.map((defaultItem, index)=><ScheduleItem index={index} unavailableLabel="Closed" day={defaultItem} key={defaultItem.day} onTimeChange={onTimeChange} onUnavailableChange={onUnavailableChange} />)}
            </div>
            <h3>Organisation Resources</h3>
            <InlineResources orgId={''} addResource={addResource} removeResource={removeResource} resources={inputs.item_resources} />

            <div className="mb-3">
                <h3>Adopt Defaults</h3>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="adopt_defaults" id="defaultsyes" value="yes" onChange={(e)=>handleChange(e, 'adopt_defaults')} />
                    <label className="form-check-label" htmlFor="defaultsyes">Yes - populate organisation with default products</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="adopt_defaults" id="defaultsno" value="no" onChange={(e)=>handleChange(e, 'adopt_defaults')} />
                    <label className="form-check-label" htmlFor="defaultsno">No - leave the organisation empty</label>
                </div>
            </div>
            {inputs.adopt_defaults == 'yes' && 
                <SelectCategories 
                    setAllCats={setAllCats}
                    categories={inputs.category_set}
                    setCategories={setCategories}
                    attributes={[]}
                    setAttributes={()=>null}
                    ntas={[]}
                    setNtas={()=>null}
                    showattrs={false} 
                    setWhatChanged={()=>null} />
                                
            }

            <div className="mb-3">
                <Button variant="contained" onClick={()=>submitForm()}>Add Organisation</Button>
            </div>
        </div>
    )
}

export default AddOrganisation