import React, {FC, useState, ChangeEvent, useRef, useEffect, useContext} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataService from "../../services/data.service";
import DataServiceResource from '../../services/DataServiceResource';
import DataServiceOrganisation from '../../services/DataServiceOrganisation';
import Resource from './Resource';
import LoadingSpinner from '../global/LoadingSpinner';
import InlineError from '../elements/InlineError';
import ResourceModel from '../../interfaces/ResourceModel';
import ListGroup from '../elements/ListGroup';
import Button from '../elements/Button';
import FormLabel from '../formfields/FormLabel';
import TextInput from '../formfields/TextInput';
import { OrganisationContext } from '../../contexts/OrganisationContext';
import getID from '../../helpers/getID';

interface ResourceProps {
    orgId: string;
    brandId: string;
    resources: ResourceModel[];
    makeReload: ()=>void;
    setIsLoading: (bool: boolean)=>void;
    isLoading: boolean;
}
const AddResource:FC<ResourceProps> = (props) => {

    const initialData = {
        "name": '',
        "res_id": '',
        "org_id": props.orgId,
        "brand_name": props.brandId
    }
    const [inputs, setInputs] = useState(initialData)
    const [addResource, setAddResource] = useState(0)
    
    const {
        user,
        getAccessTokenSilently
    } = useAuth0();

    const handleChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        const name = field;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    useEffect(()=> {

        let isMounted = true;  

        async function addResource() {
            props.setIsLoading(true)
            const data = {
                "name": inputs.name,
                "res_id": inputs.res_id,
                "org_id": props.orgId,
                "brand_name": props.brandId
            }
            const token = await getAccessTokenSilently()
            const response = DataServiceResource.addResource(inputs, token)
            try {
                const orgsResponse = await response
                setInputs(initialData)
                props.makeReload()
                props.setIsLoading(false)
                
            } catch(e) {
                props.setIsLoading(false)
            }
        }
        if(isMounted && inputs.name !== '' && inputs.res_id !== '') addResource()
        return () => { isMounted = false }

    }, [addResource])

    
    const checkID = () => {
        const exists = props.resources.filter(resource => resource.res_id == inputs.res_id)
        if( exists.length > 0) return true
        return false
    }

    return(
        <div className="row mb-3">
            <h3>Add Resource</h3>
            {checkID() && <div className="col-12"><InlineError errorMessage="ID already exists" /></div>}
            <div className="col-5">
                <div className="form-floating">
                    <TextInput defaultValue={inputs.name} field={'name'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null}/>
                    <FormLabel label={'Name'}/>
                </div>
            </div>
            <div className="col-5">
                <div className="form-floating">
                    <TextInput defaultValue={inputs.res_id} field={'res_id'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null}/>
                    <FormLabel label={'ID (lower case and numbers only)'}/>
                </div>
            </div>
            <div className="col-2">
                <Button disabled={checkID()} label={'Add Resource'} type={'outline-primary'} action={()=>setAddResource(addResource+1)}/>
            </div>
        </div>
    )

}

interface ResListProps {
    resources: ResourceModel[]
    removeResource: (a: string)=>void
}

const ResourceList:FC<ResListProps> = (props) => {
    return(
        <div>
            { props.resources.length > 0 &&
                <ListGroup>
                    {props.resources.map((resource)=><Resource orgId={getID()} removeResource={props.removeResource} data={resource} key={resource.ID + resource.res_id} />)}
                </ListGroup>
            }
        </div>
    )
}


const Resources:FC = (props) => {

    const {getAccessTokenSilently} = useAuth0();
    const [orgData, setOrgData] = useContext(OrganisationContext);

    const isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [resources, setResources] = useState([])
    const [deleteId, setDeleteId] = useState('')
    const [reload, setReload] = useState(0)

    const makeReload = () => {
        setReload(reload+1)
    }

    useEffect(function effectFunction() {

        async function resourceDetails() {
            setIsLoading(true)
            
            const token = await getAccessTokenSilently()
            const response = DataServiceOrganisation.resourceDetail(getID(), token)
            try {
                const orgsResponse = await response
                setResources(orgsResponse.data)
                setIsLoading(false)
                
            } catch(e) {
                setIsLoading(false)
            }
        }
        resourceDetails()

    }, [reload])


    useEffect(function effectFunction() {

        async function deleteResource() {
            setIsLoading(true)
            
            const token = await getAccessTokenSilently()
            const response = DataServiceResource.deleteResource(orgData.brand_name, orgData.org_id, deleteId, token)
            try {
                const orgsResponse = await response
                makeReload()
                setIsLoading(false)
                
            } catch(e) {
                setIsLoading(false)
            }
        }
        if( isMounted.current ) {
            deleteResource()
        } else {
            isMounted.current = true;
        }

    }, [deleteId])
    

    return (
        <div>            
            <h3>Resources</h3>
            {isLoading && <LoadingSpinner/> }
            <ResourceList resources={resources} removeResource={setDeleteId} />
            <AddResource makeReload={makeReload}  resources={resources} orgId={ orgData.org_id} brandId={orgData.brand_name} isLoading={isLoading} setIsLoading={setIsLoading} />
        </div>
    )
}

export default Resources