import React, {FC, useState, ChangeEvent, useEffect, useContext} from 'react';
import DataService from "../../services/data.service";
import DayModel from '../../interfaces/DayModel';

import ScheduleItem from "./ScheduleItem";
import LoadingSpinner from '../global/LoadingSpinner';
import InlineError from '../elements/InlineError';
import ScheduleModel from '../../interfaces/ScheduleModel';
import { OrganisationContext } from '../../contexts/OrganisationContext';

const OrgSchedule:FC = () => {

    const [orgData, setOrgData] = useContext(OrganisationContext);
    const [defaults, setDefaults] = useState<ScheduleModel>({
        org_name: '',
        status: '',
        min_slot: 0,
        org_id: '',
        schedule: []
    })
    const [schedule, setSchedule] = useState<DayModel[]>([])
    const [update, setUpdate] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [hasMinSlot, setHasMinSlot] = useState(false)
    const [minSlotIsValid, setMinSlotIsValid] = useState(true)
    const [timesSame, setTimesSame] = useState(false)

    let sendUpdate = () => {
        console.log('set updaye')
        setUpdate(update+1)
    }

    let onTimeChange = (openClose: number, day:number, e: ChangeEvent<HTMLInputElement>) => {

        let storedSchedule = schedule
        const editIndex = schedule.findIndex((item: DayModel) => item.day == day)

        if( openClose == 0) storedSchedule[editIndex].open = e.target.value
        if( openClose == 1) storedSchedule[editIndex].close = e.target.value

        if( storedSchedule[editIndex].open == storedSchedule[editIndex].close ) {
            setTimesSame(true) 
        } else {
            setTimesSame(false)
        }

        defaults.schedule = storedSchedule

        setSchedule(storedSchedule)
        setDefaults(defaults)
    }
    const resetError = () => setErrorMessage('')

    let onUnavailableChange = (day: number, value: boolean) => {
        console.log(day, value)
        let storedSchedule = schedule
        const editIndex = schedule.findIndex(item => item.day == day)

        if( value ) {
            storedSchedule[editIndex].open = '00:00'
            storedSchedule[editIndex].close = '00:00'
            setTimesSame(false)
        } else {
            if( storedSchedule[editIndex].open == storedSchedule[editIndex].close ) setTimesSame(true)
        }
        
        

        defaults.schedule = storedSchedule

        setSchedule(storedSchedule)
        setDefaults(defaults)
    }



    useEffect(function effectFunction() {

        let isMounted = true

        async function sendDefaults() {
            setIsLoading(true)
            const response = DataService.sendOrgDefaults(defaults)
            try {
                const defaultsResponse = await response
                console.log('test1',defaultsResponse)
                //props.setUpdateClashing(props.updateClashing+1)
                setIsLoading(false)
                resetError()
            } catch(e: any) {
                
                setErrorMessage(e.response.data.errors[0])
                setIsLoading(false)
            }
            
        }

        if( isMounted && defaults.org_id !== '' ) sendDefaults()
        
        return () => { isMounted = false }

    }, [update])

    useEffect(function effectFunction() {

        async function fetchDefaults() {
            setIsLoading(true)
            const response = DataService.getOrgDefaults(orgData.org_id)
            try {
                const defaultsResponse = await response

                setDefaults(defaultsResponse.data)
                setSchedule(defaultsResponse.data.schedule.reverse())
                setIsLoading(false)
                resetError()
                if( defaultsResponse.data.min_slot !== undefined && typeof defaultsResponse.data.min_slot != 'undefined' ) {
                    setHasMinSlot(true)
                }
                
            } catch(e: any) {
                
                setErrorMessage(e.response.data.errors[0])
                setIsLoading(false)
            }
        }
        fetchDefaults();

    }, [])

    const msToMins = () => {
        return defaults.min_slot / 60
    }

    const updateSlotLength = (e: ChangeEvent<HTMLInputElement>) => {

        const value = parseInt(e.target.value)

        if( value % 5 === 0 ) {
            defaults.min_slot = value * 60
            setDefaults(defaults)
            setErrorMessage('')
            setMinSlotIsValid(true)
        } else {
            setErrorMessage('Slot length must be a multiple of 5')
            setMinSlotIsValid(false)
        }

    }

    return (
        <div className="organisation-schedule">
            <h3>Organisation Schedule</h3>
            {isLoading && <LoadingSpinner/>}
            
            {errorMessage != '' &&
                <InlineError errorMessage={errorMessage} />
            }
            {timesSame &&
                <InlineError errorMessage="Times should not be the same" />
            }
            { hasMinSlot &&
                <div className="min-slot mb-3">
                    <label className="form-label" htmlFor="min-slot">Minimum Slot Length (minutes)</label>
                    <input className="form-control" id="min-slot" type="number" step="5" onChange={(e) => updateSlotLength(e)} defaultValue={msToMins()} />
                </div>
            }
            <div className="organisation-schedule-items">
                {schedule.map((defaultItem, index)=><ScheduleItem index={index} unavailableLabel="Closed" day={defaultItem} key={defaultItem.day} onTimeChange={onTimeChange} onUnavailableChange={onUnavailableChange} />)}
            </div>
            <button disabled={!minSlotIsValid || timesSame} className="btn btn-primary mt-3" onClick={(e) => sendUpdate()}>Update Schedule</button>
        </div>
    )
}

export default OrgSchedule