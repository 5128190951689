import { Button, Grid, Input } from "@mui/material"
import Pusher, { Channel } from "pusher-js";
import { FC, KeyboardEvent, SetStateAction, useEffect, useRef, useState } from "react"
import { sendMessage } from "../../services/DataServiceChat";
import { Background } from "reactflow";


function getID() {
    const path = window.location.pathname
    return path.split("/")[3]
}

export const NEW_MESSAGE_EVENT = 'new_message';
export const INITIATE_CHAT_EVENT = 'chat_init';

interface MessageData {
    message: string;
    username: string; 
    date: string;
}

interface ChatProps {
    pusher: Pusher,
    setChannelName: (a: string)=>void, 
    channelName: string,
    isConnecting: boolean,
    setIsConnecting: (a:boolean)=>void
    username: string
    isSubscribed: boolean
    setIsSubscribed: (a:boolean)=>void
}

const Chat:FC<ChatProps> = ({pusher, setChannelName, channelName, isConnecting, setIsConnecting, username, isSubscribed, setIsSubscribed}) => {
    // const isMounted = useRef(false)

    const [inputVal, setInputVal] = useState('');
    const [chatMessages, setChatMessages] = useState<MessageData[]>([]);
    const [isConnected, setIsConnected] = useState(false);

    const bottomOfChat = useRef<HTMLDivElement>(null);


    useEffect(()=>{

        if(!isConnected) {

            const channelname = username + '_' + getID()
            setChannelName(channelname)
        
            var channel = pusher.subscribe(channelname);
            channel.bind(NEW_MESSAGE_EVENT, (data: MessageData) => {

                if(data.message !== "CONNECTED") {
                    const storageItem = localStorage.getItem('messages_' + username + '_' + getID())
                    const messages = storageItem ? JSON.parse(storageItem) : [];
                    const newMessages = messages.concat(data);
                    localStorage.setItem('messages_' + username + '_' + getID(), JSON.stringify(newMessages));
                    bottomOfChat.current?.scrollIntoView({behavior: 'smooth'});
                    
                    setChatMessages(newMessages);
                }
            });
            setIsConnected(true)

            setTimeout(()=>{
                sendMessage(NEW_MESSAGE_EVENT, channelname, 'CONNECTED', getID());
                setIsConnecting(false);
                setIsSubscribed(true)
            }, 500)
        }

         
    },[]);

    useEffect(()=>{
        if( isConnected ) {
            const storageItem = localStorage.getItem('messages_' + username + '_' + getID())
            const messages = storageItem ? JSON.parse(storageItem) : [];
            localStorage.setItem('messages_' + username + '_' + getID(), JSON.stringify(messages));
            setChatMessages(messages);

        }
    },[isConnected])

    const styles = {
        '.sentmessage': {
            width: "80%",
            background: '#45c616',
            borderRadius: '5px 5px 0 5px',
            color: 'white',
            marginLeft: '20%',
            padding: '10px',
            marginBottom: '10px',
            'img': {
                width: '100%'
            }
        },
        '.recievedmessage': {
            background: '#eee',
            color: '#333',
            width: "80%",
            borderRadius: '5px 5px 0 5px',
            padding: '10px',
            marginBottom: '10px',
            'img': {
                width: '100%'
            }
        },
        '.inputs': {
            position: 'fixed',
            bottom: '0',
            borderTop: '1px solid #eee',
            width: '480px',
            background: '#fff',
            padding: '10px 0',

            'button': {
                position: 'absolute',
                right: '0px',
                width: '70px'
            },
            '.inputfield': {
                width: '400px'
            }
        },
        '.chat': {
            marginBottom: '53px'
        },
    }


    const clickAction = () => {
        sendMessage(NEW_MESSAGE_EVENT, channelName ?? '', inputVal, getID())
        setInputVal('');
    }

    const enterKey = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if( e.keyCode === 13) {
            sendMessage(NEW_MESSAGE_EVENT, channelName ?? '', inputVal, getID())
            setInputVal('');
        }
    }

    return <Grid sx={styles} style={{width: "500px", padding: '10px', position: 'relative', overflowY: 'auto',}}>
        <div className="chat" ref={bottomOfChat}>
            <h3 className="card-title">Chat with {username}</h3>
            {isSubscribed ?? <p>Connecting</p>}
            {chatMessages.length > 0 &&
                chatMessages.map((message, i)=>{
                    if(message.message.includes('[IMG]')) {
                        const msg = message.message.replace('[IMG]', '');
                        const msg2 = msg.replace('[/IMG]', '')
                        return <div key={message.date + i} className={message.username === getID() ? "sentmessage" : "recievedmessage"}><img src={msg2} /></div>
                    } else {
                        return <div key={message.date + i} className={message.username === getID() ? "sentmessage" : "recievedmessage"}>{message.message}</div>;
                    }
                })
            }
            <div className={"bottom"} ref={bottomOfChat}></div>
        </div>
        <div className="inputs">
            <Input className="inputfield" placeholder="enter your message" value={inputVal} onKeyDown={(e)=>enterKey(e)} onChange={(e)=>setInputVal(e.target.value)} />
            <Button color="primary" onClick={()=>clickAction()}>Send</Button>
        </div>
    </Grid>
}
export default Chat
