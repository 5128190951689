import { useAuth0 } from "@auth0/auth0-react"
import { Box, Button, Popover, Typography } from "@mui/material"
import { FC, MouseEvent, useContext, useState, useEffect } from "react"
import { ModalContext } from "../../contexts/modalContext"
import { OrganisationContext } from "../../contexts/OrganisationContext"
import OrganisationModel from "../../interfaces/OrganisationModel"
import DataServiceOrganisation from "../../services/DataServiceOrganisation"
import ButtonUi from "../elements/Button"
import EditOrganisation from "./EditOrganisation"


function getID() {
    const path = window.location.pathname
    return path.split("/")[3]
}
const DetailsPanel:FC = () => {
    let { handleModal } = useContext<any>(ModalContext)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [orgData, setOrgData] = useContext(OrganisationContext);
    
    const { getAccessTokenSilently } = useAuth0()

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const editOrg = (e: MouseEvent) => {
        e.preventDefault()
        handleModal(
            <EditOrganisation data={orgData} setReloadOrgs={function (a: number): void {
                throw new Error("Function not implemented.")
            } } />,
            `Edit ${orgData.name}`,
            true
        )
    }

    async function deleteOrg() {
    
        const token = await getAccessTokenSilently()
        const response = DataServiceOrganisation.deleteOrg(orgData.org_id, token)
        try {
            const orgsResponse = await response
            window.location.href = window.location.origin + '/brand'

        } catch(e) {
           
        }
    }

    return(
        <div>
            <h3 className="card-title">Organisation Details</h3>
            <div className="card mb-2">
                <div className="card-body">
                    <p>{orgData.description}</p>
                    <p>{orgData.street_address}<br/>
                        {orgData.postcode}</p>
                    <p>{orgData.website}</p>
                    <Box sx={{ '& button': { mr: 1 } }}>
                        <Button variant="outlined" onClick={(e)=>editOrg(e)}>Edit Details</Button>
                        <Popover 
                            sx={{textAlign: 'center'}}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >   
                            <Typography paragraph={true} sx={{margin: '15px', marginBottom: 0}}>You are Deleting '{orgData.name}' and all it's data. Are you sure?</Typography>
                            <Button sx={{margin: '15px'}} variant="outlined" color={"primary"} onClick={()=>handleClose()}>No - Cancel</Button>
                            <Button sx={{margin: '15px'}} variant="outlined" color={"error"} onClick={()=>deleteOrg()}>Yes - Delete</Button>
                        </Popover>
                        <Button variant="outlined" color={"error"} onClick={(e)=>handleClick(e)}>Delete Organisation</Button>
                    </Box>
                </div>
            </div>
        </div>
    )
}
export default DetailsPanel
