import axios from 'axios';
import { getConfig } from "../config";

const { apiOrigin = "https://suite.develop.nonprodsvc.com/v1", audience } = getConfig();

class DataServiceOrganisation {
    getBrandOrgs(brand: any) {
        return axios.get(`${apiOrigin}/organisation/organisations?brand_name=${brand}`)
    }

    getOrg(name: any, token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/organisation/organisation-detail?org_id=${name}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }

    updateOrg(data: any, token: any) {
        return axios({
            method: 'put',
            url: `${apiOrigin}/organisation/update-organisation-detail?org_id=${data.org_id}&icon_url=${data.icon_url}&display_name=${data.name}&description=${data.description}&street_address=${data.street_address}&postcode=${data.postcode}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
        });
    }

    uploadOrgIcon(body: any, headers: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/organisation/organisation-icon`,
            headers: headers, 
            data: body
        });
    }

    addOrg(data: any, token: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/organisation/add-organisation`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }, 
            data: data
        });
    }

    deleteOrg(id: any, token: any) {
        return axios({
            method: 'delete',
            url: `${apiOrigin}/organisation/organisation?org_id=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });

    }

    resourceDetail(id: any, token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/organisation/organisation-resources-details?org_id=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }

    getResources(id: any, token: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/organisation/organisation-resources?org_id=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        });
    }

    getProblemEvents(token: any, id: any) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/organisation/problem-events?org_id=${id}`,
            headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${token}` , 'accept': 'application/json' }
        })
    }
}
export default new DataServiceOrganisation();