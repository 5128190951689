import axios from 'axios';
import { getConfig } from "../config";

const { apiOrigin = "https://ddc.develop.nonprodsvc.com/v1", audience } = getConfig();

class DataServiceClient {

    getFlows(brand: string) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/question-sets-brand?brand_id=${brand}`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }, 
        });
    }
    getQuestions(brand: string, flow_id: string) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/question-set?flow_id=${flow_id}&brand_id=${brand}`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }, 
        });
    }
    addQuestion(data: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/add-item`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }, 
            data: data
        });
    }
    addOption(data: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/add-option`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }, 
            data: data
        });
        
    }
    insertOption(data: any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/insert-option`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }, 
            data: data
        });
    }
    getQuestionTypes() {
        return axios({
            method: 'get',
            url: `${apiOrigin}/question-types`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }
        });
    }
    getDialogueTypes(brandid: string) {
        return axios({
            method: 'get',
            url: `${apiOrigin}/dialogue_types?brand_id=${brandid}`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }
        });
    }
    removeOptionFlow(brandid: string, option_id: string) {
        return axios({
            method: 'post',
            url:`${apiOrigin}/remove-option-flow?brand_id=${brandid}&option_id=${option_id}`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }
        })
    }
    getActionTypes() {
        return axios({
            method: 'get',
            url: `${apiOrigin}/action-types`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }
        });
    }
    moveOption(data:any) {
        return axios({
            method: 'post',
            url: `${apiOrigin}/move-option`,
            headers: { 'Content-Type': 'application/json','accept': 'application/json' }, 
            data: data
        });
    }
}
export default new DataServiceClient();