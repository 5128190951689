import React, {useState, ChangeEvent, useContext, useEffect, FC, ReactNode} from 'react';
import CategoryModel from '../../interfaces/CategoryModel';
import DataServiceBrand from '../../services/DataServiceBrand';
import DataServiceCategory from '../../services/DataServiceCategory';
import LoadingSpinner from '../global/LoadingSpinner';

interface CatSelectProps {
    setCatFilterId: (a: number)=>void
    setCatList: (a: CategoryModel[])=>void
}
const CategorySelect:FC<CatSelectProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [catList, setCatList] = useState<CategoryModel[]>([])

    useEffect(()=> {
        let isMounted = true
        if(isMounted) {
            async function fetchCats() {
                setIsLoading(true)
                const response = DataServiceCategory.getCatList()
                try {
                    const attrResponse = await response
                    setIsLoading(false)
                    setCatList(attrResponse.data.search_categories)
                    props.setCatList(attrResponse.data.search_categories)
                    
                } catch(e) {
                    setIsLoading(false)
                }
            }
            fetchCats()
        }
        return () => { isMounted = false }

    }, [])

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        props.setCatFilterId(parseInt(e.target.value))
    }

    return(
        <div className="input-group ms-2 mb-3" style={{ width: 'auto'}}>
            <span className="input-group-text" id="addon-wrapping">Filter by Category</span>
            {isLoading && <LoadingSpinner />}
            {catList.length > 0 &&
                <select className="form-select" aria-label="Default select example" onChange={(e)=>handleChange(e)}>
                    <option value={0} selected>All</option>
                    {catList.map((item)=><option key={item.category_id} value={item.category_id}>{item.category_name}</option>)}
                </select>
            }
        </div>
    )
}

export default CategorySelect