import React, {FC, useState, ChangeEvent, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataServiceBrand from "../../services/DataServiceBrand";
import LoadingSpinner from '../global/LoadingSpinner';
import InlineError from '../elements/InlineError';
import UploadIcon from './UploadIcon';
import BrandModel from '../../interfaces/BrandModel';
import TextInputRow from '../formfields/TextInputRow';
import Button from '../elements/Button';



interface EditBrandProps {
    brand: BrandModel
    closeModal: ()=>void
    setReloadBrands: (a:number)=>void
    reloadBrands: number
}

const EditBrand:FC<EditBrandProps> = (props) => {

    const {
        user,
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
      } = useAuth0();

    const [iconUrl, setIconUrl] = useState(props.brand.branding.logo_url)
    const [inputs, setInputs] = useState({
        brand_name: props.brand.name,
        user_id: user?user.sub:'',
        display_name: props.brand.display_name,
        primary: props.brand.branding.colors.primary,
        background: props.brand.branding.colors.page_background,
        icon_file: props.brand.branding.logo_url
    })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const handleSubmit = async () => {

        setIsLoading(true)
        const token = await getAccessTokenSilently();
        const body = JSON.stringify({
            id: props.brand.id,
            name: inputs.brand_name,
            display_name: inputs.display_name,
            branding: {
                logo_url: iconUrl,
                colors: {
                    page_background: inputs.background,
                    primary: inputs.primary
                }
            }
        })
        const response = DataServiceBrand.updateBrand(body, token)
        try {
            const brandResponse = await response
            props.closeModal()
            props.setReloadBrands(props.reloadBrands+1)
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setError(e.response.data.detail[0].msg)
            } else {
                setError(e.message)
            }
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>, field: string) => {
        const name = field;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    return (
        <div className="position-relative">
            {isLoading && <LoadingSpinner />}
            {error != '' &&
                <InlineError errorMessage={error} />
            }
            <TextInputRow label={'Brand Name'} description={'Brand name must be lower case with no spaces.'} defaultValue={inputs.brand_name} field={'brand_name'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label={'Display Name'} defaultValue={inputs.display_name} field={'display_name'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <UploadIcon iconUrl={iconUrl} setIconUrl={setIconUrl} />
            <TextInputRow label={'Primary Colour'} type="color" defaultValue={inputs.primary} field={'primary'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <TextInputRow label={'Background Colour'} type="color" defaultValue={inputs.background} field={'background'} onFieldChange={handleChange} inputGroup={false} buttonAction={()=>null} />
            <Button label={'Save Changes'} type={'primary'} action={handleSubmit} />

        </div>
    )
}

export default EditBrand