import React, {FC, useState, useRef, useEffect, ChangeEvent} from 'react';
import DataService from "../../services/data.service";
import LoadingSpinner from '../global/LoadingSpinner';
import InlineError from '../elements/InlineError';
import ResourceModel from '../../interfaces/ResourceModel';
import SpecialEventModel, { checkboxModel } from '../../interfaces/SpecialEventModel';
import { Box } from '@mui/material';

interface AddProps {
    orgId: string
    makeSpecialEventUpdate: ()=>void
    resources: ResourceModel[]
}
const AddSpecialEvent:FC<AddProps> = (props) => {

    const isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const [eventObject, setEventObject] = useState<SpecialEventModel>({
        date: '',
        start_time: '',
        recurring_end: 'never',
        recurring: 'none',
        end_time: '',
        type: 'default',
        org_id: props.orgId,
        resource_set: [],
        recurring_description: ''
    })
    const [update, setUpdate] = useState(0)
    const [resourcesCheckboxes, setResourcesCheckboxes] = useState<checkboxModel[]>([])
    const [isRecurring, setIsRecurring] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(function effectFunction() {
        const checkboxes = []
        for (let i = 0; i < props.resources.length; i++) {
            const item = props.resources[i]

            checkboxes.push({
                'label': item.res_name,
                'value': item.name,
                'checked': false
            })
        } 
        setResourcesCheckboxes(checkboxes)
    }, [])

    const resetError = () => setErrorMessage('')

    const onFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: string) => {   
        setEventObject( values => ({ ...values, [field]: event.target.value }) )
        resetError()
    }

    const onResourceChange = (index: number, isChecked: boolean) => {

        const updatedListOfItems = [...resourcesCheckboxes]
        updatedListOfItems[index].checked = isChecked
        setResourcesCheckboxes(updatedListOfItems);

        const selectedResources = resourcesCheckboxes.reduce(function(result: string[], item: checkboxModel) {
            if (item.checked) {
              result.push(item.value);
            }
            return result;
          }, []);

        setEventObject( values => ({ ...values, ['resource_set']: selectedResources }) )
    }

    async function onSubmit(e: any) {
        setIsLoading(true)
        const response = DataService.createSpecialEvent(eventObject)
        try {
            const eventsResponse = await response
            props.makeSpecialEventUpdate()
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if( e.response ) {
                setErrorMessage(e.response.data.errors[0])
            } else {
                setErrorMessage(e.message)
            }
        }
    }

    const checkIsRecurring = (event: ChangeEvent<HTMLSelectElement>) => {

        const val = event?.target.value
        setEventObject( values => ({ ...values, ['recurring']: val }) )

        if( val == 'none' ) {
            setIsRecurring(false)
        } else {
            setIsRecurring(true)
        }

    }
    
    const recurring = ['none', 'daily','weekly','monthly', 'annual']

    return (
        <Box sx={{padding: '20px', minWidth: '300px'}}>
            <h3>Add Special Event</h3>
            {isLoading && <LoadingSpinner />}
            <div>
                {errorMessage != '' &&
                    <InlineError errorMessage={errorMessage} />
                }
                <div className="mb-3">
                    <label className="form-label">Recurring Description:</label>
                    <input type="text" className="form-control" defaultValue={eventObject.recurring_description} onChange={(e) => onFieldChange(e, 'recurring_description')} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Date:</label>
                    <input type="date" className="form-control" defaultValue={eventObject.date} onChange={(e) => onFieldChange(e, 'date')} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Start Time:</label>
                    <input type="time" className="form-control" defaultValue={eventObject.start_time} onChange={(e) => onFieldChange(e, 'start_time')} />
                </div>
                <div className="mb-3">
                    <label className="form-label">End Time:</label>
                    <input type="time" className="form-control" defaultValue={eventObject.end_time} onChange={(e) => onFieldChange(e, 'end_time')} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Recurring:</label>
                    <select className="form-select" onChange={(e) => checkIsRecurring(e)}>
                        {recurring.map((item)=><option value={item} selected={(item == eventObject.recurring)} key={item}>{item}</option>)}
                    </select>
                </div>
                
                    <div className="mb-3">
                        <label className="form-label">Recurring End:</label>
                        {isRecurring &&
                            <input type="date" className="form-control" defaultValue={eventObject.recurring_end} onChange={(e) => onFieldChange(e, 'recurring_end')} />
                        }
                        {!isRecurring &&
                            <input type="text" readOnly={true} className="form-control" defaultValue="never" />
                        }
                    </div>
                <div className="mb-3">
                    <label className="form-label">Resource Set:</label>
                    {
                        resourcesCheckboxes.map((item, index)=>
                            <div className="form-check" key={index}>
                                <label className="form-check-label" htmlFor={'id' + item.value}>{item.label}</label>
                                <input 
                                    className="form-check-input"
                                    id={'id' + item.value} 
                                    type="checkbox" 
                                    value={item.value} 
                                    checked={item.checked}
                                    onChange={()=>onResourceChange(index, !item.checked)} />
                            </div>
                        )
                    }
                </div>
                <button className="btn btn-primary" onClick={(e) => onSubmit(e)}>Add Special Event</button>
            </div>
            
        </Box>
    )
}

export default AddSpecialEvent



