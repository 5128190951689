import React from 'react';
 
import {
  AvailabilityCalendar,
  AvailabilityEvent,
  MsSinceMidnightRange,
  Booking,
  Range,
  CalendarThemeProp,
} from 'react-availability-calendar';
import moment from 'moment';
 
import 'bootstrap/dist/css/bootstrap.min.css';
import './booking.css';
 
const msInHour = 60 * 60 * 1000;

function pad(num, prefix) {
    if(prefix) {
        if(num < 10) {
            return '0' + num
        } else {
            return num
        }
    } else {
        if(num == 0) {
            return num + '0'
        } else {
            return num
        }
    }
}


function makeDuration(durationInMins) {
    return (durationInMins / 60)*msInHour
}
 
const BookingCalendar = (props) => {
    const now = new Date();
    const bookingData = {}
    let date = ''
    let startTime = ''
    let endTime = ''

    const onAvailabilitySelected = (a) => {
        date = a.startDate.getFullYear() + '-' + pad(a.startDate.getMonth()+1, true) + '-' + pad(a.startDate.getDate(), true)
        startTime = pad(a.startDate.getHours(), true) + ':' + pad(a.startDate.getMinutes(), false)
        endTime = pad(a.endDate.getHours(), true) + ':' + pad(a.endDate.getMinutes(), false)
        bookingData.start_time = startTime 
        bookingData.end_time = endTime
        bookingData.date = date
        props.setBooking(bookingData)
    }
    
    const onChangedCalRange = (r) =>
        props.setDate(r)

    const blockOutPeriods = [
        // [0 * msInHour, 9 * msInHour],
        // [19 * msInHour, 24 * msInHour],
    ];
  
    const providerTimeZone = 'Europe/London';
    
    return (
        <div>
            <AvailabilityCalendar
                bookings={props.bookings}
                providerTimeZone={providerTimeZone}
                moment={moment}
                initialDate={now}
                onAvailabilitySelected={onAvailabilitySelected}
                onCalRangeChange={onChangedCalRange}
                blockOutPeriods={blockOutPeriods}
                slotLengthMs={makeDuration(props.duration)}
                slotStepMs={makeDuration(props.step/60)}
            />
        </div>
    );
};
 
export default BookingCalendar;